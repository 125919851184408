import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sendRequest } from '../../../api/sendRequest';
import ReactECharts from 'echarts-for-react';
import { useTheme } from 'styled-components';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { TailSpin } from 'react-loader-spinner';

const DetailsWorkHistory = () => {
	const [cycleHistorychartOptions, setCycleHistoryChartOptions] =
		useState(null);
	const [probeAlarmschartOptions, setProbeAlarmsChartOptions] =
		useState(null);
	const monthAgo = new Date(new Date().setDate(new Date().getDate() - 7));
	const today = new Date();
	const [datePickerValue, setDatePickerValue] = useState([monthAgo, today]);
	const [isLoading, setIsLoading] = useState(true);
	const [sum, setSum] = useState({
		m3: '',
		pump_cycle_secs: '',
	});
	const params = useParams();
	const theme = useTheme();

	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');

		return `${year}/${month}/${day}`;
	};

	const fetchData = async () => {
		const startString = formatDate(datePickerValue[0]) + ' 00:00';
		const endString = formatDate(datePickerValue[1]) + ' 23:59';
		const formattedDate = startString + '-' + endString;

		const getProbePumpCycleHistory = {
			action: 'get-probe-pump-cycle-history',
			probe_id: params.id,
			period: formattedDate,
		};
		const getScheduleProbeList = {
			action: 'get-schedule-probe-list',
			probe_id: params.id,
			period: formattedDate,
		};

		try {
			const probePumpCycleHistoryResponse = await sendRequest(
				getProbePumpCycleHistory
			);
			const lastDataPoint =
				probePumpCycleHistoryResponse.data._data_.pop();

			setSum({
				m3: lastDataPoint.m3,
				pump_cycle_secs: lastDataPoint.pump_cycle_secs,
			});

			const scheduleProbeListResponse = await sendRequest(
				getScheduleProbeList
			);
			const allTimeStamps = [
				...probePumpCycleHistoryResponse.data._data_.map((item) =>
					item.ctime.substring(0, item.ctime.lastIndexOf(':'))
				),
				...scheduleProbeListResponse.data._data_.map((schedule) =>
					schedule.time_start.substring(
						0,
						schedule.time_start.lastIndexOf(':')
					)
				),
				...scheduleProbeListResponse.data._data_.map((schedule) =>
					schedule.time_stop.substring(
						0,
						schedule.time_stop.lastIndexOf(':')
					)
				),
			];

			const timeStamps = Array.from(new Set(allTimeStamps)).sort();

			const m3Data = timeStamps.map((timestamp) => {
				const item = probePumpCycleHistoryResponse.data._data_.find(
					(d) => d.ctime.startsWith(timestamp)
				);
				return item ? item.m3 : 0;
			});

			const pumpCycleSecsDataRaw =
				probePumpCycleHistoryResponse.data._data_.map((item) => ({
					ctime: item.ctime,
					pump_cycle_secs: item.pump_cycle_secs,
				}));

			let pumpCycleSecsData = Array(timeStamps.length).fill(0);
			pumpCycleSecsDataRaw.forEach((dataPoint) => {
				let index = timeStamps.findIndex((ts) =>
					dataPoint.ctime.startsWith(ts)
				);
				if (index !== -1) {
					pumpCycleSecsData[index] = dataPoint.pump_cycle_secs;
				}
			});

			const maxM3Value = Number(Math.max(...m3Data).toFixed(1));
			const scheduleColors = [
				'rgba(255, 0, 0, 0.1)',
				'rgba(25, 0, 255, 0.1)',
				'rgba(0, 255, 55, 0.1)',
			];

			function groupBy(array, key) {
				return array.reduce((result, currentValue) => {
					(result[currentValue[key]] =
						result[currentValue[key]] || []).push(currentValue);
					return result;
				}, {});
			}

			const groupedSchedules = groupBy(
				scheduleProbeListResponse.data._data_,
				'name'
			);
			const scheduleNames = Object.keys(groupedSchedules);
			let seriesData = Object.keys(groupedSchedules).map(
				(scheduleName, idx) => {
					const scheduledBarsData = Array(timeStamps.length).fill(0);
					groupedSchedules[scheduleName].forEach((schedule) => {
						let startIndex = -1;
						let endIndex = -1;

						for (let i = 0; i < timeStamps.length; i++) {
							if (
								timeStamps[i] === schedule.time_start &&
								startIndex === -1
							) {
								startIndex = i;
							}
							if (timeStamps[i] === schedule.time_stop) {
								endIndex = i;
							}
							if (startIndex !== -1 && endIndex !== -1) {
								break;
							}
						}
						for (let i = startIndex; i <= endIndex; i++) {
							scheduledBarsData[i] = maxM3Value;
						}
					});

					return {
						name: scheduleName,
						data: scheduledBarsData,
						type: 'line',
						color: scheduleColors[idx % scheduleColors.length],
						yAxisIndex: 1,
						areaStyle: {},
					};
				}
			);

			const pumpCyclesHistoryOptions = {
				animation: true,
				grid: { top: 40, right: 60, bottom: 80, left: 70 },
				xAxis: {
					type: 'category',
					data: timeStamps,
				},
				yAxis: [
					{
						type: 'value',
						name: 'Czas pracy (s)',
						nameGap: 10,
						splitLine: { show: true },
					},
					{
						type: 'value',
						name: 'm³',
						splitLine: { show: false },
					},
				],
				series: [
					...seriesData,
					{
						name: 'Czas pracy (s)',
						data: pumpCycleSecsData,
						type: 'bar',
						color: theme.success,
					},
					{
						name: 'Wypompowano (m³)',
						data: m3Data,
						type: 'line',
						yAxisIndex: 1,
						color: theme.standby,
					},
				],
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						let tooltipContents = [];
						for (let i = 0; i < params.length; i++) {
							let param = params[i];
							let seriesTooltip = `<div style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${param.color}; margin-right: 5px;"></div>`;
							if (groupedSchedules[param.seriesName]) {
								seriesTooltip += `Harmonogram (${
									param.seriesName
								}): <b>${param.value > 0 ? 'Tak' : 'Nie'}</b>`;
							} else {
								seriesTooltip += `${param.seriesName}: <b>${param.value}</b>`;
							}
							tooltipContents.push(seriesTooltip);
						}
						return [
							`${params[0].axisValueLabel}`,
							...tooltipContents,
						].join('<br>');
					},
				},
				dataZoom: [
					{
						type: 'inside',
						xAxisIndex: [0],
						filterMode: 'weakFilter',
					},
				],
				legend: {
					data: [
						'Czas pracy (s)',
						'Wypompowano (m³)',
						...scheduleNames,
					],
					orient: 'horizontal',
					top: 'top',
					left: 'center',
					textStyle: {
						color: theme.fontcolor,
					},
				},
			};

			setCycleHistoryChartOptions(pumpCyclesHistoryOptions);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchDataProbeAlarms = async () => {
		const startString = formatDate(datePickerValue[0]) + ' 00:00';
		const endString = formatDate(datePickerValue[1]) + ' 23:59';
		const formattedDate = startString + '-' + endString;

		const getProbeAlarmHistory = {
			action: 'get-probe-alarm-history',
			probe_id: [params.id],
			period: formattedDate,
		};

		try {
			const probeAlarmsHistoryResposne = await sendRequest(
				getProbeAlarmHistory
			);
			const data = probeAlarmsHistoryResposne.data._data_;
			const timeStamps = data.map((item) => {
				return item.time_stamp.substring(
					0,
					item.time_stamp.lastIndexOf(':')
				);
			});

			const pumpErr = data.map((item) => item.pump_err);
			const pumpDryRun = data.map((item) => item.pump_dry_run);
			const pumpDryErr = data.map((item) => item.pump_dry_err);
			const pumpCallService = data.map((item) => item.pump_call_service);
			const pumpAlarmLevel = data.map((item) => item.pump_alarm_level);

			const formattedTimeStamps = timeStamps.map((timestamp) => {
				const [date, time] = timestamp.split(' ');
				return `${time}\n${date}`;
			});

			const probeAlarmsHistoryOptions = {
				animation: true,
				grid: { top: 30, right: 60, bottom: 80, left: 70 },
				xAxis: {
					type: 'category',
					data: formattedTimeStamps,
					axisLabel: {
						formatter: (value) => {
							return `{value|${value.split('\n')[0]}}\n{value|${
								value.split('\n')[1]
							}}`;
						},
						rich: {
							value: {
								lineHeight: 15,
								align: 'center',
							},
						},
					},
				},
				yAxis: {
					type: 'value',
				},
				series: [
					{
						name: 'Wezwij technika',
						data: pumpCallService,
						type: 'bar',
						stack: 'stack1',
						color: theme.danger,
					},
					{
						name: 'Błąd suchobiegu',
						data: pumpDryErr,
						type: 'bar',
						stack: 'stack1',
						color: theme.gray,
					},
					{
						name: 'Suchobieg',
						data: pumpDryRun,
						type: 'bar',
						stack: 'stack1',
						color: theme.standby,
					},
					{
						name: 'Błąd pompowni',
						data: pumpErr,
						type: 'bar',
						stack: 'stack1',
						color: '#fffc43',
					},
					{
						name: 'Poziom alarmowy',
						data: pumpAlarmLevel,
						type: 'bar',
						stack: 'stack1',
						color: '#ff7f43',
					},
				],
				tooltip: {
					trigger: 'axis',
				},
				dataZoom: [
					{
						type: 'inside',
						xAxisIndex: [0],
						filterMode: 'weakFilter',
					},
				],
				legend: {
					data: [
						'Wezwij technika',
						'Błąd suchobiegu',
						'Suchobieg',
						'Błąd pompowni',
						'Poziom alarmowy',
					],
					orient: 'horizontal',
					top: 'top',
					left: 'center',
					textStyle: {
						color: theme.fontcolor,
					},
				},
			};

			setProbeAlarmsChartOptions(probeAlarmsHistoryOptions);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setIsLoading(true)
		fetchDataProbeAlarms();
		fetchData();
	}, [datePickerValue]);

	return (
		<div className="workhistory-container">
			<DateRangePicker
				onChange={setDatePickerValue}
				value={datePickerValue}
				clearIcon={null}
				maxDate={new Date()}
			/>
			<div className="sum">
				{sum.m3 && <p>Wypompowano: {sum.m3} m³</p>}
				{sum.pump_cycle_secs && (
					<p>
						Czas pracy: {Math.floor(sum.pump_cycle_secs / 60)}m{' '}
						{(sum.pump_cycle_secs % 60).toString().padStart(2, '0')}
						s
					</p>
				)}
			</div>
			<div className="chart-container">
				<h2>Szacunkowa wydajność pompowni</h2>
				{isLoading ? (
					<div className="loading">
						<TailSpin
							height="100"
							width="100"
							color={theme.primary}
							ariaLabel="tail-spin-loading"
							radius="1"
						/>
					</div>
				) : (
					<ReactECharts
						option={cycleHistorychartOptions}
						style={{ width: '100%', height: '100%' }}
					/>
				)}
			</div>
			<div className="chart-container">
				<h2>Alarmy Pompowni</h2>
				{probeAlarmschartOptions && (
					<ReactECharts
						option={probeAlarmschartOptions}
						style={{ width: '100%', height: '100%' }}
					/>
				)}
			</div>
		</div>
	);
};

export default DetailsWorkHistory;
