import React, { useState } from "react";
import { useEffect } from "react";
import { sendRequest } from "../../api/sendRequest";
import { StyledExpandedComponent } from "./ExpandedComponent.styles";
import { ThreeDots } from "react-loader-spinner";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

const ExpandedComponent = ({ rowId }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const theme = useTheme();
	const navigate = useNavigate();

	const fetchData = async () => {
		const getNodeProbeList = {
			action: "get-node-probe-list",
			node_id: rowId,
		};
		try {
			const response = await sendRequest(getNodeProbeList);
			setData(response.data._data_);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const getActiveStatus = (active) => {
		return active === 1 ? "Gotowość" : "Zablokowana";
	};

	const getProbeRunningStatus = (probe_running) => {
		switch (probe_running) {
			case 0:
				return "Wyłączona";
			case 1:
				return "Nie pompuje";
			case 2:
				return "Pompuje";
			case 3:
				return "Brak połączenia";
			default:
				return "Brak połączenia";
		}
	};

	const getActiveBackgroundColor = (active) => {
		return active === 1 ? theme.success : theme.danger;
	};

	const getProbeRunningBackgroundColor = (probe_running) => {
		switch (probe_running) {
			case 0:
				return theme.danger;
			case 1:
				return theme.standby;
			case 2:
				return theme.success;
			case 3:
			default:
				return theme.warning;
		}
	};

	if (isLoading)
		return (
			<ThreeDots
				height="80"
				width="80"
				radius="9"
				color={theme.primary}
				ariaLabel="loading"
				wrapperStyle={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			/>
		);
	return (
		<StyledExpandedComponent>
			<thead>
				<tr>
					<th>Nazwa</th>
					<th>Opis</th>
					<th>Adres</th>
					<th>Status</th>
					<th>Stan Pracy</th>
				</tr>
			</thead>
			<tbody>
				{data.map((row) => (
					<tr
						key={row.probe_id}
						onClick={() => navigate(`/details/${row.probe_id}`)}>
						<td>{row.probe_name}</td>
						<td>{row.description}</td>
						<td>{row.location}</td>
						<td
							style={{
								backgroundColor: getActiveBackgroundColor(
									row.active
								),
								color: "white",
							}}>
							{getActiveStatus(row.active)}
						</td>
						<td
							style={{
								backgroundColor: getProbeRunningBackgroundColor(
									row.probe_running
								),
								color: "white",
							}}>
							{getProbeRunningStatus(row.probe_running)}
						</td>
					</tr>
				))}
			</tbody>
		</StyledExpandedComponent>
	);
};

export default ExpandedComponent;
