import Select from "react-select";
import * as XLSX from "xlsx";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ReportsLayout } from "../Reports.style";
import useAccessControl from "../../../hooks/useAccessControl";
import { Button } from "../../../components/Button/Button";
import { StyledDataTable } from "../../../components/Table/Table.styles";
import { sendRequest } from "../../../api/sendRequest";
import LoadingComponent from "../../../components/Table/LoadingComponent";
import { useStyledSelect } from "../../../components/Select/StyledSelect";

const ReportsPumps = () => {
	const customStyles = useStyledSelect();
	const isAccessVerified = useAccessControl();
	const navigate = useNavigate();
	const monthAgo = new Date(new Date().setDate(new Date().getDate() - 30));
	const today = new Date();
	const [datePickerValue, setDatePickerValue] = useState([monthAgo, today]);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [probesAll, setProbesAll] = useState([]);
	const [selectedProbe, setSelectedProbe] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState("0");
	const [sortDirection, setSortDirection] = useState("asc");
	const [searchValue, setSearchValue] = useState("");

	const fetchData = async (
		page,
		size = perPage,
		column = sortColumn,
		direction = sortDirection,
		newDates = datePickerValue,
		currentSelectedProbe = selectedProbe,
		search
	) => {
		setLoading(true)
		const startString = formatDate(newDates[0]) + " 00:00:00";
		const endString = formatDate(newDates[1]) + " 23:59:59" ;
		const formattedDate = `${startString}-${endString}`;

		const getPumpsStatistics = {
			action: "get-probe-pumps-statistics-register",
			_data_: {
				page,
				perPage: size,
				date: formattedDate,
				sortColumn: column,
				sortDirection: direction,
				probe_id: currentSelectedProbe,
				search: search,
			},
		};
		const getProbesAll = {
			action: "get-probes-all",
			nodes: false,
		};

		try {
			const pumpsStatisticsResponse = await sendRequest(
				getPumpsStatistics
			);
			const probesAllResponse = await sendRequest(getProbesAll);
			setProbesAll(
				probesAllResponse.data._data_.map((probe) => ({
					label:
						probe.location !== "undefined"
							? `${probe.location} (${probe.probe_name})`
							: `${probe.probe_description} (${probe.probe_name})`,
					value: probe.probe_id,
				}))
			);

			setTotalRows(pumpsStatisticsResponse.data._data_.rows);
			setData(pumpsStatisticsResponse.data._data_.results);
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchData(1);
	}, []);

	const exportToExcel = async () => {
		const startString = formatDate(datePickerValue[0]) + " 00:00";
		const endString = formatDate(datePickerValue[1]) + " 23:59";
		const formattedDate = `${startString}-${endString}`;

		const getPumpsStatistics = {
			action: "get-probe-pumps-statistics",
			_data_: {
				page: 1,
				perPage: 1000,
				date: formattedDate,
				sortColumn: "0",
				sortDirection: "asc",
				probe_id: [],
				search: "",
			},
		};

		const response = await sendRequest(getPumpsStatistics);
		const cleanedData = response.data._data_.results.map((record) => {
			const { total_rows, ...rest } = record;
			return rest;
		});

		const ws = XLSX.utils.json_to_sheet(cleanedData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "reportPumps.xlsx");
	};

	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");

		return `${year}/${month}/${day}`;
	};

	const handleDateChange = (value) => {
		setDatePickerValue(value);
		fetchData(
			currentPage,
			perPage,
			sortColumn,
			sortDirection,
			value,
			selectedProbe,
			searchValue
		);
	};

	const handlePageChange = (page) => {
		fetchData(
			page,
			perPage,
			sortColumn,
			sortDirection,
			datePickerValue,
			selectedProbe,
			searchValue
		);
		setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchData(
			page,
			newPerPage,
			sortColumn,
			sortDirection,
			datePickerValue,
			selectedProbe,
			searchValue
		);
		setPerPage(newPerPage);
	};

	const handleSort = async (column, sortDirection) => {
		setSortDirection(sortDirection);
		setSortColumn(column.sortField);
		fetchData(
			currentPage,
			perPage,
			column.sortField,
			sortDirection,
			datePickerValue,
			selectedProbe,
			searchValue
		);
	};
	const handleSelectProbe = (selectedOptions) => {
		const probeValues = selectedOptions
			? selectedOptions.map((option) => option.value)
			: [];
		setSelectedProbe(probeValues);
		fetchData(
			currentPage,
			perPage,
			sortColumn,
			sortDirection,
			datePickerValue,
			probeValues,
			searchValue
		);
	};

	const handleSearchChange = (e) => {
		setSearchValue(e.target.value);
		setCurrentPage(1)
	};

	useEffect(() => {
		const debounceTimeout = setTimeout(() => {
			fetchData(
				currentPage,
				perPage,
				sortColumn,
				sortDirection,
				datePickerValue,
				selectedProbe,
				searchValue
			);
		}, 500);

		return () => {
			clearTimeout(debounceTimeout);
		};
	}, [searchValue]);

	const columns = [
		{
			name: "Nazwa pompowni",
			selector: (row) => row.description || row.probe_id,
			grow: 1,
			sortable: true,
			sortField: "0",
		},
		{
			name: "Adres",
			selector: (row) => row.location,
			grow: 1,
			sortable: true,
			sortField: "1",
		},
		{
			name: "Ilość Cykli",
			selector: (row) => row.cycles,
			grow: 1,
			sortable: true,
			sortField: "2",
		},
		{
			name: "Wypompowana ilość",
			selector: (row) => row.sum_m3,
			grow: 1,
			sortable: true,
			sortField: "3",
		},
		{
			name: "Łączny czas pracy",
			selector: (row) => row.sum_pump_cycle_secs,
			grow: 1,
			sortable: true,
			sortField: "4",
		},
	];

	
	if (!isAccessVerified) {
		return null;
	}

	return (
		<ReportsLayout>
			<h2 className="page-title">Raporty Pomp</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: "Wiersze na stronę:",
					}}
					columns={columns}
					data={data}
					noDataComponent="Brak danych spełniających kryteria"
					progressPending={loading}
					progressComponent={<LoadingComponent />}
					pagination
					paginationServer
					paginationTotalRows={totalRows}
					paginationDefaultPage={currentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					onSort={handleSort}
					paginationRowsPerPageOptions={[
						10, 15, 20, 30, 50, 100, 150, 250, 500,
					]}
					sortServer
					onRowClicked={(row) => {
						navigate(`/details/${row.probe_id}`);
					}}
				/>
				<div>
					<input
						className="search-input"
						placeholder="Szukaj..."
						onChange={handleSearchChange}
						value={searchValue}
					/>
				</div>
				<div className="datepicker-container">
					<DateRangePicker
						onChange={handleDateChange}
						value={datePickerValue}
						clearIcon={null}
						maxDate={new Date()}
					/>
					<Select
						placeholder="Wybierz pompownie..."
						styles={customStyles}
						className="select"
						isMulti
						options={probesAll}
						onChange={handleSelectProbe}
					/>
					<Button
						style={{ padding: "5px 40px" }}
						className="button"
						onClick={exportToExcel}>
						Eksportuj do Excela
					</Button>
				</div>
			</div>
		</ReportsLayout>
	);
};

export default ReportsPumps;
