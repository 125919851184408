import styled from "styled-components";
import { Sidebar } from "react-pro-sidebar";

export const StyledSidebar = styled(Sidebar)`
	top: 50% !important;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
		rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

	ul li.ps-active {
		position: relative;

		svg {
			fill: ${(props) => props.theme.primary};
		}

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 10px;
			height: 100%;
			background-color: ${(props) => props.theme.primary};
		}
	}

	.ps-submenu-content {
		background-color: ${(props) => props.theme.white};
	}

	.logo,
	.small-logo {
		height: 60px;
	}
	.expand-icon {
		position: absolute;
		bottom: 5%;
		left: 50%;
		transition: transform 0.3s;
	}

	.settings-menu-item {
		display: none;
	}
	@media (max-width: 1024px) {
		.logo,
		.small-logo {
			height: 50px;
		}
	}
	@media (max-width: 768px) {
		.expand-icon {
			display: none;
		}
		.settings-menu-item {
			display: block;
		}
	}
	@media (max-height: 590px) {
		.ps-menu-root {
			max-height: 50vh !important;
			overflow-y: scroll;
		}
	}
`;
