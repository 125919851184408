import React, { useState, useRef, useEffect } from "react";
import { StyledDataTable } from "../../components/Table/Table.styles";
import { Button } from "../../components/Button/Button";
import { ReactComponent as Icoedytuj } from "./../../svg/ico-edytuj.svg";
import { ReactComponent as Icozamknij } from "./../../svg/ico-zamknij.svg";
import { sendRequest } from "../../api/sendRequest";
import { RegionsLayout } from "./Regions.style";
import useAccessControl from "../../hooks/useAccessControl";
import Select from "react-select";
import LoadingComponent from "../../components/Table/LoadingComponent";
import { useStyledSelect } from "../../components/Select/StyledSelect";

const Regions = () => {
	const customStyles = useStyledSelect()
	const isAccessVerified = useAccessControl();
	const [isAddRegionModalOpen, setIsAddRegionModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isDeleteRegionModalOpen, setIsDeleteRegionModalOpen] =
		useState(false);
	const modalContentRef = useRef(null);
	const [regionId, setRegionId] = useState(null);
	const [regionName, setRegionName] = useState("");
	const [regionNameError, setRegionNameError] = useState("");
	const [userData, setUserData] = useState([]);
	const [deletingRegion, setDeletingRegion] = useState("");
	const [deletingRegionId, setDeletingRegionId] = useState(null);
	const [confirmedDeletingRegion, setConfirmedDeletingRegion] =
		useState(false);
	const [editMode, setEditMode] = useState(false);
	const [unassignedProbes, setUnassignedProbes] = useState([]);
	const [probesAll, setProbesAll] = useState([]);
	const [selectedProbes, setSelectedProbes] = useState([]);

	useEffect(() => {
		const getRegionsRequest = {
			action: "get-regions",
		};
		const getProbesUnassigned = {
			action: "get-probes-unassigned",
		};

		Promise.all([
			sendRequest(getRegionsRequest),
			sendRequest(getProbesUnassigned),
		])
			.then(([regionsResponse, probesUnassignedResponse]) => {
				setUserData(regionsResponse.data._data_);
				setUnassignedProbes(
					probesUnassignedResponse.data._data_.map((probe) => ({
						label: `${probe.description} (${probe.probe_name})`,
						value: probe.probe_id,
					}))
				);
				setIsLoading(false)
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false)
			});
	}, []);

	useEffect(() => {
		if (confirmedDeletingRegion && deletingRegionId) {
			const deleteRegionRequest = {
				action: "delete-region",
				id: deletingRegionId,
				confirmed: confirmedDeletingRegion,
			};

			sendRequest(deleteRegionRequest)
				.then((response) => {
					if (response.data.status === 1) {
						const getRegionsRequest = {
							action: "get-regions",
						};

						sendRequest(getRegionsRequest)
							.then((usersResponse) => {
								setUserData(usersResponse.data._data_);
							})
							.catch((error) => {
								console.error(error);
							});
					} else {
						console.error("Error deleting region:", response.data);
					}
				})
				.catch((error) => {
					console.error(error);
				});

			setConfirmedDeletingRegion(false);
			setDeletingRegionId(null);
			setIsDeleteRegionModalOpen(false);
		}
	}, [confirmedDeletingRegion, deletingRegionId]);

	const handleAddRegion = () => {
		let isValid = true;

		if (!regionName) {
			setRegionNameError("Nazwa regionu jest wymagana");
			isValid = false;
		} else {
			setRegionNameError("");
		}

		if (isValid) {
			const addRegionRequest = {
				action: "add-region",
				_data_: {
					region_name: regionName,
				},
			};

			sendRequest(addRegionRequest).then((response) => {
				if (response.data.status === 1) {
					closeModal();
					setRegionName("");

					const getRegionsRequest = {
						action: "get-regions",
					};
					sendRequest(getRegionsRequest)
						.then((regionsResponse) => {
							setUserData(regionsResponse.data._data_);
						})
						.catch((error) => {
							console.error(error);
						});
				} else {
					setRegionNameError(response.data._data_.username);
				}
			});
		}
	};

	const handleOpenModalEdit = async (row) => {
		const getProbesAllRegion = {
			action: "get-probes-all-region",
			region_id: row.id,
		};

		try {
			const response = await sendRequest(getProbesAllRegion);
			const formattedProbes = response.data._data_.map((probe) => ({
				label: `${probe.description} (${probe.probe_name})`,
				value: probe.probe_id,
			}));
			setSelectedProbes(formattedProbes);
			setProbesAll([...unassignedProbes, ...formattedProbes]);
		} catch (error) {
			console.error(error);
		}
		setEditMode(true);
		setIsAddRegionModalOpen(true);
		setRegionId(row.id);
		setRegionName(row.r_name);
	};

	const closeModal = () => {
		editMode && setRegionName("");
		setIsAddRegionModalOpen(false);
		setIsDeleteRegionModalOpen(false);
		setProbesAll([]);
		setSelectedProbes([]);
		setEditMode(false);
	};

	const openModal = () => {
		setEditMode(false);
		setIsAddRegionModalOpen(true);
	};

	const handleEditRegion = () => {
		let isValid = true;

		if (!regionName) {
			setRegionNameError("Nazwa regionu jest wymagana");
			isValid = false;
		} else {
			setRegionNameError("");
		}

		if (isValid) {
			const editRegionRequest = {
				action: "edit-region",
				_data_: {
					id: regionId,
					region_name: regionName,
					regionProbe: selectedProbes.map((probe) => probe.value),
				},
			};
			sendRequest(editRegionRequest).then((response) => {
				if (response.data.status === 1) {
					closeModal();
					setRegionName("");

					const getRegionsRequest = {
						action: "get-regions",
					};
					const getProbesUnassigned = {
						action: "get-probes-unassigned",
					};
					sendRequest(getRegionsRequest)
						.then((regionsResponse) => {
							setUserData(regionsResponse.data._data_);
						})
						.catch((error) => {
							console.error(error);
						});

					sendRequest(getProbesUnassigned)
						.then((probesUnassignedResponse) => {
							setUnassignedProbes(
								probesUnassignedResponse.data._data_.map((probe) => ({
									label: `${probe.description} (${probe.probe_name})`,
									value: probe.probe_id,
								}))
							);
						})
						.catch((error) => {
							console.error(error);
						});
				} else if (response.data.status === 0) {
					setRegionNameError("Nazwa regionu już istnieje");
				}
			});
		}
	};

	const handleDeleteUser = (row) => {
		setIsDeleteRegionModalOpen(true);
		setDeletingRegionId(row.id);
		setDeletingRegion(row.r_name);
	};

	const columns = [
		{
			name: "Id",
			selector: (row) => row.id,
			grow: 1,
		},
		{
			name: "Nazwa",
			selector: (row) => row.r_name,
			grow: 2,
		},
		{
			name: "Actions",
			cell: (row) => (
				<div style={{ display: "flex", gap: "15px" }}>
					<Icoedytuj
						style={{ cursor: "pointer" }}
						width={20}
						onClick={() => handleOpenModalEdit(row)}
					/>
					<Icozamknij
						style={{ cursor: "pointer" }}
						width={20}
						onClick={() => handleDeleteUser(row)}
					/>
				</div>
			),
			grow: 1,
		},
	];

	if (!isAccessVerified) {
		return null;
	}
	return (
		<RegionsLayout>
			<h2 className="page-title">Regiony</h2>
			<div className="table-container">
				<StyledDataTable noDataComponent="Brak danych spełniających kryteria" progressPending={isLoading} progressComponent={<LoadingComponent />} columns={columns} data={userData} />
				<Button
					className="add-user"
					onClick={openModal}
					style={{
						fontSize: "16px",
					}}>
					Dodaj Region
				</Button>
			</div>
			{isAddRegionModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{editMode ? (
							<h2>Edytuj Region</h2>
						) : (
							<h2>Dodaj Region</h2>
						)}
						<div>
							<label htmlFor="regionName">Nazwa regionu:</label>
							<input
								id="regionName"
								type="text"
								placeholder="Nazwa regionu"
								value={regionName}
								onChange={(e) => setRegionName(e.target.value)}
							/>
							<p className="err-msg">{regionNameError}</p>
						</div>
						{editMode && (
							<div>
								<label htmlFor="regionName">
									Wybrane Pompownie:
								</label>
								<Select
									styles={customStyles}
									placeholder="Wybierz pompownie..."
									className="select"
									isMulti
									options={probesAll}
									defaultValue={selectedProbes}
									onChange={setSelectedProbes}
								/>
							</div>
						)}
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{editMode ? (
								<Button
									className="add"
									onClick={handleEditRegion}>
									Zapisz
								</Button>
							) : (
								<Button
									className="add"
									onClick={handleAddRegion}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isDeleteRegionModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć region:</h2>
						<h2>{deletingRegion} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() =>
									setConfirmedDeletingRegion(true)
								}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
		</RegionsLayout>
	);
};

export default Regions;
