import useAccessControl from "../../hooks/useAccessControl";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { sendRequest } from "../../api/sendRequest";
import "ol/ol.css";
import { useTheme } from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { Button } from "../../components/Button/Button";
import LoadingThreeDots from "../../components/Loading/LoadingThreeDots";
import PumpStatus from "../../components/Details/General/PumpStatus";
import ProbeLogsRegister from "../../components/Details/ProbeLogsRegister/ProbeLogsRegister";

const SingleProbe = ({ probe_id, onLoaded }) => {
	const isAccessVerified = useAccessControl();
	const [isLoading, setIsLoading] = useState(true);
	const [isProbeLoading, setIsProbeLoading] = useState(false);
	const [probeLogsData, setProbeLogsData] = useState([]);
	const [probe, setProbe] = useState([]);
	const [probeSchedules, setProbeSchedules] = useState([]);
	const [probeStats, setProbeStats] = useState([]);
	const [prevProbeRunning, setPrevProbeRunning] = useState(null);
	const theme = useTheme();
	const navigate = useNavigate();
	const [buttonError, setButtonError] = useState("");

	const fetchData = async () => {
		setIsLoading(true);

		const getProbeStats = {
			action: "get-probe-pump-stats",
			probe_id: probe_id,
		};

		try {
			const [probeResponse, probeSchedulesResponse, probeStatsResponse] =
				await Promise.all([
					sendRequest({ action: "get-probe", probe_id: probe_id }),
					sendRequest({
						action: "get-probe-schedules",
						probe_id: probe_id,
					}),
					sendRequest(getProbeStats),
				]);

			setProbeStats(probeStatsResponse.data._data_);
			const probeData = probeResponse.data._data_;
			setProbe(probeData);

			checkPumpStatus();

			setProbeSchedules(probeSchedulesResponse.data._data_);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
		onLoaded();
	};

	const updateStatus = (activeStatus, probeRunning, error) => {
		setProbe((prevState) => ({
			...prevState,
			probe_running: probeRunning,
			active: activeStatus,
			error: error,
		}));
		updatePumpStats();
	};

	const updatePumpStats = async () => {
		const getProbeStats = {
			action: "get-probe-pump-stats",
			probe_id: probe_id,
		};
		try {
			const probeStatsResponse = await sendRequest(getProbeStats);
			setProbeStats(probeStatsResponse.data._data_);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (
			prevProbeRunning !== null &&
			prevProbeRunning !== probe.probe_running
		) {
			updatePumpStats();
		}

		setPrevProbeRunning(probe.probe_running);
	}, [probe]);

	const checkPumpStatus = async () => {
		const checkPumpStatus = {
			action: "get-probe-pump-status",
			probe_id: probe_id,
		};
		try {
			const response = await sendRequest(checkPumpStatus);
			const active = response.data._data_.active;
			const probe_running = response.data._data_.probe_running;
			const error = response.data._data_.error;
			updateStatus(active, probe_running, error);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			checkPumpStatus();
		}, 5000);
		fetchData();
		setProbeLogsData([]);
		return () => {
			clearInterval(intervalId);
		};
	}, [probe_id]);

	const isActiveSchedule = (schedule) => {
		const currentDate = new Date();
		const startDate = new Date(schedule.start);
		const stopDate = new Date(schedule.stop);

		const [hoursNow, minutesNow] = [
			currentDate.getHours(),
			currentDate.getMinutes(),
		];
		const [hoursStart, minutesStart] = schedule.time_start
			.split(":")
			.map(Number);
		const [hoursStop, minutesStop] = schedule.time_stop
			.split(":")
			.map(Number);

		const isWithinDate =
			currentDate >= startDate && currentDate <= stopDate;
		const isWithinTime =
			(hoursNow > hoursStart ||
				(hoursNow === hoursStart && minutesNow >= minutesStart)) &&
			(hoursNow < hoursStop ||
				(hoursNow === hoursStop && minutesNow <= minutesStop));

		return isWithinDate && isWithinTime;
	};

	function formatTime(secondsTotal) {
		const minutes = Math.floor(secondsTotal / 60);
		const seconds = secondsTotal % 60;
		return `${minutes}m ${seconds}s`;
	}

	if (isLoading) return "";
	return (
		<div className="single-probe">
			<a
				href={`${window.location.origin}/details/${probe.probe_id}`}
				target="_blank"
				style={{ textDecoration: "none", color: "inherit" }}>
				<div className="name-card-title">
					<h2>{probe.description || probe.probe_id}</h2>
				</div>
			</a>
			<div className="metrics-card">
				<PumpStatus probe={probe} />
				<div className="metrics-card-24">
					<div className="metrics-card-title">Dzisiaj</div>
					<div className="metrics-card-stats">
						<div>
							<p>Czas pracy(min):</p>
							<p>Cykli pracy: </p>
							<p>Wypompowana ilość(m³):</p>
						</div>
						<div>
							<p>
								{formatTime(
									probeStats["24h"].sum_pump_cycle_secs
								)}
							</p>
							<p>{probeStats["24h"].cycles}</p>
							<p>{probeStats["24h"].sum_m3}</p>
						</div>
					</div>
				</div>
				<div className="metrics-card-month">
					<div className="metrics-card-title">
						Bieżący <br /> miesiąc
					</div>
					<div className="metrics-card-stats">
						<div>
							<p>Czas pracy(min):</p>
							<p>Cykli pracy: </p>
							<p>Wypompowana ilość(m³):</p>
						</div>
						<div>
							<p>
								{formatTime(
									probeStats.current.sum_pump_cycle_secs
								)}
							</p>
							<p>{probeStats.current.cycles}</p>
							<p>{probeStats.current.sum_m3}</p>
						</div>
					</div>
				</div>
				<div className="metrics-card-lastmonth">
					<div className="metrics-card-title">
						Poprzedni <br /> miesiąc
					</div>
					<div className="metrics-card-stats">
						<div>
							<p>Czas pracy(min):</p>
							<p>Cykli pracy: </p>
							<p>Wypompowana ilość(m³):</p>
						</div>
						<div>
							<p>
								{formatTime(
									probeStats.previous.sum_pump_cycle_secs
								)}
							</p>
							<p>{probeStats.previous.cycles}</p>
							<p>{probeStats.previous.sum_m3}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="status">
				<div>
					Adres instalacji:
					<p className="bold">{probe.location}</p>
				</div>
				<div>
					Ostatni odczyt:
					<p className="bold">{probe.last_login}</p>
				</div>
				<ol>
					Harmonogramy:
					{probeSchedules && probeSchedules.length > 0 ? (
						probeSchedules.map((schedule, index) => {
							const activeClass = isActiveSchedule(schedule)
								? "active-schedule"
								: "";
							return (
								<p key={index} className={activeClass}>
									<p className="bold">
										{schedule.start}
										--
										{schedule.stop}
									</p>
									<p>
										{schedule.time_start}-
										{schedule.time_stop}
									</p>
								</p>
							);
						})
					) : (
						<p>Brak</p>
					)}
				</ol>
			</div>
			<ProbeLogsRegister
				isProbeLoading={isProbeLoading}
				setProbeLogsData={setProbeLogsData}
				probeLogsData={probeLogsData}
				probeId={probe_id}
			/>
		</div>
	);
};

export default SingleProbe;
