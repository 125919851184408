import { useState, useEffect, useRef } from "react";
import { NavbarContainer } from "./TopNavbar.styles";
import { ReactComponent as Icoustawienia } from "./../../svg/ico-ustawienia.svg";
import { ReactComponent as Icowyloguj } from "./../../svg/ico-wyloguj.svg";
import { ReactComponent as Icopowiadomienie } from "./../../svg/ico-powiadomienie.svg";
import { ReactComponent as Icokonfiguracja } from "./../../svg/ico-konfiguracja.svg";
import { ReactComponent as BurgerMenu } from "./../../svg/ico-rozwin-menu.svg";
import { ReactComponent as SunIcon } from "./../../svg/sun_icon.svg";
import { ReactComponent as MoonIcon } from "./../../svg/moon_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { sendRequest } from "../../api/sendRequest";
import logo from "./../../img/logoNQM.png";

const TopNavbar = ({ toggleTheme, toggled, setToggled }) => {
	const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);
	const [isNotificationsDropdownOpen, setIsNotificationsDropdownOpen] =
		useState(false);
	const [notificationsData, setNotificationsData] = useState([]);
	const navigate = useNavigate();
	const theme = useTheme();
	const notificationsRef = useRef(null);
	const groupName = window.localStorage.getItem("group_name");
	const userDescription = window.localStorage.getItem("user_description");
	const [activeTheme, setActiveTheme] = useState(
		localStorage.getItem("selected-theme")
	);

	const fetchData = async () => {
		const getProbeAlarms = {
			action: "get-probe-pumps-alarms",
		};

		try {
			const probeAlarmsResponse = await sendRequest(getProbeAlarms);
			if (Array.isArray(probeAlarmsResponse.data._data_)) {
				const sortedNotifications =
					probeAlarmsResponse.data._data_.sort(
						(a, b) => new Date(b.ctime) - new Date(a.ctime)
					);
				setNotificationsData(sortedNotifications);
			} else {
				console.warn("Unexpected data structure received");
			}
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	const handleDocumentClick = (e) => {
		if (
			notificationsRef.current &&
			!notificationsRef.current.contains(e.target)
		) {
			setIsNotificationsDropdownOpen(false);
		}
	};
	const handleThemeClick = () => {
		toggleTheme();
		activeTheme === "light"
			? setActiveTheme("dark")
			: setActiveTheme("light");
	};

	useEffect(() => {
		const timeout = isNotificationsDropdownOpen
			? setTimeout(() => {
					document.addEventListener("click", handleDocumentClick);
			  }, 0)
			: null;

		return () => {
			document.removeEventListener("click", handleDocumentClick);
			if (timeout) clearTimeout(timeout);
		};
	}, [isNotificationsDropdownOpen]);

	const toggleSettingsDropdown = () => {
		setIsSettingsDropdownOpen(!isSettingsDropdownOpen);
		setIsNotificationsDropdownOpen(false);
	};
	const toggleSNotificationDropdown = () => {
		setIsNotificationsDropdownOpen(!isNotificationsDropdownOpen);
		setIsSettingsDropdownOpen(false);
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("login");
		localStorage.removeItem("password");
		sessionStorage.removeItem("mapProbes");
		window.location.reload(true);
	};

	const errorTranslations = {
		DRY_ERR: "Suchobieg",
		CALL_SERVICE: "Wezwij technika",
		PUMP_ERR: "Błąd pompy",
	};

	const routeLinks = JSON.parse(localStorage.getItem("routes") || "[]");
	const settingsLinks = routeLinks.filter((route) => route.visible === 1);
	return (
		<NavbarContainer>
			<div className="burger-menu" onClick={() => setToggled(!toggled)}>
				<BurgerMenu fill={theme.fontcolor} width={35} />
			</div>
			<img
				src={logo}
				alt="Logo"
				className="logo"
				style={{ height: 45 }}
			/>
			<div className="region-name">{groupName || "Default Group"}</div>
			<div className="logged-as">
				<div>Zalogowany jako: </div>
				<div>{userDescription || "Unknown User"}</div>
			</div>
			<div>
				{activeTheme === "dark" ? (
					<SunIcon
						className="theme-icon"
						onClick={handleThemeClick}
						height={45}
						width={45}
						fill={theme.fontcolor}
					/>
				) : (
					<MoonIcon
						className="theme-icon"
						onClick={handleThemeClick}
						height={45}
						width={45}
						fill={theme.fontcolor}
					/>
				)}
				<div className="notification">
					<Icopowiadomienie
						className="notification-icon"
						width={45}
						fill={
							notificationsData.length
								? theme.danger
								: theme.primary
						}
						onClick={toggleSNotificationDropdown}
					/>
					{notificationsData.length > 0 && (
						<div className="notification-badge">
							{notificationsData.length}
						</div>
					)}
				</div>
				{isNotificationsDropdownOpen && (
					<div
						ref={notificationsRef}
						className="dropdown-menu menu-notifications">
						{notificationsData.length > 0 ? (
							notificationsData.map((notification, index) => (
								<div
									key={index}
									className="notification-item"
									onClick={() => {
										setIsNotificationsDropdownOpen(false);
										navigate(
											`/details/${notification.probe_id}`
										);
									}}>
									<div className="notification-top">
										<div className="notification-description">
											{notification.description
												? notification.description
												: notification.probe_id}
										</div>
										<div className="notification-time">
											{notification.ctime}
										</div>
									</div>
									<div className="notification-error">
										{notification.error
											.map(
												(error) =>
													errorTranslations[error] ||
													error
											)
											.join(", ")}
									</div>
								</div>
							))
						) : (
							<p className="notification-info">
								Brak powiadomień
							</p>
						)}
					</div>
				)}
			</div>
			<div>
				<Icoustawienia
					className="settings-icon"
					width={55}
					fill={theme.primary}
					onClick={toggleSettingsDropdown}
				/>
				{isSettingsDropdownOpen && (
					<div className="dropdown-menu menu-settings">
						<Link
							key="admin-users-admin"
							id="admin-users-admin"
							onClick={toggleSettingsDropdown}
							to="/admin-users-admin">
							Użytkownicy
						</Link>
						{settingsLinks.map((route) => {
							let path;
							let label;

							switch (route.menu_key) {
								case "menu-admin-regions":
									path = "/admin-regions";
									label = "Regiony";
									break;
								case "menu-admin-logs":
									path = "/admin-logs";
									label = "Logi";
									break;
								case "menu-admin-settings":
									path = "/admin-settings";
									label = "Ustawienia";
									break;
								case "menu-admin-map-edit":
									path = "/admin-map-edit";
									label = "Edycja Mapy";
									break;

								default:
									return null;
							}

							return (
								<Link
									key={route.menu_key}
									id={route.menu_key}
									onClick={toggleSettingsDropdown}
									to={path}>
									{label}
								</Link>
							);
						})}
					</div>
				)}
				<Icowyloguj
					onClick={handleLogout}
					width={45}
					fill={theme.danger}
				/>
			</div>
		</NavbarContainer>
	);
};

export default TopNavbar;
