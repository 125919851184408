import React from 'react'

const NotAuthorized = () => {
	return (
		<div
			style={{
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<h2>Insufficient Authorization</h2>
		</div>
	)
}

export default NotAuthorized
