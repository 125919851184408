import { useState, useEffect } from "react";
import { StyledDataTable } from "../../Table/Table.styles";
import { Button } from "../../Button/Button";
import { sendRequest } from "../../../api/sendRequest";
import { pumpRequest } from "../../../api/pumpRequest";
import LoadingComponent from "../../Table/LoadingComponent";
import { timer } from "rxjs";
import { switchMap, filter, take, tap } from "rxjs/operators";
import { TailSpin } from "react-loader-spinner";
import { useTheme } from "styled-components";

const AdvancedSettings = ({ probe }) => {
	const theme = useTheme();
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [modifiedValues, setModifiedValues] = useState({});
	const [eventId, setEventId] = useState("");
	const [paramName, setParamName] = useState("");
	const [buttonStatus, setButtonStatus] = useState({});
	const [shouldCheckPumpStatus, setShouldCheckPumpStatus] = useState(false);
	const [isProbeLoading, setIsProbeLoading] = useState(false);
	const [error, setError] = useState(null);
	const [info, setInfo] = useState("");

	useEffect(() => {
		if (!shouldCheckPumpStatus) return;
		const subscription = timer(0, 2000)
			.pipe(
				switchMap(() => {
					console.log("Checking status...");
					return pumpRequest({
						action: "pump-observable",
						probe_id: probe.probe_id,
						event_id: eventId,
					});
				}),
				tap((response) => {
					console.log(
						"Received response before filtering:",
						response
					);
				}),
				filter((response) => {
					if (!response.data._data_) {
						return false;
					}
					if (response.data._data_.event_response === "noresponse") {
						setInfo("Brak komunikacji.");
						setIsProbeLoading(false);
					} else if (
						response.data._data_.event.includes(
							"read_register_done"
						)
					) {
						setInfo("Odczyt zakończony pomyślnie!");

						const updatedData = data.map((item) => {
							if (item.param_name === paramName) {
								return {
									...item,
									param_value:
										response.data._data_.event_response,
								};
							}
							return item;
						});

						setData(updatedData);
					} else if (
						response.data._data_.event.includes(
							"write_register_done"
						)
					) {
						setInfo("Zapis zakończony pomyślnie!");
					}
					const isDone = response.data._data_.event.includes("done");
					if (isDone) {
						console.log('Status is "done"');
					}
					return isDone;
				}),
				tap((response) => {
					console.log("Received response after filtering:", response);
				}),
				take(1)
			)
			.subscribe(
				() => {
					setIsProbeLoading(false);
					setShouldCheckPumpStatus(false);
				},
				(requestError) => {
					setError(requestError);
					setIsProbeLoading(false);
				}
			);
		return () => subscription.unsubscribe();
	}, [shouldCheckPumpStatus, setShouldCheckPumpStatus]);

	const fetchData = async () => {
		const getProbeConfig = {
			action: "get-probe-config",
			probe_id: probe.probe_id,
		};

		try {
			const response = await sendRequest(getProbeConfig);
			const updatedData = response.data._data_.map((item, index) => {
				return {
					...item,
					id: Date.now() + index,
				};
			});
			setData(updatedData);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		try {
			fetchData();
		} catch (error) {
			console.error(
				"An error occurred while processing information data:",
				error
			);
		}
	}, []);

	const handleAddRow = () => {
		const newRow = {
			id: Date.now(),
			param_name: "",
			param_value: "",
			isNew: true,
		};
		setData((prevData) => [...prevData, newRow]);
	};

	const handleValueChange = (key, id, newValue) => {
		setData((prevData) =>
			prevData.map((item) =>
				item.id === id ? { ...item, [key]: newValue } : item
			)
		);
	};

	const handleDelete = (id) => {
		setData((prevData) => prevData.filter((item) => item.id !== id));
	};

	const handleSave = async (e) => {
		const buttonId = e.currentTarget.getAttribute("data-button-id");
		setButtonStatus({ [buttonId]: "" });
		const hasEmptyRow = data.some(
			(row) => !row.param_name || !row.param_value
		);

		if (hasEmptyRow) {
			alert("Uzupełnij brakujące informacje w tabeli przed zapisaniem.");
			return;
		}

		const settingsObject = generateSettingsObject();

		const setProbeConfig = {
			action: "set-probe-config",
			probe_id: probe.probe_id,
			_data_: settingsObject,
		};
		try {
			await sendRequest(setProbeConfig);
			setButtonStatus({ [buttonId]: "success" });
		} catch (error) {
			console.error(error);
			setButtonStatus({ [buttonId]: "error" });
		}
	};

	const handleReadRegistry = async (param_name) => {
		if (!param_name || param_name.trim() === "") {
			alert("Podaj nazwę parametru przed odczytem.");
			return;
		}

		setParamName(param_name);
		setIsProbeLoading(true);
		const pumpGetRegister = {
			action: "pump-get-register",
			probe_id: probe.probe_id,
			register: param_name,
		};

		try {
			const pumpGetRegisterResponse = await pumpRequest(pumpGetRegister);
			setEventId(pumpGetRegisterResponse.data.event_id);
			setShouldCheckPumpStatus(true);
		} catch (error) {
			setIsProbeLoading(false);
			console.error(error);
		}
	};

	const handleSaveRegistry = async (row) => {
		if (!row.param_name || !row.param_value) {
			alert("Uzupełnij Parametr oraz Wartość przed zapisaniem.");
			return;
		}

		setIsProbeLoading(true);
		const pumpSaveRegister = {
			action: "pump-set-register",
			probe_id: probe.probe_id,
			register: row.param_name,
			value: row.param_value,
		};
		try {
			const pumpSaveRegisterResponse = await pumpRequest(
				pumpSaveRegister
			);
			setEventId(pumpSaveRegisterResponse.data.event_id);
			setShouldCheckPumpStatus(true);
		} catch (error) {
			setIsProbeLoading(false);
			console.error(error);
		}
	};

	const generateSettingsObject = () => {
		return data.reduce((acc, item) => {
			acc[item.param_name] =
				modifiedValues[item.param_name] || item.param_value;
			return acc;
		}, {});
	};

	const advancedSettingsColumns = [
		{
			name: "Parametr",
			cell: (row) => {
				if (row.isNew) {
					return (
						<input
							type="text"
							value={row.param_name}
							onChange={(e) =>
								handleValueChange(
									"param_name",
									row.id,
									e.target.value
								)
							}
						/>
					);
				}
				return row.param_name;
			},
			grow: 1,
		},
		{
			name: "Wartość",
			cell: (row) => (
				<input
					className="advanced-input"
					type="text"
					value={modifiedValues[row.param_name] || row.param_value}
					onChange={(e) =>
						handleValueChange("param_value", row.id, e.target.value)
					}
				/>
			),
			grow: 3,
		},
		{
			name: "Akcje",
			cell: (row) => (
				<div style={{ display: "flex", gap: "7px" }}>
					<Button
						onClick={() => handleReadRegistry(row.param_name)}
						style={{
							minWidth: "99px",
							fontSize: "14px",
							padding: "5px 20px",
						}}>
						Odczytaj
					</Button>
					<Button
						onClick={() => handleSaveRegistry(row)}
						style={{
							minWidth: "84px",
							fontSize: "14px",
							padding: "5px 20px",
						}}>
						Zapisz
					</Button>
					<Button
						style={{ fontSize: "14px", padding: "5px 20px" }}
						onClick={() => handleDelete(row.id)}>
						X
					</Button>
				</div>
			),
			minWidth: "270px",
			style: { justifyContent: "center" },
			grow: 2,
		},
	];
	return (
		<div>
			<Button
				data-button-id="handleSave"
				className={buttonStatus.handleSave}
				onClick={handleSave}
				style={{ marginBottom: "20px" }}>
				Zapisz
			</Button>
			<Button
				onClick={handleAddRow}
				style={{
					marginBottom: "20px",
					marginLeft: "20px",
					marginRight: "20px",
				}}>
				Dodaj
			</Button>
			<span>{info}</span>
			<div className="advanced-settings-table-container">
				<StyledDataTable
					progressComponent={<LoadingComponent />}
					noDataComponent="Brak danych spełniających kryteria"
					progressPending={isLoading}
					data={data}
					columns={advancedSettingsColumns}
				/>
			</div>
			{isProbeLoading && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						zIndex: 1000,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<TailSpin
						height="200"
						width="200"
						color={theme.primary}
						ariaLabel="tail-spin-loading"
						radius="1"
					/>
				</div>
			)}
		</div>
	);
};

export default AdvancedSettings;
