import React from "react";
import ReactDOM from "react-dom";
import { LogViewer, LogViewerSearch } from "@patternfly/react-log-viewer";
import {
	Badge,
	Button,
	Tooltip,
	Toolbar,
	ToolbarContent,
	ToolbarGroup,
	ToolbarItem,
	ToolbarToggleGroup,
} from "@patternfly/react-core";
import OutlinedPlayCircleIcon from "@patternfly/react-icons/dist/esm/icons/outlined-play-circle-icon";
import ExpandIcon from "@patternfly/react-icons/dist/esm/icons/expand-icon";
import PauseIcon from "@patternfly/react-icons/dist/esm/icons/pause-icon";
import PlayIcon from "@patternfly/react-icons/dist/esm/icons/play-icon";
import EllipsisVIcon from "@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon";
import DownloadIcon from "@patternfly/react-icons/dist/esm/icons/download-icon";
import "@patternfly/react-core/dist/styles/base.css";
import { sendRequest } from "../api/sendRequest";
import { useTheme } from "styled-components";
import { ThemeProvider } from "styled-components";
import { darkTheme } from "../themes";

const ComplexToolbarLogViewer = () => {
	const dataSources = {
		"container-1": { type: "C", id: "data1" },
		"container-2": { type: "D", id: "data2" },
		"container-3": { type: "E", id: "data3" },
	};
	const [isPaused, setIsPaused] = React.useState(false);
	const [isFullScreen, setIsFullScreen] = React.useState(false);
	const [itemCount, setItemCount] = React.useState(0);
	const [currentItemCount, setCurrentItemCount] = React.useState(0);
	const [renderData, setRenderData] = React.useState("");
	const [selectedDataSource, setSelectedDataSource] =
		React.useState("container-1");
	const [selectDataSourceOpen, setSelectDataSourceOpen] =
		React.useState(false);
	const [timer, setTimer] = React.useState(null);
	const [selectedData, setSelectedData] = React.useState([]);
	const [buffer, setBuffer] = React.useState([]);
	const [linesBehind, setLinesBehind] = React.useState(0);
	const logViewerRef = React.useRef();
	const theme = useTheme();

	const [offset, setOffset] = React.useState(null);

	const byteSize = (str) => new Blob([str]).size;

	const fetchData = async () => {
		const getLogs = {
			action: "get-probe-system-log",
			offset: offset !== null ? offset : 0,
		};
		try {
			let response = await sendRequest(getLogs);
			let data = await response.data._data_;
			return data;
		} catch (error) {
			console.error(
				"There was a problem with the fetch operation:",
				error
			);
		}
	};

	React.useEffect(() => {
		const intervalId = window.setInterval(async () => {
			const newData = await fetchData();
			if (newData) {
				if (!isPaused) {
					setBuffer((prevBuffer) => [
						...prevBuffer,
						...(newData.log ? [newData.log] : []),
					]);
				}
				const newSizeInBytes = newData.log ? byteSize(newData.log) : 0;
				if (offset === null) {
					setOffset(newData.offset + newSizeInBytes);
				} else {
					setOffset((prevOffset) => prevOffset + newSizeInBytes);
				}
			}
		}, 2000);

		return () => {
			window.clearInterval(intervalId);
		};
	}, [offset, isPaused]);

	React.useEffect(() => {
		if (!isPaused && buffer.length > 0) {
			setCurrentItemCount(buffer.length);
			setRenderData(buffer.join("\n"));
			if (logViewerRef && logViewerRef.current) {
				logViewerRef.current.scrollToBottom();
			}
		} else if (buffer.length !== currentItemCount) {
			setLinesBehind(buffer.length - currentItemCount);
		} else {
			setLinesBehind(0);
		}
	}, [isPaused, buffer]);

	const onExpandClick = (event) => {
		const element = document.querySelector("#complex-toolbar-demo");

		if (!isFullScreen) {
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.webkitRequestFullScreen) {
				element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			}
			setIsFullScreen(true);
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				/* Safari */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				/* IE11 */
				document.msExitFullscreen();
			}
			setIsFullScreen(false);
		}
	};

	const onScroll = ({
		scrollOffsetToBottom,
		scrollDirection,
		scrollUpdateWasRequested,
	}) => {
		if (!scrollUpdateWasRequested) {
			if (scrollOffsetToBottom > 0) {
				setIsPaused(true);
			} else {
				setIsPaused(false);
			}
		}
	};

	const ControlButton = () => {
		return (
			<Button
				variant={isPaused ? "plain" : "link"}
				onClick={() => {
					setIsPaused(!isPaused);
				}}>
				{isPaused ? <PlayIcon /> : <PauseIcon />}
				{isPaused ? ` Resume Log` : ` Pause Log`}
			</Button>
		);
	};
    
	const leftAlignedToolbarGroup = (
		<React.Fragment>
			<ToolbarToggleGroup toggleIcon={<EllipsisVIcon />} breakpoint="md">
				<ToolbarItem variant="search-filter"></ToolbarItem>
				<ToolbarItem variant="search-filter">
					<LogViewerSearch
						onFocus={(e) => setIsPaused(true)}
						placeholder="Search"
					/>
				</ToolbarItem>
			</ToolbarToggleGroup>
			<ToolbarItem>
				<ControlButton />
			</ToolbarItem>
		</React.Fragment>
	);

	const rightAlignedToolbarGroup = (
		<React.Fragment>
			<ToolbarGroup variant="icon-button-group">
				<ToolbarItem>
					<Tooltip position="top" content={<div>Expand</div>}>
						<Button
							onClick={onExpandClick}
							variant="plain"
							aria-label="View log viewer in full screen">
							<ExpandIcon />
						</Button>
					</Tooltip>
				</ToolbarItem>
			</ToolbarGroup>
		</React.Fragment>
	);

	const FooterButton = () => {
		const handleClick = (e) => {
			setIsPaused(false);
		};
		return (
			<Button onClick={handleClick} isBlock>
				<OutlinedPlayCircleIcon />
				resume{" "}
				{linesBehind === 0 ? null : `and show ${linesBehind} lines`}
			</Button>
		);
	};

	return (
		<LogViewer
			data={renderData}
			id="complex-toolbar-demo"
			innerRef={logViewerRef}
			height={isFullScreen ? "100%" : "75vh"}
			toolbar={
				<Toolbar
					style={{
						backgroundColor: theme.white,
						padding: "8px 8px 0px 8px",
						margin: "0",
					}}>
					<ToolbarContent>
						<ToolbarGroup
							alignment={{ default: "alignLeft" }}
							style={{ marginRight: "auto" }}>
							{leftAlignedToolbarGroup}
						</ToolbarGroup>
						<ToolbarGroup alignment={{ default: "alignRight" }}>
							{rightAlignedToolbarGroup}
						</ToolbarGroup>
					</ToolbarContent>
				</Toolbar>
			}
			overScanCount={10}
			footer={isPaused && <FooterButton />}
			onScroll={onScroll}
			theme={theme.name}
			style={{ backgroundColor: theme.white }}
		/>
	);
};

export default ComplexToolbarLogViewer;
