// globalStyles.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body{
  height: 89vh;
  background-color: ${(props) => props.theme.background};
  font-family: 'Overpass', sans-serif;
  color: ${(props) => props.theme.fontcolor};
}

.rdt_Pagination {
  background-color: ${(props) => props.theme.white} !important;
  color: ${(props) => props.theme.fontcolor} !important;

  button:disabled {
    fill: ${(props) => props.theme.expandedTableBackgroundColor};
  }
  button:not(:disabled){
    fill: ${(props) => props.theme.fontcolor};
  }
}

.rdt_Table {
  background-color: ${(props) => props.theme.white};
}

.content {
  transition: margin-left 0.3s;
  margin-right: 50px;
}

.content.expanded {
  margin-left: 300px;
}

.content.collapsed {
  margin-left: 130px;
}

.page-title {
		display: inline;
		padding: 10px 20px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		background-color: ${(props) => props.theme.white};
	}

  .page-title-params{
    display: inline;
    margin-left: 10px;
		padding: 10px 20px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		background-color: ${(props) => props.theme.white};
  }
  .tippy-box {
  max-width: 900px !important;
  overflow-wrap: break-word;
}

.ps-sidebar-backdrop{
  width: 200vw;
}

.map-popup {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	user-select: none;
	pointer-events: none;
	position: absolute;
	width: max-content;
	background-color: white;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	padding: 15px;
	border-radius: 10px;
	border: 1px solid #cccccc;
  color: #000;
}

@media (max-width: 1200px) {
    .content{
      margin-right: 25px;
    }
    .content.expanded {
        margin-left: 235px;
    }

    .content.collapsed {
        margin-left: 105px;
    }
}
@media (max-width: 900px) {
    .content{
      margin-right: 10px;
    }
    .content.expanded {
        margin-left: 190px;
    }

    .content.collapsed {
        margin-left: 90px;
    }
}

@media (max-width: 768px) {
    .content {
        margin-top: 130px;
        margin-right: 25px;
    }

    .content.expanded {
        margin-left: 25px;
    }

    .content.collapsed {
        margin-left: 25px;
    }
}

@media (max-width: 425px) {
    .content {
        margin-top: 75px;
        margin-right: 10px;
    }

    .content.expanded {
        margin-left: 10px;
    }

    .content.collapsed {
        margin-left: 10px;
    }
}

@media (max-width: 375px) {
    .content {
        margin-right: 5px;
    }

    .content.expanded {
        margin-left: 5px;
    }

    .content.collapsed {
        margin-left: 5px;
    }
}

`;

export default GlobalStyle;
