import styled from "styled-components";

export const UsersLayout = styled.div`
	margin: 100px 0 50px 0;

	.table-container {
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
	}

	.add-user {
		width: 222px;
		margin-bottom: 20px;
	}

	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 15;

		.modal-content {
			background-color: ${(props) => props.theme.white};
			padding: 20px;
			border-radius: 8px;
			min-width: 400px;
			max-width: 600px;
			display: flex;
			flex-direction: column;
			gap: 5px;

			.modal-actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 10px;
			}

			.modal-actions {
				.cancel {
					margin-right: 10px;
				}
				.add {
					margin-left: 10px;
				}
			}

			.err-msg {
				color: ${(props) => props.theme.danger};
				margin-top: 2px;
				min-height: 17px;
				font-size: 14px;
			}

			h2 {
				margin-bottom: 5px;
			}

			input {
				width: 100%;
				margin-top: 5px;
				padding: 15px 10px;
				border: none;
				outline: none;
				border-radius: 5px;
				background-color: ${(props) => props.theme.secondary};
				color: ${(props) => props.theme.fontcolor};
			}
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px
					${(props) => props.theme.secondary} inset !important;
			}
		}
	}
	.rc-switch-checked .rc-switch-inner {
		color: #ffffff; /* Kolor tekstu w środku przełącznika */
	}

	.rc-switch-checked .rc-switch-handle {
		background-color: #ffffff; /* Kolor samego przełącznika */
	}

	.rc-switch-checked {
		background-color: ${(props) => props.theme.success};
	}
	@media (max-width: 1879px) {
		.modal-overlay {
			.modal-content {
				gap: 0;

				label {
					font-size: 14px;
				}

				.modal-actions {
					margin-top: 5px;

					Button {
						font-size: 12px;
					}
				}

				.modal-actions {
					.cancel {
						margin-right: 10px;
					}
					.add {
						margin-left: 10px;
					}
				}

				.err-msg {
					color: ${(props) => props.theme.danger};
					margin-top: 2px;
					min-height: 14px;
					font-size: 11px;
				}

				h2 {
					font-size: 18px;
				}

				input {
					padding: 10px 10px;
					font-size: 12px;
				}
				input:-webkit-autofill,
				input:-webkit-autofill:hover,
				input:-webkit-autofill:focus,
				input:-webkit-autofill:active {
					-webkit-box-shadow: 0 0 0 30px
						${(props) => props.theme.secondary} inset !important;
				}
			}
		}
		.react-select__control {
			font-size: 12px !important; /* Zmniejszenie rozmiaru czcionki dla kontrolki na małych ekranach */
			min-height: 34px !important;
		}

		.react-select__value-container {
			padding: 4px 8px !important; /* Dostosuj padding wewnątrz kontrolki */
		}

		.react-select__indicators {
			height: 30px !important; /* Wysokość dla kontenera ikon, np. ikony strzałki w dół */
		}

		.react-select__indicator-separator {
			display: none !important; /* Jeśli nie chcesz separatora */
		}

		.react-select__menu {
			font-size: 12px !important;
		}

		.react-select__option {
			font-size: 12px !important; /* Zmniejszenie rozmiaru czcionki dla opcji na małych ekranach */
			height: 34px !important;
		}
	}
	@media (max-width: 320px) {
		.modal-overlay {
			.modal-content {
				min-width: 300px;
			}
		}
	}
`;
