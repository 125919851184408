import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useTheme } from "styled-components";
import { pumpRequest } from "../../../api/pumpRequest";
import { timer } from "rxjs";
import { switchMap, filter, take, timeout } from "rxjs/operators";
import { ReactComponent as RefreshIcon } from "./../../../svg/refresh.svg";

const ProbeLogsRegister = ({
	setScheme,
	isProbeLoading,
	setProbeLogsData,
	probeLogsData,
	probeId,
}) => {
	const theme = useTheme();
	const [rawProbeLogsRegister, setRawProbeLogsRegister] = useState();
	const [translatedData, setTranslatedData] = useState([]);
	const [isProbeLogsRegisterLoading, setIsProbeLogsRegisterLoading] =
		useState(true);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [shouldCheckPumpStatus, setShouldCheckPumpStatus] = useState(false);
	const [hasPumpStatusFetched, setHasPumpStatusFetched] = useState(false);
	const [eventId, setEventId] = useState("");
	const [data, setData] = useState(null);
	const [error, setError] = useState("");
	const dictionary = JSON.parse(localStorage.getItem("dictionary"));

	useEffect(() => {
		if (!shouldCheckPumpStatus) return;
		const subscription = timer(0, 5000)
			.pipe(
				switchMap(() => {
					return pumpRequest({
						action: "pump-observable",
						probe_id: probeId,
						event_id: eventId,
					});
				}),
				filter((response) => {
					if (
						!response.data._data_ ||
						Object.keys(response.data._data_).length === 0
					) {
						return false;
					}
					if (
						response.data._data_.event === "read_log_registers_done"
					) {
						if (
							response.data._data_.event_response === "noresponse"
						) {
							setError("Brak komunikacji.");
							setIsProbeLogsRegisterLoading(true);
						} else {
							setError("");
							const data = JSON.parse(
								response.data._data_.event_response
							);
							setRawProbeLogsRegister(data);
							setProbeLogsData(data);
							if (data?.io_state?.includes("LS_NOM")) {
								setScheme("full");
							}

							setIsProbeLogsRegisterLoading(false);
						}
					}
					const isDone = response.data._data_.event.includes("done");
					return isDone;
				}),
				take(1)
			)
			.subscribe({
				next: () => {
					setData("Pump is done.");
					setShouldCheckPumpStatus(false);
					if (!hasPumpStatusFetched) {
						fetchPumpStatus();
					}
					setIsRefreshing(false);
				},
				error: (requestError) => {
					console.log(requestError);
					if (requestError.name === "TimeoutError") {
						setError("Brak komunikacji.");
						setIsProbeLogsRegisterLoading(false);
						setShouldCheckPumpStatus(false);
					} else {
						setError(requestError);
					}
				},
			});

		return () => {
			subscription.unsubscribe();
		};
	}, [shouldCheckPumpStatus, setShouldCheckPumpStatus]);
	useEffect(() => {
		if (probeLogsData.length === 0) {
			refreshProbeLogsRegister();
		} else {
			setRawProbeLogsRegister(probeLogsData);
		}
	}, []);

	useEffect(() => {
		const cleanData = (data) => {
			let cleanedData = { ...data };

			const isOnlySemicolons = (value) => !value.replace(/;/g, "").length;

			if (cleanedData.error1 && isOnlySemicolons(cleanedData.error1)) {
				delete cleanedData.error1;
				delete cleanedData.auto_restart_time;
			}

			if (cleanedData.error2 && isOnlySemicolons(cleanedData.error2)) {
				delete cleanedData.error2;
			}

			return cleanedData;
		};

		const processErrors = (value, errorType) => {
			return value
				.split(";")
				.filter((error) => error.trim() !== "")
				.map((error) => {
					if (dictionary.event_log[errorType][error]) {
						return dictionary.event_log[errorType][error];
					}
					return error;
				});
		};

		const cleanedRawData = cleanData(rawProbeLogsRegister);
		const translatedObject = {};

		Object.keys(cleanedRawData).forEach((key) => {
			if (key === "error1" || key === "error2") {
				const processedErrors = processErrors(cleanedRawData[key], key);
				if (processedErrors.length > 0) {
					translatedObject[key] = processedErrors;
				}
			} else if (
				dictionary.pump_register &&
				dictionary.pump_register[key]
			) {
				translatedObject[dictionary.pump_register[key]] =
					cleanedRawData[key];
			}
		});

		setTranslatedData(translatedObject);
	}, [rawProbeLogsRegister]);

	const refreshProbeLogsRegister = async () => {
		const getPumpLogs = {
			action: "pump-get-logs",
			probe_id: probeId,
		};
		setIsRefreshing(true);
		try {
			const response = await pumpRequest(getPumpLogs);
			setError("");
			setEventId(response.data.event_id);
			setShouldCheckPumpStatus(true);
		} catch (error) {
			setError("Brak komunikacji.");
			setIsRefreshing(false);
			console.error(error);
			setIsProbeLogsRegisterLoading(false);
		}
	};

	const fetchPumpStatus = async () => {
		const getPumpStatus = {
			action: "pump-status-noconfirmed",
			probe_id: probeId,
		};
		try {
			await pumpRequest(getPumpStatus);
			setHasPumpStatusFetched(true);
		} catch (error) {
			console.error(error);
		}
	};

	const sortedKeys = Object.keys(translatedData).sort();

	return !rawProbeLogsRegister && error.length === 0 ? (
		<div className="pump-status">
			<TailSpin
				height="100"
				width="85"
				color={theme.primary}
				radius="1"
				wrapperStyle={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					height: "100%",
				}}
				visible={true}
			/>
		</div>
	) : !rawProbeLogsRegister && error.length > 0 ? (
		<div
			className="pump-status"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: "48px",
				cursor: "pointer",
			}}
			>
			<div className="refresh-button">
				<TailSpin
					height="25"
					width="25"
					color={theme.primary}
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{ padding: "5px" }}
					wrapperClass=""
					visible={isRefreshing}
				/>
				{!isRefreshing && (
					<div
						className="refresh-button"
						onClick={() => refreshProbeLogsRegister()}>
						<RefreshIcon
							width={20}
							height={20}
							fill={theme.primary}
							style={{ cursor: "pointer" }}
						/>
					</div>
				)}
			</div>
			<h3>{error}</h3>
		</div>
	) : (
		<div className="pump-status">
			<div className="refresh-button">
				<TailSpin
					height="25"
					width="25"
					color={theme.primary}
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperClass=""
					visible={isRefreshing}
				/>
			</div>
			{!isRefreshing && (
				<div
					className="refresh-button"
					onClick={() => refreshProbeLogsRegister()}>
					<RefreshIcon
						width={20}
						height={20}
						fill={theme.primary}
						style={{ cursor: "pointer" }}
					/>
				</div>
			)}
			{sortedKeys.map((key, index) => (
				<div className="pump-status-register" key={index}>
					<span
						style={{
							fontWeight: "700",
							width:
								[
									"error1",
									"error2",
									"Czas do ponownej próby załączenia",
								].includes(key) && "auto",
						}}>
						{key}:{" "}
					</span>
					{Array.isArray(translatedData[key])
						? translatedData[key].join(", ")
						: translatedData[key]}
				</div>
			))}
		</div>
	);
};

export default ProbeLogsRegister;
