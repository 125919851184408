import styled from "styled-components";

export const DetailsLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 75px 0 50px 0;
    gap: 7px;

    .single-probe {
        flex: 1 1 346px;
        display: flex;
        flex-direction: column;
        gap: 7px;
		max-width: 785px;
		margin-bottom: 15px;


		.name-card-title {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			width: 100%;
			height: 5vh;
			min-height: 47px;
			background-color: ${(props) => props.theme.white};
			border-radius: 5px;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
			cursor: pointer;
		}

		.metrics-card {
			display: flex;
			flex-direction: column;
			gap: 7px;

			.metrics-card-status {
				position: relative;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 7px;
				box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
				border-radius: 5px;

				.metrics-card-title {
					position: absolute;
					top: 0;
					left: 0;
					padding: 15px;
					font-weight: 700;
					text-align: start;
					color: white;
				}
				div {
					height: 100%;
					width: 100%;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: ${(props) => props.theme.white};
					border-radius: 5px;
				}
			}

			.metrics-card-month,
			.metrics-card-lastmonth,
			.metrics-card-24 {
				background-color: ${(props) => props.theme.white};
				width: 100%;
				padding: 15px;
				border-radius: 5px;
				display: flex;
				justify-content: space-between;
				box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

				.metrics-card-title {
					font-weight: 700;
					text-align: start;
				}
				.metrics-card-stats {
					display: flex;
					align-items: end;
					gap: 15px;
				}

				div {
					text-align: end;
				}
			}
		}

		.status {
			padding: 15px 0;
			text-align: center;
			background-color: ${(props) => props.theme.white};
			border-radius: 5px;
		}

		.pump-status {
			position: relative;
			overflow: auto;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
			height: 100%;
			background-color: ${(props) => props.theme.white};
			border-radius: 5px;
			padding: 20px 10px;
			white-space: normal;
			word-wrap: break-word;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
			font-size: 15px;

			.pump-status-register {
				margin-bottom: 5px;
			}

			.refresh-button {
				position: absolute;
				top: 5px;
				right: 5px;
			}
			span {
				font-weight: 700;
				display: inline-block;
				width: 60%;
				text-align: right;
				margin-right: 10px;
			}
		}
	}
`;
