import React from "react";
import { useTheme } from "styled-components";

const PumpStatus = ({ probe }) => {
	const theme = useTheme();
	let message;
	let backgroundColor;
	if (probe) {
		if (probe.error > 0) {
			message = "Błąd pompowni";
			backgroundColor = theme.danger;
		} else if (probe.probe_running === 0) {
			message = "Zablokowana";
			backgroundColor = theme.blocked;
		} else if (probe.probe_running === 1) {
			message = "Gotowość";
			backgroundColor = theme.standby;
		} else if (probe.probe_running === 2) {
			message = "Pompowanie";
			backgroundColor = theme.success;
		} else if (probe.probe_running === 3) {
			message = "Brak komunikacji";
			backgroundColor = theme.warning;
		}
	} else {
		message = "Brak statusu";
		backgroundColor = theme.warning;
	}
	return (
		<div className="metrics-card-status">
			<p className="metrics-card-title">Status</p>
			<div style={{ backgroundColor, color: "white" }}>{message}</div>
		</div>
	);
};

export default PumpStatus;
