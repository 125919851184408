import { useState, useRef, useEffect } from "react";
import useAccessControl from "../../hooks/useAccessControl";
import { StyledDataTable } from "../../components/Table/Table.styles";
import { SchedulesLayout } from "./Schedules.styles";
import { Button } from "../../components/Button/Button";
import Select from "react-select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { sendRequest } from "../../api/sendRequest";
import { TailSpin } from "react-loader-spinner";
import { useTheme } from "styled-components";
import { ReactComponent as Icoedytuj } from "./../../svg/ico-edytuj.svg";
import { ReactComponent as Icozamknij } from "./../../svg/ico-zamknij.svg";
import { useNavigate } from "react-router-dom";
import { useStyledSelect } from "../../components/Select/StyledSelect";
import Loading from "../../components/Loading/Loading";

const Schedules = () => {
	const customStyles = useStyledSelect();
	const navigate = useNavigate();
	const theme = useTheme();
	const modalContentRef = useRef(null);
	const isAccessVerified = useAccessControl();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [deletingSchedule, setDeletingSchedule] = useState([]);
	const [dateRange, setDateRange] = useState([new Date(), new Date()]);
	const [startDate, setStartDate] = useState("");
	const [dateError, setDateError] = useState("");
	const [timeError, setTimeError] = useState("");
	const [stopDate, setStopDate] = useState("");
	const [startTime, setStartTime] = useState("12:00");
	const [stopTime, setStopTime] = useState("12:00");
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	const [schedules, setSchedules] = useState([]);
	const [scheduleId, setScheduleId] = useState("");
	const [scheduleName, setScheduleName] = useState("");
	const [scheduleNameError, setScheduleNameError] = useState("");
	const [selectedProbes, setSelectedProbes] = useState([]);
	const [selectedNodes, setSelectedNodes] = useState([]);
	const [probesList, setProbesList] = useState([]);
	const [nodesList, setNodesList] = useState([]);

	const fetchData = async () => {
		try {
			const getProbesAll = {
				action: "get-probes-all",
			};
			const getNodes = {
				action: "get-nodes",
			};
			const getSchedules = {
				action: "get-schedules",
			};
			const [probesAllResponse, nodesResposne, schedulesRequest] =
				await Promise.all([
					sendRequest(getProbesAll),
					sendRequest(getNodes),
					sendRequest(getSchedules),
				]);
			setProbesList(probesAllResponse.data._data_);
			setNodesList(nodesResposne.data._data_);
			setSchedules(schedulesRequest.data._data_);
			setIsLoading(false);
			setIsLoadingRequest(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const [start, end] = dateRange.map(
			(dateString) => new Date(dateString)
		);
		setStartDate(formatDate(start));
		setStopDate(formatDate(end));
	}, [dateRange]);

	useEffect(() => {
		fetchData();
	}, []);

	if (!isAccessVerified) {
		return null;
	}

	const columns = [
		{
			name: "Nazwa",
			selector: (row) => row.name,
			grow: 5,
			sortable: true,
			sortField: "0",
		},
		{
			name: "Data",
			selector: (row) => row.start + " do " + row.stop,
			grow: 8,
			sortable: true,
			sortField: "0",
		},
		{
			name: "Start",
			selector: (row) => row.time_start,
			grow: 3,
			sortable: true,
			sortField: "1",
		},
		{
			name: "Stop",
			selector: (row) => row.time_stop,
			grow: 3,
			sortable: true,
			sortField: "2",
		},
		{
			name: "Typ",
			selector: (row) => (row.type === "a" ? "Auto" : "Ręczny"),
			grow: 2,
			sortable: true,
			sortField: "3",
		},
		{
			name: "Pompownie",
			selector: (row) => row.schedule_probes,
			grow: 2,
			sortable: true,
			sortField: "3",
		},
		{
			name: "Akcje",
			cell: (row) => (
				<div style={{ display: "flex", gap: "15px" }}>
					<Icoedytuj
						style={{ cursor: "pointer" }}
						width={20}
						onClick={() => {
							handleEditClick(row);
						}}
					/>
					<Icozamknij
						style={{ cursor: "pointer" }}
						width={20}
						onClick={() => {
							setDeletingSchedule(row);
							setIsModalDeleteOpen(true);
						}}
					/>
				</div>
			),
			style: { justifyContent: "center" },
			grow: 1,
		},
	];

	function formatDate(date) {
		return `${date.getFullYear()}-${(date.getMonth() + 1)
			.toString()
			.padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
	}

	const validateSchedule = () => {
		let isValid = true;

		if (!scheduleName) {
			setScheduleNameError("Nazwa jest wymagana.");
			isValid = false;
		} else {
			setScheduleNameError("");
		}
		if (startDate === "1970-01-01" || stopDate === "1970-01-01") {
			setDateError("Pełen zakres dat jest wymagany.");
			isValid = false;
		} else {
			if (startDate > stopDate) {
				setDateError("Data start jest późniejsza niż data stop.");
				isValid = false;
			} else {
				if (startDate === stopDate) {
					setDateError("Daty start i stop muszą być różne.");
					isValid = false;
				} else {
					setDateError("");
				}
			}
		}
		if (!startTime || !stopTime) {
			setTimeError("Pełen zakres godzin pracy jest wymagany.");
			isValid = false;
		} else {
			if (startTime === stopTime) {
				setTimeError("Godziny start i stop muszą być różne.");
				isValid = false;
			} else {
				setTimeError("");
			}
		}

		return isValid;
	};

	const clearForm = () => {
		setScheduleId("");
		setScheduleName("");
		setSelectedProbes([]);
		setSelectedNodes([]);
		setDateRange([new Date(), new Date()]);
		setStartTime("12:00");
		setStopTime("12:00");
		setScheduleNameError("");
		setDateError("");
		setTimeError("");
	};
	const closeModal = () => {
		setIsModalOpen(false);
		setIsEditMode(false);
		setIsModalDeleteOpen(false);
		clearForm();
	};

	const handleEditClick = async (row) => {
		const getScheduleProbes = {
			action: "get-schedule-probes",
			schedule_id: row.schedule_id,
		};
		const getScheduleNodes = {
			action: "get-schedule-nodes",
			schedule_id: row.schedule_id,
		};
		try {
			const scheduleProbesResponse = await sendRequest(getScheduleProbes);
			const scheduleNodesResponse = await sendRequest(getScheduleNodes);
			const probe_ids = scheduleProbesResponse.data._data_.map(
				(probe) => probe.probe_id
			);
			const filteredProbes = probesList.filter((probe) =>
				probe_ids.includes(probe.probe_id)
			);
			setSelectedProbes(
				filteredProbes.map((probe) => ({
					value: probe.probe_id,
					label:
						probe.location !== "undefined"
							? `${probe.location} (${probe.probe_name})`
							: `${probe.probe_description} (${probe.probe_name})`,
				}))
			);
			const node_ids = scheduleNodesResponse.data._data_.map(
				(node) => node.node_id
			);
			const filteredNodes = nodesList.filter((node) =>
				node_ids.includes(node.node_id)
			);
			setSelectedNodes(
				filteredNodes.map((node) => ({
					value: node.node_id,
					label: node.node_name,
				}))
			);
			setScheduleId(row.schedule_id);
			setScheduleName(row.name);
			setStartDate(row.start);
			setStopDate(row.stop);
			setDateRange([new Date(row.start), new Date(row.stop)]);
			setStartTime(row.time_start);
			setStopTime(row.time_stop);
			setIsModalOpen(true);
			setIsEditMode(true);
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddSchedule = async () => {
		const isValid = validateSchedule();
		if (isValid) {
			setIsLoadingRequest(true);
			const saveSchedule = {
				action: "save-schedule",
				_data_: {
					name: scheduleName,
					start: startDate,
					stop: stopDate,
					time_start: startTime,
					time_stop: stopTime,
					nodes: selectedNodes.map((node) => node.value),
					probes: selectedProbes.map((probe) => probe.value),
				},
			};
			try {
				await sendRequest(saveSchedule);
				closeModal();
			} catch (error) {
				console.error(error);
			}
			await fetchData();
		}
	};
	const handleEditSchedule = async () => {
		const isValid = validateSchedule();
		if (isValid) {
			setIsLoadingRequest(true);
			const saveSchedule = {
				action: "save-schedule",
				_data_: {
					schedule_id: scheduleId,
					name: scheduleName,
					start: startDate,
					stop: stopDate,
					time_start: startTime,
					time_stop: stopTime,
					nodes: selectedNodes.map((node) => node.value),
					probes: selectedProbes.map((probe) => probe.value),
				},
			};
			try {
				await sendRequest(saveSchedule);
				closeModal();
			} catch (error) {
				console.error(error);
			}
			await fetchData();
		}
	};

	const handleDeleteSchedule = async () => {
		setIsLoadingRequest(true);
		const deleteSchedule = {
			action: "delete-schedule",
			schedule_id: deletingSchedule.schedule_id,
			confirmed: true,
		};
		try {
			await sendRequest(deleteSchedule);
			closeModal();
		} catch (error) {
			console.error(error);
		}
		await fetchData();
	};

	const handleDateChange = (ranges) => {
		setDateRange(ranges);

		const [start, end] = ranges.map((dateString) => new Date(dateString));

		setStartDate(formatDate(start));
		setStopDate(formatDate(end));
	};

	const customTableStyles = {
		rows: {
			style: {
				cursor: "pointer",
			},
		},
	};

	if (isLoading) {
		return (
			<TailSpin
				height="80"
				width="80"
				color={theme.primary}
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}
				visible={true}
			/>
		);
	}

	return (
		<SchedulesLayout>
			<h2 className="page-title">Harmonogramy</h2>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: "Wiersze na stronę:",
					}}
					data={schedules}
					columns={columns}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					customStyles={customTableStyles}
					onRowClicked={(row) =>
						navigate(`/pipelinemap/schedule/${row.name}`)
					}
				/>
				<Button
					className="add-button"
					onClick={() => setIsModalOpen(true)}
					style={{
						fontSize: "16px",
					}}>
					Dodaj harmonogram
				</Button>
			</div>
			{isModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{isEditMode ? (
							<h2>Edytuj Harmonogram</h2>
						) : (
							<h2>Dodaj Harmonogram</h2>
						)}

						<div>
							<label>Nazwa:</label>
							<input
								placeholder="Nazwa harmonogramu"
								className="input-form"
								value={scheduleName}
								onChange={(e) =>
									setScheduleName(e.target.value)
								}
							/>
							<p className="err-msg">{scheduleNameError}</p>
						</div>
						<div>
							<label>Data:</label>
							<div>
								<DateRangePicker
									disableClock={true}
									onChange={handleDateChange}
									value={dateRange}
									minDate={new Date()}
									clearIcon={null}
								/>
							</div>
							<p className="err-msg">{dateError}</p>
						</div>
						<div>
							<label>Start:</label>
							<div>
								<TimePicker
									disableClock={true}
									onChange={setStartTime}
									value={startTime}
									clearIcon={null}
								/>
							</div>
							<p className="err-msg"></p>
						</div>
						<div>
							<label>Stop:</label>
							<div>
								<TimePicker
									disableClock={true}
									onChange={setStopTime}
									value={stopTime}
									clearIcon={null}
								/>
							</div>
							<p className="err-msg">{timeError}</p>
						</div>
						<div>
							<label>Pompownie:</label>
							<Select
								placeholder="Wybierz pompownie..."
								isMulti
								options={probesList.map((probe) => ({
									value: probe.probe_id,
									label: `${probe.description} (${probe.probe_name})`,
								}))}
								value={selectedProbes}
								onChange={setSelectedProbes}
								styles={customStyles}
							/>
							<p className="err-msg"></p>
						</div>
						<div>
							<label>Węzły:</label>
							<Select
								placeholder="Wybierz węzły..."
								isMulti
								options={nodesList.map((node) => ({
									value: node.node_id,
									label: node.node_name,
								}))}
								value={selectedNodes}
								onChange={setSelectedNodes}
								styles={customStyles}
							/>
							<p className="err-msg"></p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{isEditMode ? (
								<Button
									className="add"
									onClick={handleEditSchedule}>
									Zapisz
								</Button>
							) : (
								<Button
									className="add"
									onClick={handleAddSchedule}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isModalDeleteOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć harmonogram</h2>
						<h2>{deletingSchedule.name} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => handleDeleteSchedule()}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
			{isLoadingRequest && (
				<div className="modal-overlay">
					<Loading />
				</div>
			)}
		</SchedulesLayout>
	);
};

export default Schedules;
