import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import React, { useState, useEffect } from "react";
import { LogsLayout } from "./Logs.style";
import useAccessControl from "../../hooks/useAccessControl";
import { StyledDataTable } from "../../components/Table/Table.styles";
import { sendRequest } from "../../api/sendRequest";
import LoadingComponent from "../../components/Table/LoadingComponent";
import ComplexToolbarLogViewer from "../../components/ComplexToolbarLogViewer";

const Logs = () => {
	const monthAgo = new Date(new Date().setDate(new Date().getDate() - 30));
	const today = new Date();
	const [datePickerValue, setDatePickerValue] = useState([monthAgo, today]);
	const isAccessVerified = useAccessControl();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState("3");
	const [sortDirection, setSortDirection] = useState("asc");

	const fetchLogs = async (
		page,
		size = perPage,
		column = sortColumn,
		direction = sortDirection,
		search,
		newDates = datePickerValue
	) => {
		const formatDate = (date) => {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, "0");
			const day = String(date.getDate()).padStart(2, "0");

			return `${year}/${month}/${day}`;
		};

		const startString = formatDate(newDates[0]) + " 00:00";
		const endString = formatDate(newDates[1]) + " 23:59";

		const formattedDate = startString + "-" + endString;

		const getLogsRequest = {
			action: "get-logs",
			_data_: {
				page: page,
				perPage: size,
				search: search,
				date: formattedDate,
				sortColumn: column,
				sortDirection: direction,
			},
		};

		sendRequest(getLogsRequest)
			.then((response) => {
				setTotalRows(response.data._data_.results[0]?.total_count);
				setData(response.data._data_.results);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchLogs(1);
	}, []);

	const handleDateChange = (value) => {
		setDatePickerValue(value);
		fetchLogs(
			currentPage,
			perPage,
			sortColumn,
			sortDirection,
			searchValue,
			value
		);
	};

	const handleSearchChange = (e) => {
		setSearchValue(e.target.value);
		setCurrentPage(1)
		fetchLogs(
			currentPage,
			perPage,
			sortColumn,
			sortDirection,
			e.target.value
		);
	};

	const handlePageChange = (page) => {
		fetchLogs(page, perPage, sortColumn, sortDirection);
		setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchLogs(page, newPerPage, sortColumn, sortDirection);
		setPerPage(newPerPage);
	};

	const handleSort = async (column, sortDirection) => {
		setSortDirection(sortDirection);
		setSortColumn(column.sortField);
		fetchLogs(
			currentPage,
			perPage,
			column.sortField,
			sortDirection,
			searchValue
		);
	};

	const columns = [
		{
			name: "Type",
			selector: (row) => row.type,
			grow: 1,
			sortable: true,
			sortField: "0",
		},
		{
			name: "Process ID",
			selector: (row) => row.process_id,
			grow: 1,
			sortable: true,
			sortField: "1",
		},
		{
			name: "Log",
			selector: (row) => row.message,
			grow: 3,
			sortable: true,
			sortField: "2",
		},
		{
			name: "Timestamp",
			selector: (row) => row.ctime,
			grow: 1,
			sortable: true,
			sortField: "3",
		},
	];

	if (!isAccessVerified) {
		return null;
	}
	return (
		<LogsLayout>
			<h2 className="page-title">Logi</h2>
			<ComplexToolbarLogViewer />
		</LogsLayout>
	);
};

export default Logs;
