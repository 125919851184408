import { useEffect, useState } from "react";
import { sendRequest } from "../../api/sendRequest";
import { StyledDataTable } from "../../components/Table/Table.styles";
import { SettingsLayout } from "./Settings.style";
import { Button } from "../../components/Button/Button";
import useAccessControl from "../../hooks/useAccessControl";
import LoadingComponent from "../../components/Table/LoadingComponent";

const Settings = () => {
	const isAccessVerified = useAccessControl();
	const [userData, setUserData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const getConfigsRequest = {
			action: "get-config",
		};

		sendRequest(getConfigsRequest)
			.then((configsResponse) => {
				setUserData(configsResponse.data._data_.results);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	}, []);

	const handleChange = (event, parameter) => {
		setUserData((prevState) =>
			prevState.map((data) =>
				data.parameter === parameter
					? { ...data, value: event.target.value }
					: data
			)
		);
	};

	const handleSave = () => {
		const saveConfigRequest = {
			action: "save-config",
			_data_: userData.reduce(
				(acc, data) => ({ ...acc, [data.parameter]: data.value }),
				{}
			),
		};

		sendRequest(saveConfigRequest).catch((error) => {
			console.error(error);
		});
	};

	const columns = [
		{
			name: "Parametr",
			selector: (row) => row.parameter,
			grow: 1,
		},
		{
			name: "Wartość",
			cell: (row) => (
				<div style={{ display: "flex", gap: "15px" }}>
					<input
						type="text"
						value={row.value}
						onChange={(event) => handleChange(event, row.parameter)}
					/>
				</div>
			),
			grow: 1,
		},
		{
			name: "Data modyfikacji",
			selector: (row) => row.mtime,
			grow: 2,
		},
	];

	if (!isAccessVerified) {
		return null;
	}
	return (
		<SettingsLayout>
			<h2 className="page-title">Ustawienia</h2>
			<div className="table-container">
				<StyledDataTable
					columns={columns}
					data={userData}
					progressPending={isLoading}
					noDataComponent="Brak danych spełniających kryteria"
					progressComponent={<LoadingComponent />}
				/>
				<Button
					className="action-button"
					onClick={handleSave}
					style={{
						fontSize: "16px",
					}}>
					Zapisz
				</Button>
			</div>
		</SettingsLayout>
	);
};

export default Settings;
