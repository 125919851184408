import axios from "axios";

export async function pumpRequest(request) {

	const apiUrl = process.env.REACT_APP_PUMP_API_URL;
	let token = localStorage.getItem("token");

	if (token) {
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	}

	return axios.post(apiUrl, request, {
		headers: {
			"Content-Type": "application/json",
		},
	});
}
