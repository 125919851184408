import styled from "styled-components";

export const HomeLayout = styled.div`
	margin: 100px 0 30px 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	.table-container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: start;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
		min-height: 685px;

		input {
			margin-right: auto;
			margin-bottom: 10px;
			padding: 13px 10px;
			border: none;
			outline: none;
			border-radius: 5px;
			color: ${(props) => props.theme.fontcolor};
			background-color: ${(props) => props.theme.secondary};
		}
	}

	.map-container {
		position: relative;
		width: 100%;
		min-height: 250px;
		height: 55vh;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
	}
	.select-layers-button {
		position: absolute;
		top: 45px;
		right: 30px;
		padding: 1px 4px;
		z-index: 2;
		background-color: white;
		border: 1px solid transparent;
		border-radius: 2px;

		:hover {
			border: 1px solid black;
		}
	}

	.select-layers {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: start;
		padding: 10px 15px;
		background-color: white;
		top: 70px;
		right: 30px;
		border-radius: 5px;
		z-index: 1;
		color: #3f3f3f;

		input{
			margin-right: 5px;
		}
	}

	.delete-filters{
		position: absolute;
		top: 30px;
		right: 130px;
		background-color: ${(props) => props.theme.white};
		padding: 5px 10px;
		border-radius: 5px;
		cursor: pointer;
		z-index: 1;
	}

	.chart-container {
		position: relative;
		padding: 15px;
		width: 100%;
		text-align: center;
		min-height: 170px;
		height: 30vh;
		margin-bottom: 15px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

		.chart-info {
			position: absolute;
			bottom: 98%;
			left: 0;
			padding-top: 5px;
			border-radius: 5px;
			background-color: ${(props) => props.theme.white};

			svg {
				line,
				path,
				circle {
					stroke: ${(props) => props.theme.fontcolor} !important;
				}
			}

			.number {
				position: absolute;
				padding-top: 5px;
				text-align: center;
				height: 30px;
				width: 30px;
				height: 30px;
				width: 30px;
				border-radius: 50%;
				color: ${(props) => props.theme.white};
			}
			.number-online {
				bottom: -5px;
				left: 24px;
				background-color: ${(props) => props.theme.standby};
			}
			.number-offline {
				bottom: -5px;
				right: 110px;
				background-color: ${(props) => props.theme.warning};
			}
			.number-off {
				padding-top: 4px;
				bottom: -5px;
				right: 56px;
				background-color: white;
				color: #282828;
				border: 1px solid #282828;
			}
			.number-block {
				bottom: -5px;
				left: 80px;
				background-color: ${(props) => props.theme.blocked};
				color: white;
			}
			.number-alarms {
				bottom: -5px;
				right: 1px;
				background-color: ${(props) => props.theme.danger};
			}
		}
	}
	.button {
		position: absolute;
		top: 0;
		right: 0;
		padding: 5px 15px;
		min-width: 120px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
		cursor: pointer;
		z-index: 2;
	}

	.map {
		position: relative;
		overflow: hidden;
		width: 100%;
		margin-bottom: 15px;
		text-align: center;
		min-height: 45vh;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
		border-top-right-radius: 0;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	}
	@media (max-width: 1879px) {
		.table-dashboard {
			.rdt_TableCell {
				font-size: 10px;
			}
			.rdt_TableCol {
				font-size: 14px;
			}
		}
	}

	@media (max-width: 425px) {
		margin: 50px 0 30px 0;

		.chart-container {
			display: none;
		}
		.map {
			min-height: 80vh;

			.map-container {
				height: 80vh;
			}
		}
	}
`;
