import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from "react";
import { fromLonLat } from "ol/proj";
import { Snap } from "ol/interaction.js";
import "ol/ol.css";
import {
	RMap,
	ROSM,
	RInteraction,
	RLayerVector,
	RFeature,
	RPopup,
} from "rlayers";
import { RIcon, RStyle, RStroke } from "rlayers/style";
import { LineString, Point } from "ol/geom";
import { sendRequest } from "../../api/sendRequest";
import { MapEditLayout } from "./MapEdit.styles";
import Select from "react-select";
import { Button } from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import { useTheme } from "styled-components";
import locationProbeIcon from "./../../svg/Artboard 62.svg";
import locationNodeIcon from "./../../svg/iconmonstr-triangle-1.svg";
import locationGatewayIcon from "./../../svg/square.svg";
import calculateIconScale from "../../hooks/calculateIconScale";
import useAccessControl from "../../hooks/useAccessControl";
import { useStyledSelect } from "../../components/Select/StyledSelect";

const MapEdit = () => {
	const customStyles = useStyledSelect();
	const isAccessVerified = useAccessControl();
	const [mapKey, setMapKey] = useState(Math.random());
	const [isDrawingEnabled, setIsDrawingEnabled] = useState(false);
	const [isDeletingEnabled, setIsDeletingEnabled] = useState(false);
	const [drawings, setDrawings] = useState([]);
	const [gateways, setGateways] = useState([]);
	const [gatewayNetwork, setGatewayNetwork] = useState([]);
	const [drawType, setDrawType] = useState("Polygon");
	const [mapProbes, setMapProbes] = useState([]);
	const [nodeId, setNodeId] = useState("");
	const [nodeName, setNodeName] = useState("");
	const [nodeError, setNodeError] = useState("");
	const [nodeThroughput, setNodeThroughput] = useState("");
	const [nodeThroughputError, setNodeThroughputError] = useState("");
	const [nodeDiameter, setNodeDiameter] = useState("");
	const [nodeDiameterError, setNodeDiameterError] = useState("");
	const [isNodeAddingModalOpen, setIsNodeAddingModalOpen] = useState(false);
	const [isNodeEditModalOpen, setIsNodeEditModalOpen] = useState(false);
	const [isNodeDeleteModalOpen, setIsNodeDeleteModalOpen] = useState(false);
	const [deletingNode, setDeletingNode] = useState(null);
	const [userRegions, setUserRegions] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState([]);
	const [selectedRegionError, setSelectedRegionError] = useState("");
	const [pumpsList, setPumpsList] = useState([]);
	const [isMultiLine, setIsMultiLine] = useState(false);
	const [availablePumpsList, setAvailablePumpsList] = useState([]);
	const [availableNodesList, setAvailableNodesList] = useState([]);
	const [nodesList, setNodesList] = useState([]);
	const [selectedPumps, setSelectedPumps] = useState(null);
	const [selectedNodes, setSelectedNodes] = useState(null);
	const [selectedPumpsError, setSelectedPumpsError] = useState("");
	const [selectedNodesError, setSelectedNodesError] = useState("");
	const [currentFeature, setCurrentFeature] = useState(null);
	const [arePumpsVisible, setPumpsVisible] = useState(true);
	const [arePipesVisible, setPipesVisible] = useState(true);
	const [areNodesVisible, setNodesVisible] = useState(true);
	const [areGatewaysVisible, setGatewaysVisible] = useState(false);
	const [isSelectLayersVisible, setSelectLayersVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [zoomLevel, setZoomLevel] = useState(12);
	const savedCenter = JSON.parse(sessionStorage.getItem("mapCenter"));
	const savedZoom = sessionStorage.getItem("mapZoom");
	const modalContentRef = useRef(null);
	const theme = useTheme();
	const pipesRef = useRef();
	const nodesRef = useRef();
	const probeRef = useRef();

	const fetchData = async () => {
		try {
			const getMapProbes = { action: "get-map-probes-all" };

			const getFeaturesLineString = {
				action: "get-features",
				type: "LineString",
				region_id: selectedRegion,
			};
			const getFeaturesPoint = {
				action: "get-features",
				type: "Point",
				region_id: selectedRegion,
			};
			const getRegionsRequest = {
				action: "get-regions",
			};
			const getProbesAll = { action: "get-probes-all", nodes: true };
			const getNodesRequest = {
				action: "get-nodes",
			};

			const [
				mapProbesInitialResponse,
				featuresLineStringResponse,
				featuresPointResponse,
				regionsResponse,
				probesAllResponse,
				nodesResponse,
			] = await Promise.all([
				sendRequest(getMapProbes),
				sendRequest(getFeaturesLineString),
				sendRequest(getFeaturesPoint),
				sendRequest(getRegionsRequest),
				sendRequest(getProbesAll),
				sendRequest(getNodesRequest),
			]);

			const checkPumpsStatus = {
				action: "get-probes-pump-status",
			};

			const checkPumpsStatusResponse = await sendRequest(
				checkPumpsStatus
			);
			sessionStorage.setItem(
				"mapProbes",
				JSON.stringify(mapProbesInitialResponse)
			);
			const getewayNetwork = mapProbesInitialResponse.data._data_
				.filter((item) => {
					return item.latitude !== 0 && item.longitude !== 0 && item.gateway_name;
				})
				.map((item) => ({
					gateway_name: item.gateway_name,
					latitude: item.latitude,
					longitude: item.longitude,
					project: item.project,
				}));
			setGatewayNetwork(getewayNetwork);

			const updatedMapProbes = mapProbesInitialResponse.data._data_.map(
				(probe) => {
					const updatedProbe =
						checkPumpsStatusResponse.data._data_.find(
							(item) => item.probe_id === probe.probe_id
						);
					if (updatedProbe) {
						return {
							...probe,
							probe_running: updatedProbe.probe_running,
							error: updatedProbe.error,
						};
					}
					return probe;
				}
			);
			setDrawings([
				...featuresLineStringResponse.data._data_,
				...featuresPointResponse.data._data_,
			]);
			setMapProbes(updatedMapProbes);
			setUserRegions(
				regionsResponse.data._data_.map((region) => ({
					value: region.id,
					label: region.r_name,
				}))
			);
			setPumpsList(
				probesAllResponse.data._data_.map((probe) => ({
					value: probe.probe_id,
					label:
					probe.location !== "undefined"
						? `${probe.location} (${probe.probe_name})`
						: `${probe.probe_description} (${probe.probe_name})`,
				}))
			);
			setNodesList(nodesResponse.data._data_);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		fetchDrawings();
	}, [selectedRegion]);

	const fetchDrawings = async () => {
		try {
			const getFeaturesLineString = {
				action: "get-features",
				type: "LineString",
				region_id: selectedRegion.value,
			};
			const getFeaturesPoint = {
				action: "get-features",
				type: "Point",
				region_id: selectedRegion.value,
			};

			const getGatewaysRequest = {
				action: "get-gateways",
				region_id: selectedRegion.value,
			};

			const [
				featuresLineStringResponse,
				featuresPointResponse,
				getGatewaysResponse,
			] = await Promise.all([
				sendRequest(getFeaturesLineString),
				sendRequest(getFeaturesPoint),
				sendRequest(getGatewaysRequest),
			]);
			setDrawings([
				...featuresLineStringResponse.data._data_,
				...featuresPointResponse.data._data_,
			]);
			setGateways(getGatewaysResponse.data._data_);
		} catch (error) {
			console.error(error);
		}
		setDrawType("Polygon");
		setMapKey(Math.random());
	};

	const getIconColor = (probe_running, probe_error) => {
		if (probe_error > 0) return theme.danger;
		if (probe_running === 0) return theme.blocked;
		if (probe_running === 1) return theme.standby;
		if (probe_running === 2) return theme.success;
		if (probe_running === 3) return theme.warning;

		return theme.warning;
	};

	const iconColors = useMemo(() => {
		return mapProbes.map((probe) =>
			getIconColor(probe.probe_running, probe.error)
		);
	}, [mapProbes, theme]);

	const handleDrawEnd = async (e) => {
		setCurrentFeature(e.feature);
		if (drawType === "Point") {
			setIsNodeAddingModalOpen(true);
		} else {
			setIsNodeAddingModalOpen(false);

			const saveFeature = {
				action: "save-feature",
				_data_: {
					type: drawType,
					coords: e.feature.getGeometry().getCoordinates(),
					uid: e.feature.ol_uid,
					region_id: selectedRegion.value,
				},
			};
			try {
				await sendRequest(saveFeature);
			} catch (error) {
				console.error(error);
			}

			await fetchDrawings();
		}
		setIsDrawingEnabled(false);
	};
	const closeModal = async () => {
		setIsNodeAddingModalOpen(false);
		setIsNodeDeleteModalOpen(false);
		setIsNodeEditModalOpen(false);
		setNodeError("");
		setNodeThroughputError("");
		setNodeDiameterError("");
		setSelectedNodesError("");
		setSelectedPumpsError("");
		setDrawType("Polygon");
		setMapKey(Math.random());
	};

	const validateNodeData = () => {
		let isValid = true;

		if (!nodeName) {
			setNodeError("Nazwa jest wymagana.");
			isValid = false;
		} else {
			setNodeError("");
		}
		if (!nodeThroughput) {
			setNodeThroughputError("Przepustowość jest wymagana.");
			isValid = false;
		} else {
			setNodeThroughputError("");
		}
		if (!nodeDiameter) {
			setNodeDiameterError("Średnica jest wymagana.");
			isValid = false;
		} else {
			setNodeDiameterError("");
		}
		if (!selectedPumps || selectedPumps.length === 0) {
			setSelectedPumpsError("Wybierz przynajmniej jedną.");
			isValid = false;
		} else {
			setSelectedPumpsError("");
		}

		return isValid;
	};

	const clearForm = () => {
		setNodeId("");
		setNodeName("");
		setNodeThroughput("");
		setNodeDiameter("");
		setSelectedNodes(null);
		setSelectedPumps(null);
	};

	const handleAddNode = async () => {
		const isValid = validateNodeData();

		if (isValid) {
			const saveNode = {
				action: "save-node",
				_data_: {
					name: nodeName,
					probes: selectedPumps.map((pump) => pump.value),
					nodes: "",
					throughput: nodeThroughput,
					diameter: nodeDiameter,
					region_id: selectedRegion.value,
				},
			};

			try {
				const responseSaveNode = await sendRequest(saveNode);
				const saveFeature = {
					action: "save-feature",
					_data_: {
						type: "Point",
						coords: currentFeature.getGeometry().getCoordinates(),
						uid: responseSaveNode.data.node_id,
						name: nodeName,
						region_id: selectedRegion.value,
					},
				};

				try {
					await sendRequest(saveFeature);
					await fetchDrawings();
					fetchData();
					clearForm();
					closeModal();
				} catch (error) {
					console.error(error);
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleFeatureClick = async (feature) => {
		if (!Array.isArray(selectedRegion)) {
			document.body.style.cursor = "default";
			if (!isDrawingEnabled) {
				if (isDeletingEnabled) {
					if (feature.type === "Point") {
						let node = nodesList.find(
							(n) => n.node_id === feature.uid
						);
						setDeletingNode(node);
						setIsNodeDeleteModalOpen(true);
					} else {
						const deleteFeatureRequest = {
							action: "delete-feature",
							_data_: {
								type: "LineString",
								uid: feature.uid,
							},
						};
						try {
							await sendRequest(deleteFeatureRequest);
							await fetchDrawings();
						} catch (error) {
							console.error(error);
						}
						setIsDeletingEnabled(false);
					}
				} else {
					if (feature.type === "Point") {
						let node = nodesList.find(
							(n) => n.node_id === feature.uid
						);
						if (node) {
							const getNodeProbesRequest = {
								action: "get-node-probes",
								node_id: node.node_id,
							};
							try {
								const response = await sendRequest(
									getNodeProbesRequest
								);
								const nodeProbes = response.data._data_;
								setNodeId(node.node_id);
								setNodeName(node.node_name);
								setNodeThroughput(node.throughput);
								setNodeDiameter(node.diameter);
								const availablePumpsList = [
									...pumpsList,
									...nodeProbes.probes.map((probe) => ({
										value: probe.probe_id,
										label: `${probe.description} (${probe.probe_name})`,
									})),
								];
								setAvailablePumpsList(availablePumpsList);
								setAvailableNodesList(
									nodesList.filter(
										(n) => n.node_id !== node.node_id
									)
								);

								setSelectedPumps(
									nodeProbes.probes.map((probe) => ({
										value: probe.probe_id,
										label: `${probe.description} (${probe.probe_name})`,
									}))
								);
								setSelectedNodes(
									nodeProbes.nodes.map((node) => ({
										value: node.node_id,
										label: node.node_name,
									}))
								);
								setIsNodeEditModalOpen(true);
							} catch (error) {
								console.error(error);
							}
						}
					}
				}
			}
		} else {
			setSelectedRegionError("Musisz wybrać region by edytować");
		}
	};

	const handleEditNode = async () => {
		const isValid = validateNodeData();

		if (isValid) {
			const saveNode = {
				action: "save-node",
				_data_: {
					node_id: nodeId,
					name: nodeName,
					probes: selectedPumps.map((pump) => pump.value),
					nodes: selectedNodes.map((node) => node.value),
					throughput: nodeThroughput,
					diameter: nodeDiameter,
				},
			};
			try {
				await sendRequest(saveNode);
				clearForm();
				closeModal();
				fetchData();
			} catch (error) {
				console.error(error);
			}
		}
	};
	const handleRegionChange = (selectedRegion) => {
		setSelectedRegion(selectedRegion);
		setSelectedRegionError("");
	};
	const handleEnableDrawing = (action) => {
		setIsDeletingEnabled(false);
		if (action === drawType) {
			setDrawType("Polygon");
			setIsDrawingEnabled(false);
			return;
		}
		if (!Array.isArray(selectedRegion)) {
			setDrawType(action);
			setIsDrawingEnabled(true);
		} else {
			setSelectedRegionError("Musisz wybrać region by edytować");
		}
	};

	const handleEnableDeleting = () => {
		if (!Array.isArray(selectedRegion)) {
			setDrawType("Polygon");
			setIsDrawingEnabled(false);
			setIsDeletingEnabled(!isDeletingEnabled);
		} else {
			setSelectedRegionError("Musisz wybrać region by edytować");
		}
	};

	const handleDeleteNode = async () => {
		const deleteFeatureRequest = {
			action: "delete-feature",
			_data_: {
				type: "Point",
				uid: deletingNode.node_id,
			},
		};
		const deleteNodeRequest = {
			action: "delete-node",
			node_id: deletingNode.node_id,
			confirmed: true,
		};

		try {
			await sendRequest(deleteFeatureRequest);
			await sendRequest(deleteNodeRequest);
			closeModal();
			setDeletingNode(null);
			fetchData();
		} catch (error) {
			console.error(error);
		}
	};

	const handlePointerMove = useCallback(
		(e) => {
			e.map.getInteractions().forEach((interaction) => {
				if (interaction instanceof Snap) {
					e.map.removeInteraction(interaction);
				}
			});

			if (pipesRef != null) {
				const pipesSource = pipesRef.current.source;
				const nodesSource = nodesRef.current.source;
				const probeSource = probeRef.current.source;

				const snap = new Snap({
					source: pipesSource,
				});
				const snap2 = new Snap({
					source: nodesSource,
				});
				const snap3 = new Snap({
					source: probeSource,
				});

				e.map.addInteraction(snap);
				e.map.addInteraction(snap2);
				e.map.addInteraction(snap3);
			}
		},
		[pipesRef, nodesRef, probeRef]
	);

	if (isLoading) return <Loading />;
	if (!isAccessVerified) {
		return null;
	}
	return (
		<MapEditLayout>
			<h2 className="page-title">Edycja Mapy</h2>
			<div
				style={{ cursor: isDrawingEnabled && "crosshair" }}
				className="map-container">
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
						marginBottom: "10px",
					}}>
					<Button
						style={{ backgroundColor: isDeletingEnabled && "red" }}
						onClick={() => handleEnableDeleting()}>
						Usuń
					</Button>
					<Button
						style={{
							backgroundColor:
								drawType === "LineString" && "green",
						}}
						onClick={() => handleEnableDrawing("LineString")}>
						Rurociąg
					</Button>
					<Button
						style={{
							backgroundColor: drawType === "Point" && "green",
						}}
						onClick={() => handleEnableDrawing("Point")}>
						Węzeł
					</Button>
					<Select
						placeholder="Wybierz region..."
						options={userRegions}
						onChange={handleRegionChange}
						styles={customStyles}
					/>
					<p>{selectedRegionError}</p>
					<Button
						style={{ marginLeft: "auto" }}
						onClick={() => setIsMultiLine(!isMultiLine)}>
						{isMultiLine
							? "Wyłącz wielokrotną linię"
							: "Włącz wielokrotną linię"}
					</Button>
				</div>
				<button
					className="select-layers-button"
					onClick={() =>
						setSelectLayersVisible(!isSelectLayersVisible)
					}>
					&#9776;
				</button>
				{isSelectLayersVisible && (
					<div className="select-layers">
						<label>
							<input
								type="checkbox"
								checked={arePumpsVisible}
								onChange={(e) =>
									setPumpsVisible(e.target.checked)
								}
							/>
							Pompownie
						</label>
						<label>
							<input
								type="checkbox"
								checked={arePipesVisible}
								onChange={(e) =>
									setPipesVisible(e.target.checked)
								}
							/>
							Pokaż rurociągi
						</label>
						<label>
							<input
								type="checkbox"
								checked={areNodesVisible}
								onChange={(e) =>
									setNodesVisible(e.target.checked)
								}
							/>
							Węzły
						</label>
						<label>
							<input
								type="checkbox"
								checked={areGatewaysVisible}
								onChange={(e) =>
									setGatewaysVisible(e.target.checked)
								}
							/>
							Punkty dostępowe
						</label>
					</div>
				)}
				<RMap
					width={"100%"}
					height={"94%"}
					onPointerMove={handlePointerMove}
					initial={{
						center:
							savedCenter ||
							fromLonLat([
								mapProbes[0]?.latitude || 18.5434897,
								mapProbes[0]?.longitude || 54.5277284,
							]),
						zoom:
							savedZoom ||
							(mapProbes[0]?.latitude && mapProbes[0]?.longitude
								? 14
								: 8),
					}}
					onMoveEnd={(evt) => {
						setZoomLevel(evt.target.getView().getZoom());
					}}>
					<ROSM />
					<RLayerVector
						zIndex={2}
						visible={arePumpsVisible}
						ref={probeRef}
						properties={{ label: "Pompownie" }}>
						{mapProbes.map((probe, index) => (
							<RFeature
								key={index}
								geometry={
									new Point(
										fromLonLat([
											probe.latitude,
											probe.longitude,
										])
									)
								}>
								<RStyle>
									<RIcon
										key={iconColors[index]}
										src={locationProbeIcon}
										color={iconColors[index]}
										scale={calculateIconScale(
											0.9,
											zoomLevel
										)}
										anchor={[0.5, 0.5]}
									/>
								</RStyle>
								<RPopup trigger={"hover"} className="map-popup">
									<p>
										<strong>
											{probe.probe_info.probe_name}
										</strong>
									</p>
									<p>
										<em>
											{probe.probe_info.probe_description}
										</em>
									</p>
									<div>
										Harmonogramy:
										{probe.schedules &&
											probe.schedules
												.split(",")
												.map((schedule, index) => (
													<p key={index}>
														{schedule}
													</p>
												))}
									</div>
								</RPopup>
							</RFeature>
						))}
					</RLayerVector>
					<RLayerVector
						visible={arePipesVisible}
						key={mapKey}
						ref={pipesRef}
						properties={{ label: "Rury" }}>
						{drawings
							.filter((drawing) => drawing.type === "LineString")
							.map((lineString) => (
								<RFeature
									key={lineString.uid}
									onClick={() =>
										handleFeatureClick(lineString)
									}
									geometry={
										new LineString(
											JSON.parse(lineString.coords)
										)
									}>
									<RStyle>
										<RStroke color={theme.pipe} width={3} />
									</RStyle>
								</RFeature>
							))}
						<RInteraction.RDraw
							maxPoints={
								drawType === "LineString"
									? isMultiLine
										? Infinity
										: 2
									: 1
							}
							type={drawType}
							condition={() => isDrawingEnabled}
							onDrawEnd={handleDrawEnd}
						/>
					</RLayerVector>
					<RLayerVector
						zIndex={1}
						visible={areNodesVisible}
						ref={nodesRef}
						properties={{ label: "Węzły" }}>
						{drawings
							.filter((drawing) => drawing.type === "Point")
							.map((point, index) => {
								const node = nodesList.find(
									(node) => node.node_id === point.uid
								);
								return (
									<RFeature
										onPointerEnter={() =>
											(document.body.style.cursor =
												"pointer")
										}
										onPointerLeave={() =>
											(document.body.style.cursor =
												"default")
										}
										key={index}
										onClick={() =>
											handleFeatureClick(point)
										}
										geometry={
											new Point(JSON.parse(point.coords))
										}>
										<RStyle>
											<RIcon
												scale={calculateIconScale(
													1,
													zoomLevel
												)}
												src={locationNodeIcon}
												anchor={[0.5, 0.7]}
											/>
										</RStyle>
										<RPopup
											trigger={"hover"}
											className="map-popup">
											<p>
												<strong>
													Nazwa:{" "}
													{node
														? node.node_name
														: "Unknown"}
												</strong>
											</p>
											<p>
												<em>
													Przepustowość(m³/h):{" "}
													{node
														? node.throughput
														: "Unknown"}
												</em>
											</p>
											<p>
												<em>
													Średnica(mm):{" "}
													{node
														? node.diameter
														: "Unknown"}
												</em>
											</p>
										</RPopup>
									</RFeature>
								);
							})}
					</RLayerVector>
					<RLayerVector
						zIndex={1}
						visible={areGatewaysVisible}
						properties={{ label: "Gateways" }}>
						{gateways.map((gateway) => {
							return (
								<RFeature
									key={gateway.gateway_name}
									geometry={
										new Point(
											fromLonLat([
												gateway.lon,
												gateway.lat,
											])
										)
									}>
									<RStyle>
										<RIcon
											scale={calculateIconScale(
												1,
												zoomLevel
											)}
											src={locationGatewayIcon}
											anchor={[0.5, 0.5]}
										/>
									</RStyle>
									<RPopup
											trigger={"hover"}
											className="map-popup">
											<p>
												<strong>
												Gateway
												</strong>
											</p>
											<p>
												<em>
													Nazwa: {" "}
													{gateway
														? gateway.gateway_name
														: "Unknown"}
												</em>
											</p>
										</RPopup>
								</RFeature>
							);
						})}
					</RLayerVector>
					<RLayerVector
						zIndex={0}
						visible={areGatewaysVisible}
						properties={{ label: "Gateways" }}>
						{gatewayNetwork
							.filter(
								(gatewayNet) =>
									gatewayNet.project === selectedRegion.label
							)
							.map((gatewayNet, index) => {
								const matchingGateway = gateways.find(
									(gateway) =>
										gateway.gateway_name ===
										gatewayNet.gateway_name
								);

								const gatewayLon = matchingGateway
									? matchingGateway.lon
									: gatewayNet.gateway_lon;
								const gatewayLat = matchingGateway
									? matchingGateway.lat
									: gatewayNet.gateway_lat;

								return (
									<RFeature
										key={index}
										geometry={
											new LineString([
												fromLonLat([
													gatewayLon,
													gatewayLat,
												]),
												fromLonLat([
													gatewayNet.latitude,
													gatewayNet.longitude,
												]),
											])
										}>
										<RStyle>
											<RStroke
												color={theme.gatewayline}
												width={0.5}
											/>
										</RStyle>
									</RFeature>
								);
							})}
					</RLayerVector>
				</RMap>
			</div>
			{isNodeAddingModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Dodaj Węzeł</h2>
						<div>
							<label htmlFor="name">Nazwa węzła:</label>
							<input
								id="name"
								type="text"
								placeholder="Nazwa węzła"
								value={nodeName}
								onChange={(e) => setNodeName(e.target.value)}
							/>
							<p className="err-msg">{nodeError}</p>
						</div>
						<div>
							<label htmlFor="throughput">Przepustowość:</label>
							<input
								id="throughput"
								type="number"
								placeholder="Przepustowość w m³/h"
								value={nodeThroughput}
								onChange={(e) =>
									setNodeThroughput(e.target.value)
								}
							/>
							<p className="err-msg">{nodeThroughputError}</p>
						</div>
						<div>
							<label htmlFor="diameter">Średnica:</label>
							<input
								id="diameter"
								type="number"
								placeholder="Średnica w cm"
								value={nodeDiameter}
								onChange={(e) =>
									setNodeDiameter(e.target.value)
								}
							/>
							<p className="err-msg">{nodeDiameterError}</p>
						</div>
						<div>
							<label>Podpięte pompownie:</label>
							<Select
								placeholder="Wybierz podpięte pompownie..."
								isMulti
								options={pumpsList}
								defaultValue={selectedPumps}
								onChange={setSelectedPumps}
								styles={customStyles}
							/>
							<p className="err-msg">{selectedPumpsError}</p>
						</div>
						<div>
							<label>Podłączone węzły:</label>
							<Select
								placeholder="Wybierz podłączone węzły..."
								isMulti
								options={nodesList.map((node) => ({
									value: node.node_id,
									label: node.node_name,
								}))}
								defaultValue={selectedNodes}
								onChange={setSelectedNodes}
								styles={customStyles}
							/>
							<p className="err-msg">{selectedNodesError}</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button className="add" onClick={handleAddNode}>
								Dodaj
							</Button>
						</div>
					</div>
				</div>
			)}
			{isNodeEditModalOpen && (
				<div
					onMouseDown={() => {
						closeModal();
						clearForm();
					}}
					className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Edytuj Węzeł</h2>
						<div>
							<label htmlFor="name">Nazwa węzła:</label>
							<input
								id="name"
								type="text"
								placeholder="Nazwa węzła"
								value={nodeName}
								onChange={(e) => setNodeName(e.target.value)}
							/>
							<p className="err-msg">{nodeError}</p>
						</div>
						<div>
							<label htmlFor="throughput">Przepustowość:</label>
							<input
								id="throughput"
								type="number"
								placeholder="Przepustowość w m³/h"
								value={nodeThroughput}
								onChange={(e) =>
									setNodeThroughput(e.target.value)
								}
							/>
							<p className="err-msg">{nodeThroughputError}</p>
						</div>
						<div>
							<label htmlFor="diameter">Średnica:</label>
							<input
								id="diameter"
								type="number"
								placeholder="Średnica w cm"
								value={nodeDiameter}
								onChange={(e) =>
									setNodeDiameter(e.target.value)
								}
							/>
							<p className="err-msg">{nodeDiameterError}</p>
						</div>
						<div>
							<label>Podpięte pompownie:</label>
							<Select
								placeholder="Wybierz podpięte pompownie..."
								isMulti
								options={availablePumpsList}
								defaultValue={selectedPumps}
								onChange={setSelectedPumps}
								styles={customStyles}
							/>
							<p className="err-msg">{selectedPumpsError}</p>
						</div>
						<div>
							<label>Podłączone węzły:</label>
							<Select
								placeholder="Wybierz podłączone węzły..."
								isMulti
								options={availableNodesList.map((node) => ({
									value: node.node_id,
									label: node.node_name,
								}))}
								defaultValue={selectedNodes}
								onChange={setSelectedNodes}
								styles={customStyles}
							/>
							<p className="err-msg">{selectedNodesError}</p>
						</div>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() => {
									closeModal();
									clearForm();
								}}>
								Anuluj
							</Button>
							<Button className="add" onClick={handleEditNode}>
								Zapisz
							</Button>
						</div>
					</div>
				</div>
			)}
			{isNodeDeleteModalOpen && (
				<div
					onMouseDown={() => {
						closeModal();
						setDeletingNode(null);
					}}
					className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Usuń Węzeł</h2>
						<div>
							Czy na pewno chcesz usunąć węzeł
							<h3>{deletingNode.node_name}</h3>
						</div>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() => {
									closeModal();
									setDeletingNode(null);
								}}>
								Anuluj
							</Button>
							<Button className="add" onClick={handleDeleteNode}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
		</MapEditLayout>
	);
};

export default MapEdit;
