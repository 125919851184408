import { Outlet, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import useToken from "./hooks/useToken";
import Nav from "./components/Nav/Nav";
import TopNavbar from "./components/TopNavbar/TopNavbar";
import { useState, useEffect } from "react";
import { sendRequest } from "./api/sendRequest";
import Loading from "./components/Loading/Loading";

const Root = ({ toggleTheme }) => {
	const { token, setToken } = useToken();
	const [toggled, setToggled] = useState(false);
	const [isNavExpanded, setIsNavExpanded] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isInitialCheckDone, setIsInitialCheckDone] = useState(false);
	const location = useLocation();

function isValidBase64(str) {
	try {
		const decodedStr = atob(str);
		const reEncodedStr = btoa(decodedStr);
		return reEncodedStr === str || reEncodedStr === str + "=" || reEncodedStr === str + "==";
	} catch (err) {
		console.log('Error during encoding/decoding: ', err.message);
		return false;
	}
}

	useEffect(() => {
		if (token) {
			try {
				const payload = token.split(".")[1];
				if (!isValidBase64(payload)) {
					throw new Error("Payload is not valid Base64");
				}
				const decodedPayload = JSON.parse(atob(payload));
				const isTokenExpired =
					decodedPayload.exp < new Date().getTime() / 1000;

				if (isTokenExpired) {
					localStorage.removeItem("token");
					setToken("");
				} else {
					setIsInitialCheckDone(true);
				}
			} catch (error) {
				console.error(
					"An error occurred while decoding the token:",
					error
				);
				localStorage.removeItem("token");
				setToken("");
			}
		} else {
			const savedLogin = localStorage.getItem("login");
			const savedPassword = localStorage.getItem("password");

			if (savedLogin && savedPassword) {
				setIsLoading(true);
				const loginPayload = {
					username: savedLogin,
					password: savedPassword,
					action: "login",
				};

				sendRequest(loginPayload)
					.then((response) => {
						if (response.data.status === 1) {
							const routes = response.data.routes;
							const newToken = response.data.JWT;

							localStorage.setItem(
								"routes",
								JSON.stringify(routes)
							);
							setToken({ token: newToken });
						} else {
							localStorage.removeItem("login");
							localStorage.removeItem("password");
						}
					})
					.catch((err) => {
						console.error(
							"Failed to re-login with saved credentials: ",
							err
						);
						localStorage.removeItem("login");
						localStorage.removeItem("password");
					})
					.finally(() => {
						setIsLoading(false);
						setIsInitialCheckDone(true);
					});
			} else {
				setIsInitialCheckDone(true);
			}
		}
	}, [token, setToken]);

	useEffect(() => {
		const pageTitle = () => {
			switch (location.pathname) {
				case "/":
					return "Logowanie - NetQM IoT";
				case "/dashboard":
					return "Dashboard - NetQM IoT";
				case "/admin-settings":
					return "Ustawienia - NetQM IoT";
				case "/admin-users-admin":
					return "Użytkownicy - NetQM IoT";
				case "/admin-logs":
					return "Logi - NetQM IoT";
				case "/reports-pumps":
					return "Raporty Pompowni - NetQM IoT";
				case "/reports-alarms":
					return "Raporty Alarmów - NetQM IoT";
				case "/schedules":
					return "Harmonogramy - NetQM IoT";
				case "/pipelinemap":
					return "Mapa pracy sieci - NetQM IoT";
				case "/pipelinemap-nodes":
					return "Węzły sieci - NetQM IoT";
				case "/admin-regions":
					return "Regiony - NetQM IoT";
				case "/admin-map-edit":
					return "Edycja mapy - NetQM IoT";
				case "/noauth":
					return "Brak autoryzacji - NetQM IoT";
				default:
					return "NetQM IoT";
			}
		};
		document.title = pageTitle();
	}, [location]);

	if (isLoading) {
		return <Loading />;
	}

	if (!token && isInitialCheckDone) {
		return <Login setToken={setToken} />;
	}

	if (!isInitialCheckDone) {
		return <Loading />;
	}

	return (
		<>
			<TopNavbar
				toggleTheme={toggleTheme}
				toggled={toggled}
				setToggled={setToggled}
			/>
			<Nav
				toggled={toggled}
				setToggled={setToggled}
				isExpanded={isNavExpanded}
				setIsExpanded={setIsNavExpanded}
			/>
			<main
				className={`content ${
					isNavExpanded ? "expanded" : "collapsed"
				}`}>
				<Outlet />
			</main>
		</>
	);
};

export default Root;
