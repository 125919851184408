import { useState, useRef, useEffect } from "react";
import { Button } from "../../Button/Button";
import { StyledDataTable } from "../../Table/Table.styles";
import { ReactComponent as IcoArrowDown } from "./../../../svg/ico-w-dol.svg";
import Select from "react-select";
import { sendRequest } from "../../../api/sendRequest";
import { pumpRequest } from "../../../api/pumpRequest";
import { Observable } from "rxjs";
import { TailSpin } from "react-loader-spinner";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useStyledSelect } from "../../Select/StyledSelect";
import AdvancedSettings from "./AdvancedSettings";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

const DetailsSettings = ({ probe, nodesList, probeSchedules, setMapProbe }) => {
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const [showAdvanced, setShowAdvanced] = useState(false);
	const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState(false);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	const [editedProbe, setEditedProbe] = useState(probe);
	const [statusInfo, setStatusInfo] = useState("");
	const [allSchedules, setAllSchedules] = useState([]);
	const [buttonStatus, setButtonStatus] = useState({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [regions, setRegions] = useState([]);
	const modalContentRef = useRef(null);
	const navigate = useNavigate();
	const userRights = JSON.parse(localStorage.getItem("routes"));
	const isAdminRights = userRights.find(
		(item) => item.menu_key === "menu-probe-settings-delete-probe"
	);
	const [selectedSchedulesState, setSelectedSchedulesState] = useState(
		probeSchedules.map((schedule) => ({
			value: schedule.schedule_id,
			label: schedule.name,
		}))
	);
	const fetchData = async () => {
		const getRegionsRequest = {
			action: "get-regions",
		};
		const getSchedules = {
			action: "get-schedules",
		};

		try {
			const regionsResponse = await sendRequest(getRegionsRequest);
			const allSchedulesResponse = await sendRequest(getSchedules);

			setRegions(
				regionsResponse.data._data_.map((region) => ({
					value: region.id,
					label: region.r_name,
				}))
			);

			setAllSchedules(allSchedulesResponse.data._data_);
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	const handleSaveSettings = async (e) => {
		const buttonId = e.currentTarget.getAttribute("data-button-id");
		setButtonStatus({ [buttonId]: "" });
		const saveProbeRequest = {
			action: "save-probe",
			_data_: {
				...editedProbe,
				schedules: selectedSchedulesState
					.map((schedule) => schedule.value)
					.join(","),
			},
		};
		try {
			await sendRequest(saveProbeRequest);
			setButtonStatus({ [buttonId]: "success" });
			sessionStorage.removeItem('mapProbesTimestamp');
			setMapProbe((prevState) => ({
				...prevState,
				latitude: saveProbeRequest._data_.lon,
				longitude: saveProbeRequest._data_.lat,
			}));
		} catch (error) {
			console.error(error);
			setButtonStatus({ [buttonId]: "error" });
		}
	};

	const handleDeleteProbe = async () => {
		const deleteProbeRequest = {
			action: "delete-probe",
			probe_id: probe.probe_id,
			confirmed: true,
		};
		try {
			await sendRequest(deleteProbeRequest);
			setStatusInfo("Pomyślnie usunięto!");
			navigate(`/`);
		} catch (error) {
			console.error(error);
			setStatusInfo("Błąd usuwania!");
		}
	};


	const getPumpLocalization = async (e) => {
		const buttonId = e.currentTarget.getAttribute("data-button-id");
		setButtonStatus({ [buttonId]: "" });
		const pumpGetLonLat = {
			action: "pump-get-lonlat",
			probe_id: probe.probe_id,
		};

		try {
			await pumpRequest(pumpGetLonLat);
			setButtonStatus({ [buttonId]: "success" });
		} catch (error) {
			console.error(error);
			setButtonStatus({ [buttonId]: "error" });
		}
	};

	const handleInputChange = (parameter, value) => {
		setEditedProbe((prevState) => ({
			...prevState,
			[parameter.toLowerCase()]: value,
		}));
	};

	const handlePumpSaveConfiguration = async (e) => {
		const buttonId = e.currentTarget.getAttribute("data-button-id");
		setButtonStatus({ [buttonId]: "" });
		const savePumpConfiguration = {
			action: "pump-save-configuration",
			probe_id: probe.probe_id,
		};
		setIsSaveLoading(true);
		try {
			const response = await pumpRequest(savePumpConfiguration);
			const observable = new Observable((observer) => {
				const poll = async () => {
					try {
						const observableResponse = await pumpRequest({
							action: "pump-observable",
							probe_id: probe.probe_id,
							event_id: response.data.event_id,
						});
						console.log("Observable response:", observableResponse);
						if (
							observableResponse &&
							observableResponse.data &&
							observableResponse.data._data_ &&
							observableResponse.data._data_.event
						) {
							if (
								observableResponse.data._data_.event.includes(
									"done"
								)
							) {
								observer.next(observableResponse);
								setButtonStatus({ [buttonId]: "success" });
								setShowSaveConfirmationModal(false);
								setIsSaveLoading(false);
								observer.complete();
							} else {
								setTimeout(poll, 1000);
							}
						} else {
							console.error(
								"Unexpected response structure:",
								observableResponse
							);
							observer.error(
								new Error("Unexpected response structure")
							);
						}
					} catch (error) {
						observer.error(error);
					}
				};

				poll();
			});

			observable.subscribe({
				next: (res) => console.log("Received response:", res),
				error: (err) => {
					console.error(err);
					setButtonStatus({ [buttonId]: "error" });
				},
				complete: () => console.log("Observable completed"),
			});
		} catch (error) {
			setIsSaveLoading(false);
			console.error(error);
			setButtonStatus({ [buttonId]: "error" });
		}
	};


	const settingsColumns = [
		{
			name: "Parametr",
			selector: (row) => row.label,
			grow: 1,
		},
		{
			name: "Wartość",
			cell: (row) => {
				if (row.parameter === "r_name") {
					return (
						<div>
							<Select
								placeholder="Wybierz region..."
								className="select"
								options={regions}
								styles={customStyles}
								value={regions.find(
									(region) =>
										region.label === editedProbe.r_name
								)}
								onChange={(selected) => {
									setEditedProbe((prev) => ({
										...prev,
										r_name: selected.label,
										region_id: selected.value,
									}));
								}}
							/>
						</div>
					);
				}
				if (row.parameter === "node_id") {
					const nodes = nodesList.map((node) => ({
						value: node.node_id,
						label: node.node_name,
					}));

					let defaultNode = nodes.find(
						(node) => node.value === row.value
					);

					return (
						<div>
							<Select
								placeholder="Wybierz węzęł..."
								className="select"
								defaultValue={defaultNode}
								options={nodes}
								styles={customStyles}
								onChange={(selectedOption) => {
									setEditedProbe((prev) => ({
										...prev,
										node_id: selectedOption
											? selectedOption.value
											: null,
									}));
								}}
							/>
						</div>
					);
				}

				if (row.parameter === "auto_scheduler"){
					return (	
 						<div
						    style={{
							display: "flex",
							justifyContent: "left",
							alignItems: "center",
							width: "100%",
						    }}>
						    <Switch
							defaultChecked={((row.value === 1 || row.value === 2) ? true : false)}
							onChange={() => {
								setEditedProbe((prev) => ({
									...prev,
									auto_scheduler: (row.value === 1 || row.value === 2) ? 0 : 2
								}));
							}}
						    />
						</div>
					);
				}

				if (row.parameter === "schedules") {
					const schedules = allSchedules.map((schedule) => ({
						value: schedule.schedule_id,
						label: schedule.name,
					}));

					return (
						<div style={{display: "flex", alignItems: "center", gap: "5px"}}>
							<Select
								placeholder="Wybierz cztery aktywne harmonogramy..."
								className="select"
								isMulti
								value={selectedSchedulesState}
								options={schedules}
								styles={customStyles}
								onChange={(selectedOptions) => {
									setSelectedSchedulesState(selectedOptions);

									const selectedScheduleIds = selectedOptions
										? selectedOptions.map(
												(option) => option.value
										  )
										: [];
									setEditedProbe((prev) => ({
										...prev,
										schedules:
											selectedScheduleIds.join(","),
									}));
								}}
								isOptionDisabled={() =>
									selectedSchedulesState &&
									selectedSchedulesState.length >= 4
								}
							/>
							<p>{probe.schedules_uploaded ? "" : "Harmonogram w trakcie ładowania"}</p>
						</div>
					);
				}

				if (row.parameter === "probe_id") {
					return (
						<div style={{ paddingLeft: "10px" }}>{row.value}</div>
					);
				}

				if (row.parameter === "serial") {
					return (
						<div style={{ paddingLeft: "10px" }}>{row.value}</div>
					);
				}

				if (row.parameter === "active") {
					return (
						<div
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "center",
								width: "100%",
							}}>
							<Switch
								defaultChecked={row.value === 1}
								onChange={() => {
									setEditedProbe(prev => ({
										...prev,
										active: prev.active === 1 ? 0 : 1
									}));
								}}
							/>
						</div>
					);
				}				

				return (
					<div>
						<input
							type="text"
							value={editedProbe[row.parameter.toLowerCase()]}
							onChange={(e) =>
								handleInputChange(row.parameter, e.target.value)
							}
						/>
					</div>
				);
			},
			grow: 5,
		},
	];

	const settingsData = [
		{
			label: "ID",
			parameter: "probe_id",
			value: probe.probe_id,
		},
		{
			label: "Numer seryjny",
			parameter: "serial",
			value: probe.serial,
		},
		{
			label: "Nazwa",
			parameter: "probe_name",
			value: probe.probe_name,
		},
		{
			label: "Opis",
			parameter: "description",
			value: probe.description,
		},
		{
			label: "Węzły",
			parameter: "node_id",
			value: probe.node_id,
		},
		{
			label: "Regiony",
			parameter: "r_name",
			value: probe.r_name,
		},
		{
			label: "Automatyczny Harmonogram",
			parameter: "auto_scheduler",
			value: probe.auto_scheduler
		},
		{
			label: "Harmonogramy",
			parameter: "schedules",
			value: probeSchedules,
		},
		{
			label: "Adres instalacji",
			parameter: "location",
			value: probe.location,
		},
		{
			label: "Promień",
			parameter: "radius",
			value: probe.radius,
		},
		{
			label: "Szerokość",
			parameter: "lat",
			value: probe.lat,
		},
		{
			label: "Długość",
			parameter: "lon",
			value: probe.lon,
		},
		{
			label: "Metry sześcienne (m³/h)",
			parameter: "model",
			value: probe.model,
		},
		{
			label: "Zdalne zarządzanie pompownią",
			parameter: "active",
			value: probe.active,
		},
	];

	return (
		<div className="settings-container">
			{isSaveLoading && (
				<div
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						zIndex: 1000,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<TailSpin
						height="200"
						width="200"
						color={theme.primary}
						ariaLabel="tail-spin-loading"
						radius="1"
					/>
				</div>
			)}

			<div
				onClick={() => setShowAdvanced(!showAdvanced)}
				className="advanced-button">
				<IcoArrowDown width={30} />
				{showAdvanced ? "Ogólne" : "Zaawansowane"}
			</div>
			{showAdvanced ? (
				<AdvancedSettings probe={probe} />
			) : (
				<StyledDataTable
					data={settingsData}
					noDataComponent="Brak danych spełniających kryteria"
					columns={settingsColumns}
				/>
			)}
			<h2>{showAdvanced ? "Ustawienia Zaawansowane" : "Ustawienia"}</h2>
			{!showAdvanced && (
				<div className="actions">
					<Button
						data-button-id="handleSaveSettings"
						className={`action-button ${buttonStatus.handleSaveSettings}`}
						onClick={handleSaveSettings}
						style={{
							fontSize: "16px",
						}}>
						Zapisz
					</Button>
					<Button
						data-button-id="handlePumpSaveConfiguration"
						className={`action-button ${buttonStatus.handlePumpSaveConfiguration}`}
						onClick={() => setShowSaveConfirmationModal(true)}
						style={{
							fontSize: "16px",
							width: "auto",
							marginLeft: "20px",
						}}>
						Zapisz dane do sterownika
					</Button>
					<Button
						data-button-id="getPumpLocalization"
						className={`action-button ${buttonStatus.getPumpLocalization}`}
						onClick={getPumpLocalization}
						style={{
							fontSize: "16px",
							width: "auto",
							marginLeft: "20px",
						}}>
						Pobierz lokalizację
					</Button>
					{isAdminRights.visible && (
						<Button
							className="action-button"
							onClick={() => setShowDeleteConfirmationModal(true)}
							style={{
								fontSize: "16px",
								width: "auto",
								marginLeft: "20px",
								backgroundColor: theme.danger,
							}}>
							Usuń pompownie
						</Button>
					)}
					<span style={{ marginLeft: "15px" }}>{statusInfo}</span>
				</div>
			)}
			{showSaveConfirmationModal && (
				<div
					onMouseDown={() => setShowSaveConfirmationModal(false)}
					className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h3>Czy na pewno chcesz zapisać dane do sterownika?</h3>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() =>
									setShowSaveConfirmationModal(false)
								}>
								Anuluj
							</Button>
							<Button
								onClick={handlePumpSaveConfiguration}
								className="add">
								Zapisz tabelę
							</Button>
						</div>
					</div>
				</div>
			)}
			{showDeleteConfirmationModal && (
				<div
					onMouseDown={() => setShowDeleteConfirmationModal(false)}
					className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>
							Czy na pewno chcesz usunąć pompownię{" "}
							{probe.description}?
						</h2>
						<div className="modal-actions">
							<Button
								className="cancel"
								onClick={() =>
									setShowDeleteConfirmationModal(false)
								}>
								Anuluj
							</Button>
							<Button onClick={handleDeleteProbe} className="add">
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DetailsSettings;
