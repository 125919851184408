import useAccessControl from "../../../hooks/useAccessControl";
import React, {
	useState,
	useEffect,
	useMemo,
	useRef,
	useCallback,
} from "react";
import Select from "react-select";
import { sendRequest } from "../../../api/sendRequest";
import "ol/ol.css";
import { createEmpty, extend, getHeight, getWidth } from "ol/extent";
import { fromLonLat } from "ol/proj";
import {
	RMap,
	ROSM,
	RLayerVector,
	RFeature,
	RPopup,
	RLayerCluster,
	RInteraction,
} from "rlayers";
import {
	RIcon,
	RStyle,
	RFill,
	RStroke,
	RRegularShape,
	RCircle,
	RText,
} from "rlayers/style";
import { LineString, Point } from "ol/geom";
import { useTheme } from "styled-components";
import locationProbeIcon from "./../../../svg/Artboard 62.svg";
import locationProbeIconInactive from "./../../../svg/Artboard 62 transparent.svg";
import locationNodeIcon from "./../../../svg/iconmonstr-triangle-1.svg";
import locationGatewayIcon from "./../../../svg/square.svg";
import { PipelinemapLayout } from "../Pipelinemap.styles";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import calculateIconScale from "../../../hooks/calculateIconScale";
import { pumpRequest } from "../../../api/pumpRequest";
import { Button } from "../../../components/Button/Button";
import { useStyledSelect } from "../../../components/Select/StyledSelect";
import { shiftKeyOnly } from "ol/events/condition";
import { boundingExtent } from "ol/extent";
import { TailSpin } from "react-loader-spinner";

const Pipelinemap = () => {
	const customStyles = useStyledSelect();
	const isAccessVerified = useAccessControl();
	const [mapKey, setMapKey] = useState(Math.random());
	const [drawings, setDrawings] = useState([]);
	const [mapProbes, setMapProbes] = useState([]);
	const [gateways, setGateways] = useState([]);
	const [gatewayNetwork, setGatewayNetwork] = useState([]);
	const [userRegions, setUserRegions] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState([]);
	const [title, setTitle] = useState("");
	const [nodesList, setNodesList] = useState([]);
	const [arePumpsVisible, setPumpsVisible] = useState(true);
	const [arePipesVisible, setPipesVisible] = useState(true);
	const [areNodesVisible, setNodesVisible] = useState(true);
	const [pumpStatus, setPumpStatus] = useState("all");
	const [isMapLoading, setIsMapLoading] = useState(false);
	const [startDragBox, setStartDragBox] = useState(null);
	const [endDragBox, setEndDragBox] = useState(null);
	const [selectedProbes, setSelectedProbes] = useState([]);
	const [disabledGateways, setDisabledGateways] = useState({});
	const [areGatewaysVisible, setGatewaysVisible] = useState(false);
	const [isSelectLayersVisible, setSelectLayersVisible] = useState(false);
	const [zoomLevel, setZoomLevel] = useState(12);
	const [isLoading, setIsLoading] = useState(true);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [isSelectedProbesModalOpen, setIsSelectedProbesModalOpen] =
		useState(false);
	const [clickedNode, setClickedNode] = useState([]);
	const [isNodeModalIsOpen, setIsNodeModalIsOpen] = useState(false);
	const [info, setInfo] = useState("");
	const theme = useTheme();
	const params = useParams();
	const navigate = useNavigate();
	const mapProbesRef = useRef();
	const probeRef = useRef();
	const vectorRef = useRef();
	const modalContentRef = useRef(null);
	mapProbesRef.current = mapProbes;

	const radiusStar = (feature) =>
		Math.round(5 * (parseFloat(feature.get("mag")) - 2.5));

	const extentFeatures = (features, resolution) => {
		const extent = createEmpty();

		for (const f of features) extend(extent, f.getGeometry().getExtent());

		return (
			Math.round(0.25 * (getWidth(extent) + getHeight(extent))) /
			resolution
		);
	};

	const handleCacheId = useCallback(
		(feature, resolution) =>
			feature.get("features").length > 1
				? "#" + extentFeatures(feature.get("features"), resolution)
				: "$" + radiusStar(feature.get("features")[0]),

		[]
	);

	const handleRender = useCallback(
		(feature, resolution) => {
			const size = feature.get("features").length;
			const allFeatures = feature.get("features");
			const allProbeIds = allFeatures.map((f) => f.get("probeId"));

			const allProbeValues = allProbeIds.map((probeId) => {
				const probe = mapProbes.find((p) => p.probe_id === probeId);
				return probe
					? { error: probe.error, running: probe.probe_running }
					: null;
			});

			let statusColor = theme.standby;

			const hasError = allProbeValues.some((v) => v && v.error > 0);
			if (hasError) {
				statusColor = theme.danger;
			} else {
				if (allProbeValues.some((v) => v && v.running === 2)) {
					statusColor = theme.success;
				} else if (allProbeValues.some((v) => v && v.running === 0)) {
					statusColor = theme.blocked;
				} else if (allProbeValues.some((v) => v && v.running === 3)) {
					statusColor = theme.warning;
				}
			}

			if (size > 1) {
				const radius = extentFeatures(
					feature.get("features"),
					resolution
				);
				return (
					<>
						<RCircle radius={15}>
							<RFill color={statusColor} />
						</RCircle>
						<RText text={size.toString()} scale={1.5}>
							<RFill color="#000000" />
							<RStroke color="rgb(0, 0, 0)" width={0.5} />
						</RText>
					</>
				);
			}

			const unclusteredFeature = feature.get("features")[0];
			return (
				<RRegularShape
					radius1={radiusStar(unclusteredFeature)}
					radius2={3}
					points={5}
					angle={Math.PI}>
					<RFill color="rgba(255, 0, 0, 0.8)" />
					<RStroke color="rgba(255, 204, 0, 0.2)" width={5} />
				</RRegularShape>
			);
		},
		[mapProbes]
	);

	const fetchRegions = async () => {
		try {
			const getRegionsRequest = {
				action: "get-regions",
			};
			const regionsResponse = await sendRequest(getRegionsRequest);
			setUserRegions(
				regionsResponse.data._data_.map((region) => ({
					value: region.id,
					label: region.r_name,
				}))
			);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const fetchData = async () => {
		try {
			const getMapProbes = { action: "get-map-probes-all" };
			const getNodesRequest = {
				action: "get-nodes",
			};

			const [mapProbesInitialResponse, nodesResponse] = await Promise.all(
				[sendRequest(getMapProbes), sendRequest(getNodesRequest)]
			);

			const checkPumpsStatus = {
				action: "get-probes-pump-status",
			};

			const checkPumpsStatusResponse = await sendRequest(
				checkPumpsStatus
			);

			const getewayNetwork = mapProbesInitialResponse.data._data_
				.filter((item) => {
					return (
						item.latitude !== 0 &&
						item.longitude !== 0 &&
						item.gateway_name
					);
				})
				.map((item) => ({
					gateway_name: item.gateway_name,
					latitude: item.latitude,
					longitude: item.longitude,
					project: item.project,
				}));
			setGatewayNetwork(getewayNetwork);

			let updatedMapProbes = mapProbesInitialResponse.data._data_.map(
				(probe) => {
					const updatedProbe =
						checkPumpsStatusResponse.data._data_.find(
							(item) => item.probe_id === probe.probe_id
						);
					if (updatedProbe) {
						return {
							...probe,
							probe_running: updatedProbe.probe_running,
							error: updatedProbe.error,
						};
					}
					return probe;
				}
			);

			if (Object.keys(params).length !== 0) {
				if (params.type === "schedule") {
					updatedMapProbes = updatedMapProbes.filter((probe) => {
						if (typeof probe.schedules === "string") {
							const schedulesArray = probe.schedules
								.split(",")
								.map((s) => s.trim());
							return schedulesArray.includes(params.id_schedule);
						}
						return false;
					});
					setTitle(`Harmonogram: ${params.id_schedule}`);
				} else {
					setTitle("");
				}
			}

			setMapProbes(updatedMapProbes);
			setIsLoading(false);
			setNodesList(nodesResponse.data._data_);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const fetchProbesAllInterval = setInterval(checkAllPumpStatus, 10000);
		fetchRegions();
		fetchData();
		return () => {
			clearInterval(fetchProbesAllInterval);
		};
	}, [params]);

	useEffect(() => {
		if (selectedRegion.value) {
			fetchDrawings();
		}
	}, [selectedRegion]);

	const checkAllPumpStatus = async () => {
		const checkPumpsStatus = {
			action: "get-probes-pump-status",
		};
		try {
			const response = await sendRequest(checkPumpsStatus);
			const updatedData = response.data._data_;

			const newMapProbes = mapProbesRef.current.map((probe) => {
				const updatedProbe = updatedData.find(
					(item) => item.probe_id === probe.probe_id
				);
				if (updatedProbe) {
					return {
						...probe,
						probe_running: updatedProbe.probe_running,
						error: updatedProbe.error,
					};
				}
				return probe;
			});
			setMapProbes(newMapProbes);
		} catch (error) {
			console.error(error);
		}
	};

	const statusMessage = (probe) => {
		switch (probe.probe_running) {
			case 0:
				return "Wyłączona";
			case 1:
				return "Nie pompuje";
			case 2:
				return "Pompuje";
			case 3:
				return "Brak połączenia";
			default:
				return "Brak połączenia";
		}
	};

	const getIconColor = (probe_running, probe_error, probe_status) => {
		if (probe_status === 0) return "transparent";
		if (probe_error > 0) return theme.danger;
		if (probe_running === 0) return theme.blocked;
		if (probe_running === 1) return theme.standby;
		if (probe_running === 2) return theme.success;
		if (probe_running === 3) return theme.warning;

		return theme.warning;
	};

	const iconColors = useMemo(() => {
		const colors = {};
		mapProbes.forEach((probe) => {
			colors[probe.probe_id] = getIconColor(
				probe.probe_running,
				probe.error,
				probe.status
			);
		});
		return colors;
	}, [mapProbes, theme]);

	const fetchDrawings = async () => {
		setIsMapLoading(true);
		const getFeaturesLineString = {
			action: "get-features",
			type: "LineString",
			region_id: selectedRegion.value,
		};
		const getFeaturesPoint = {
			action: "get-features",
			type: "Point",
			region_id: selectedRegion.value,
		};
		const getGatewaysRequest = {
			action: "get-gateways",
			region_id: selectedRegion.value,
		};

		try {
			const [
				featuresLineStringResponse,
				featuresPointResponse,
				getGatewaysResponse,
			] = await Promise.all([
				sendRequest(getFeaturesLineString),
				sendRequest(getFeaturesPoint),
				sendRequest(getGatewaysRequest),
			]);
			setGateways(getGatewaysResponse.data._data_);
			setDrawings([
				...featuresLineStringResponse.data._data_,
				...featuresPointResponse.data._data_,
			]);
		} catch (error) {
			console.error(error);
		}
		setMapKey(Math.random());
		setIsMapLoading(false);
	};

	const handleNodeClicked = (node) => {
		setIsNodeModalIsOpen(true);
		setClickedNode(node);
	};

	const closeModal = () => {
		setIsNodeModalIsOpen(false);
		setIsConfirmModalOpen(false);
		setIsSelectedProbesModalOpen(false);
		setSelectedProbes([]);
		setClickedNode([]);
	};

	const cleanPipelines = async () => {
		const getFeaturesLineString = {
			action: "pump-clean-pipeline",
			node_id: clickedNode.node_id,
		};

		try {
			await pumpRequest(getFeaturesLineString);
			setInfo("Wysłano żądanie płukania rurociągów.");
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const lockAllSelected = async () => {
		const getFeaturesLineString = {
			action: "pump-set-lock",
			probe_id: selectedProbes,
			type: "lock",
		};

		try {
			await pumpRequest(getFeaturesLineString);
			setInfo("Wysłano żądanie zablokowania pompowni.");
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const unlockAllSelected = async () => {
		const getFeaturesLineString = {
			action: "pump-set-lock",
			probe_id: selectedProbes,
			type: "unlock",
		};

		try {
			await pumpRequest(getFeaturesLineString);
			setInfo("Wysłano żądanie odblokowania pompowni.");
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const handleBoxStart = React.useCallback(
		(e) => {
			setStartDragBox(e.coordinate);
			setEndDragBox(undefined);
		},
		[setStartDragBox, setEndDragBox]
	);

	const handleBoxEnd = React.useCallback(
		(e) => {
			setEndDragBox(e.coordinate);
			const selected = [];
			if (vectorRef.current && startDragBox) {
				vectorRef.current.source.forEachFeatureInExtent(
					boundingExtent([startDragBox, e.coordinate]),
					(f) => {
						const probeId = f.get("probeId");
						if (probeId !== undefined) {
							selected.push(probeId);
						}
					}
				);
			}
			setSelectedProbes(selected);
			setIsSelectedProbesModalOpen(true);
		},
		[startDragBox, setSelectedProbes, vectorRef]
	);

	const calculateCenterCoordinates = () => {
		const filteredProbes = mapProbes.filter(
			(probe) => probe.project === selectedRegion.label
		);

		for (let probe of filteredProbes) {
			if (probe.latitude !== 0 || probe.longitude !== 0) {
				return fromLonLat([probe.latitude, probe.longitude]);
			}
		}

		const mapCenter = JSON.parse(sessionStorage.getItem("mapCenter"));
		if (mapCenter) {
			return mapCenter;
		} else {
			return fromLonLat([18.5434897, 54.5277284]);
		}
	};

	const centerCoordinates = calculateCenterCoordinates();

	const handleGatewayClick = (gatewayName) => {
		setDisabledGateways((prevDisabledGateways) => ({
			...prevDisabledGateways,
			[gatewayName]: !prevDisabledGateways[gatewayName],
		}));
	};

	useEffect(() => {
		if (gateways.length > 0 && gatewayNetwork.length > 0 && !gateways[0].connected) {
			const updatedGateways = gateways.map(gateway => {
				const connectedCount = gatewayNetwork.filter(net => net.gateway_name === gateway.gateway_name).length;
				return { ...gateway, connected: connectedCount };
			});
	
			setGateways(updatedGateways);
		}
	
	}, [gatewayNetwork, gateways]);
	
	

	if (isLoading) return <Loading />;
	if (!isAccessVerified) {
		return null;
	}

	return (
		<PipelinemapLayout>
			{isMapLoading && (
				<TailSpin
					height="80"
					width="80"
					color={theme.primary}
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{
						position: "absolute",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						zIndex: 10,
					}}
					wrapperClass="loading-backdrop"
					visible={true}
				/>
			)}
			<h2 className="page-title">Mapa pracy sieci</h2>
			{title && <h3 className="page-title-params">{title}</h3>}
			<div className="map-container">
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
						marginBottom: "10px",
					}}>
					<Select
						placeholder="Wybierz region..."
						options={userRegions}
						value={selectedRegion}
						onChange={setSelectedRegion}
						styles={customStyles}
					/>
					<span>{info}</span>
				</div>
				<button
					className="select-layers-button"
					onClick={() =>
						setSelectLayersVisible(!isSelectLayersVisible)
					}>
					&#9776;
				</button>
				{isSelectLayersVisible && (
					<div className="select-layers">
						<label>
							<input
								type="checkbox"
								checked={arePumpsVisible}
								onChange={(e) =>
									setPumpsVisible(e.target.checked)
								}
							/>
							Pompownie
						</label>
						{arePumpsVisible && (
							<select
								onChange={(e) => setPumpStatus(e.target.value)}
								defaultValue="all">
								<option value="all">Wszystkie</option>
								<option value={0}>Wyłączona</option>
								<option value={1}>Nie pompuje</option>
								<option value={2}>Pompuje</option>
								<option value={3}>Brak połączenia</option>
								<option value="error">Z błędem</option>
							</select>
						)}
						<label>
							<input
								type="checkbox"
								checked={arePipesVisible}
								onChange={(e) =>
									setPipesVisible(e.target.checked)
								}
							/>
							Rurociągi
						</label>
						<label>
							<input
								type="checkbox"
								checked={areNodesVisible}
								onChange={(e) =>
									setNodesVisible(e.target.checked)
								}
							/>
							Węzły
						</label>
						<label>
							<input
								type="checkbox"
								checked={areGatewaysVisible}
								onChange={(e) =>
									setGatewaysVisible(e.target.checked)
								}
							/>
							Punkty dostępowe
						</label>
					</div>
				)}
				<RMap
					key={mapKey}
					minZoom={8}
					width={"100%"}
					height={"94%"}
					initial={{
						center: centerCoordinates,
						zoom: 15,
					}}
					onMoveEnd={(evt) => {
						setZoomLevel(evt.target.getView().getZoom());
					}}>
					<ROSM />
					{zoomLevel < 14 && (
						<RLayerCluster
							distance={50}
							visible={arePumpsVisible}
							ref={probeRef}
							zIndex={15}>
							{mapProbes
								.filter((probe) => probe.status !== 0)
								.filter(
									(probe) =>
										probe.project === selectedRegion.label
								)
								.filter((probe) => {
									if (pumpStatus === "all") {
										return true;
									} else if (pumpStatus === "error") {
										return probe.error > 0;
									} else {
										return (
											probe.probe_running ===
											Number(pumpStatus)
										);
									}
								})
								.map((probe, index) => (
									<RFeature
										key={index}
										properties={{ probeId: probe.probe_id }}
										geometry={
											new Point(
												fromLonLat([
													probe.latitude,
													probe.longitude,
												])
											)
										}></RFeature>
								))}
							<RStyle
								cacheSize={1024}
								cacheId={handleCacheId}
								render={handleRender}
							/>
						</RLayerCluster>
					)}
					<RLayerVector
						ref={vectorRef}
						zIndex={3}
						visible={arePumpsVisible}
						properties={{ label: "Pompownie" }}>
						{mapProbes
							.filter(
								(probe) =>
									probe.project === selectedRegion.label
							)
							.filter((probe) => {
								if (pumpStatus === "all") {
									return true;
								} else if (pumpStatus === "error") {
									return probe.error > 0;
								} else {
									return (
										probe.probe_running ===
										Number(pumpStatus)
									);
								}
							})
							.sort((a, b) => {
								const getPriority = (probe) => {
								  if (probe.status === 0) return 1;
								  if (probe.status === 1 && probe.probe_running === 1 && probe.error === 0) return 2;
								  if (probe.status === 1 && probe.probe_running === 2 && probe.error === 0) return 3;
								  if (probe.status === 1 && probe.error > 0) return 4;
								  if (probe.status === 1 && probe.probe_running === 3 && probe.error === 0) return 5;
								  return 6; 
								};
							  
								const priorityA = getPriority(a);
								const priorityB = getPriority(b);
							  
								if (priorityA < priorityB) return -1;
								if (priorityA > priorityB) return 1;
								return 0;
							  })
							.map((probe) => {
								const color = iconColors[probe.probe_id];

								const getMultiProbeInfo = (probe) => {
									if (probe.multi_probe) {
										try {
											const multiProbeArray =
												JSON.parse(
													probe.multi_probe
												);
											if (
												Array.isArray(
													multiProbeArray
												)
											) {
												const count =
													multiProbeArray.length;
												if (count === 2)
													return "Grupa 2-pompowa";
												if (count === 3)
													return "Grupa 3-pompowa";
												if (count === 4)
													return "Grupa 4-pompowa";
											}
										} catch (e) {
											console.error(
												"Błąd podczas przetwarzania multi_probe:",
												e
											);
										}
									}
									return null;
								};

								const multiProbeInfo =
									getMultiProbeInfo(probe);
								return (
									<RFeature
									onClick={() => {
										if (multiProbeInfo) {
											const multiProbeIds =
												probe.multi_probe
													? JSON.parse(
															probe.multi_probe
													  ).join("_")
													: probe.probe_id;
											navigate(
												`/multidetails/${multiProbeIds}`
											);
										} else {
											navigate(
												`/details/${probe.probe_id}`
											);
										}
										document.body.style.cursor =
											"default";
									}}
										key={probe.probe_id}
										properties={{
											probeId: probe.probe_id,
										}}
										geometry={
											new Point(
												fromLonLat([
													probe.latitude,
													probe.longitude,
												])
											)
										}>
										<RStyle>
											<RIcon
												key={color}
												src={
													color === "transparent"
														? locationProbeIconInactive
														: locationProbeIcon
												}
												color={color}
												scale={calculateIconScale(
													0.9,
													zoomLevel
												)}
												anchor={[0.5, 0.5]}
											/>
										</RStyle>
										<RPopup
												trigger={"hover"}
												className="map-popup">
												<p>
													<strong>
														{
															probe.probe_info
																.probe_name
														}
													</strong>
												</p>
												{multiProbeInfo && (
													<p>{multiProbeInfo}</p>
												)}
												<p>
													<em>
														{
															probe.probe_info
																.location
														}
													</em>
												</p>
												<p>
													Status:
													<em>
														{" " +
															statusMessage(
																probe
															)}
													</em>
												</p>
												<div>
													Harmonogramy:
													{probe.schedules ? (
														probe.schedules
															.split(",")
															.map(
																(
																	schedule,
																	index
																) => (
																	<p
																		key={
																			index
																		}>
																		{
																			schedule
																		}
																	</p>
																)
															)
													) : (
														<span> —</span>
													)}
												</div>

												<p>
													Ostatni odczyt:{" "}
													<em>
														{
															probe.probe_info
																.last_login
														}
													</em>
												</p>
											</RPopup>
									</RFeature>
								);
							})}
					</RLayerVector>
					<RInteraction.RDragBox
						condition={shiftKeyOnly}
						onBoxStart={handleBoxStart}
						onBoxEnd={handleBoxEnd}
					/>

					<RLayerVector
						zIndex={1}
						visible={arePipesVisible}
						key={mapKey}
						properties={{ label: "Rury" }}>
						{drawings
							.filter((drawing) => drawing.type === "LineString")
							.map((lineString) => (
								<RFeature
									key={lineString.uid}
									geometry={
										new LineString(
											JSON.parse(lineString.coords)
										)
									}>
									<RStyle>
										<RStroke color={theme.pipe} width={3} />
									</RStyle>
								</RFeature>
							))}
					</RLayerVector>
					<RLayerVector
						zIndex={2}
						visible={areNodesVisible}
						properties={{ label: "Węzły" }}>
						{drawings
							.filter((drawing) => drawing.type === "Point")
							.map((point, index) => {
								const node = nodesList.find(
									(node) => node.node_id === point.uid
								);
								return (
									<RFeature
										onClick={() => handleNodeClicked(node)}
										key={index}
										geometry={
											new Point(JSON.parse(point.coords))
										}>
										<RStyle>
											<RIcon
												scale={calculateIconScale(
													1,
													zoomLevel
												)}
												src={locationNodeIcon}
												anchor={[0.5, 0.7]}
											/>
										</RStyle>
										<RPopup
											trigger={"hover"}
											className="map-popup">
											<p>
												<strong>
													Nazwa:{" "}
													{node
														? node.node_name
														: "Unknown"}
												</strong>
											</p>
											<p>
												<em>
													Przepustowość:{" "}
													{node
														? node.throughput
														: "Unknown"}{" "}
													m³/h
												</em>
											</p>
											<p>
												<em>
													Średnica:{" "}
													{node
														? node.diameter
														: "Unknown"}{" "}
													cm
												</em>
											</p>
										</RPopup>
									</RFeature>
								);
							})}
					</RLayerVector>
					<RLayerVector
						zIndex={1}
						visible={areGatewaysVisible}
						properties={{ label: "Gateways" }}>
						{gateways.map((gateway) => {
							return (
								<RFeature
									onClick={() =>
										handleGatewayClick(gateway.gateway_name)
									}
									key={`${gateway.gateway_name}-${disabledGateways[gateway.gateway_name] ? 'disabled' : 'enabled'}`}
									geometry={
										new Point(
											fromLonLat([
												gateway.lon,
												gateway.lat,
											])
										)
									}>
									<RStyle>
										<RIcon
											scale={calculateIconScale(
												1,
												zoomLevel
											)}
											src={locationGatewayIcon}
											anchor={[0.5, 0.5]}
											color={disabledGateways[gateway.gateway_name] ? "darkslategray" : "white"}
										/>
									</RStyle>
									<RPopup
										trigger={"hover"}
										className="map-popup">
										<p>
											<strong>Gateway</strong>
										</p>
										<p>
											<em>
												Nazwa:{" "}
												{gateway
													? gateway.gateway_name
													: "Unknown"}
											</em>
										</p>
										<p>
											<em>
												Podłączone :{" "}
												{gateway
													? gateway.connected
													: "Unknown"}
											</em>
										</p>
									</RPopup>
								</RFeature>
							);
						})}
					</RLayerVector>
					<RLayerVector
						zIndex={0}
						visible={areGatewaysVisible}
						properties={{ label: "Gateways" }}>
						{gatewayNetwork
							.filter(
								(gatewayNet) =>
									gatewayNet.project === selectedRegion.label
							)
							.filter(
								(gatewayNet) =>
									!disabledGateways[gatewayNet.gateway_name]
							)
							.map((gatewayNet, index) => {
								const matchingGateway = gateways.find(
									(gateway) =>
										gateway.gateway_name ===
										gatewayNet.gateway_name
								);

								const gatewayLon = matchingGateway
									? matchingGateway.lon
									: gatewayNet.gateway_lon;
								const gatewayLat = matchingGateway
									? matchingGateway.lat
									: gatewayNet.gateway_lat;

								return (
									<RFeature
										key={index}
										geometry={
											new LineString([
												fromLonLat([
													gatewayLon,
													gatewayLat,
												]),
												fromLonLat([
													gatewayNet.latitude,
													gatewayNet.longitude,
												]),
											])
										}>
										<RStyle>
											<RStroke
												color={theme.gatewayline}
												width={0.5}
											/>
										</RStyle>
									</RFeature>
								);
							})}
					</RLayerVector>
				</RMap>
			</div>
			{isNodeModalIsOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Węzeł: {clickedNode.node_name}</h2>
						<div>
							<p style={{ marginBottom: "10px" }}>
								Przepustowość: {clickedNode.throughput}m³/h
							</p>
							<p>Średnica: {clickedNode.diameter}mm</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => setIsConfirmModalOpen(true)}>
								Płukanie rurociągu
							</Button>
						</div>
					</div>
				</div>
			)}
			{isConfirmModalOpen && (
				<div
					onMouseDown={closeModal}
					className="modal-overlay"
					style={{ zIndex: "16" }}>
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz włączyć płukanie rurociągu?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => cleanPipelines()}>
								Potwierdź
							</Button>
						</div>
					</div>
				</div>
			)}
			{isSelectedProbesModalOpen && (
				<div
					onMouseDown={closeModal}
					className="modal-overlay"
					style={{ zIndex: "16" }}>
					<div
						onMouseDown={(e) => e.stopPropagation()}
						className="modal-content"
						ref={modalContentRef}>
						<div className="probe-list">
							<table>
								<thead>
									<tr>
										<th>ID</th>
										<th>Opis</th>
										<th>Lokalizacja</th>
									</tr>
								</thead>
								<tbody>
									{mapProbes
										.filter((probe) =>
											selectedProbes.includes(
												probe.probe_id
											)
										)
										.map((filteredProbe) => (
											<tr key={filteredProbe.probe_id}>
												<td>
													{filteredProbe.probe_id}
												</td>
												<td>
													{filteredProbe.probe_info
														.probe_description ||
														"Brak"}
												</td>
												<td>
													{filteredProbe.probe_info
														.location || "Brak"}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button className="add" onClick={lockAllSelected}>
								Zablokuj wszystkie
							</Button>
							<Button className="add" onClick={unlockAllSelected}>
								Odblokuj wszystkie
							</Button>
						</div>
					</div>
				</div>
			)}
		</PipelinemapLayout>
	);
};

export default Pipelinemap;
