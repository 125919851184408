import DataTable from "react-data-table-component";
import styled from "styled-components";

export const StyledDataTable = styled(DataTable)`
	overflow: auto;
	

	.rdt_Table {
		background-color: ${(props) => props.theme.white};
	}
	.rdt_TableCell {
		color: ${(props) => props.theme.tablebodyfontcolor};
		background-color: ${(props) => props.theme.secondary};
		border: 1px solid ${(props) => props.theme.white};
		font-size: 14px;
		color: ${(props) => props.theme.fontcolor};
	}

	.rdt_TableRow {
		border: none;
		background-color: transparent;
	}

	.rdt_TableCol {
		background-color: ${(props) => props.theme.primary};
		border: 1px solid ${(props) => props.theme.white};
		color: ${(props) => props.theme.tableheadfontcolor};
		font-size: 16px;
		font-weight: 700;
		justify-content: center;
	}

	.rdt_TableHeadRow {
		border: none;
		background-color: ${(props) => props.theme.white};

		.rdt_TableCol:first-child {
			border-top-left-radius: 5px;
		}

		.rdt_TableCol:last-child {
			border-top-right-radius: 5px;
		}
	}

	.rdt_TableBody {
		background-color: ${(props) => props.theme.white};
		.rdt_TableRow:last-child {
			background-color: ${(props) => props.theme.white};
			.rdt_TableCell:first-child {
				border-bottom-left-radius: 5px;
			}

			.rdt_TableCell:last-child {
				border-bottom-right-radius: 5px;
			}
		}
	}

	.rdt_ExpanderRow{
		background-color: ${(props) => props.theme.white};
	}

	.bEMRin,.cKXTJo {
		background-color: ${(props) => props.theme.white} !important;
		color: ${(props) => props.theme.fontcolor};
	}
`;
