import Select from "react-select";
import * as XLSX from "xlsx";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import React, { useState, useEffect } from "react";
import { ReportsLayout } from "../Reports.style";
import useAccessControl from "../../../hooks/useAccessControl";
import { Button } from "../../../components/Button/Button";
import { StyledDataTable } from "../../../components/Table/Table.styles";
import { sendRequest } from "../../../api/sendRequest";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import ReactECharts from "echarts-for-react";
import { ReactComponent as IcoArrowDown } from "./../../../svg/ico-w-dol.svg";
import LoadingComponent from "../../../components/Table/LoadingComponent";
import { useStyledSelect } from "../../../components/Select/StyledSelect";
import { TailSpin } from "react-loader-spinner";

const ReportsAlarms = () => {
	const customStyles = useStyledSelect();
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const params = new URLSearchParams(location.search);
	const probeId = params.get("probe_id");
	const isAccessVerified = useAccessControl();
	const monthAgo = new Date(new Date().setDate(new Date().getDate() - 30));
	const today = new Date();
	const [datePickerValue, setDatePickerValue] = useState([monthAgo, today]);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [probesAll, setProbesAll] = useState([]);
	const [selectedProbe, setSelectedProbe] = useState(
		probeId ? [probeId] : []
	);
	const [isChartLoading, setIsChartLoading] = useState(true);
	const [showChart, setShowCharts] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState("2");
	const [sortDirection, setSortDirection] = useState("desc");
	const [searchValue, setSearchValue] = useState("");
	const [probeAlarmschartOptions, setProbeAlarmsChartOptions] =
		useState(null);
	const dictionary = JSON.parse(localStorage.getItem("dictionary"));

	const fetchData = async (
		page,
		size = perPage,
		column = sortColumn,
		direction = sortDirection,
		newDates = datePickerValue,
		currentSelectedProbe = selectedProbe,
		search
	) => {
		setLoading(true)
		const startString = formatDate(newDates[0]) + " 00:00";
		const endString = formatDate(newDates[1]) + " 23:59";
		const formattedDate = `${startString}-${endString}`;

		const getAlarmsStatistics = {
			action: "get-probe-pumps-alarm-log",
			_data_: {
				page,
				perPage: size,
				date: formattedDate,
				sortColumn: column,
				sortDirection: direction,
				probe_id: currentSelectedProbe,
				search: search,
			},
		};

		const getProbesAll = {
			action: "get-probes-all",
			nodes: false,
		};

		try {
			const pumpsStatisticsResponse = await sendRequest(
				getAlarmsStatistics
			);
			const probesAllResponse = await sendRequest(getProbesAll);
			setProbesAll(
				probesAllResponse.data._data_.map((probe) => ({
					label:
						probe.location !== "undefined"
							? `${probe.location} (${probe.probe_name})`
							: `${probe.probe_description} (${probe.probe_name})`,
					value: probe.probe_id,
				}))
			);

			setTotalRows(pumpsStatisticsResponse.data._data_.rows);
			setData(
				pumpsStatisticsResponse.data._data_.results.map(
					({ total_rows, ...row }) => ({
						...row,
						error1: cleanData(row.error1, "error1"),
						error2: cleanData(row.error2, "error2"),
						io: cleanData(row.io, "io"),
						ls: cleanData(row.ls, "ls"),
					})
				)
			);
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const fetchDataProbeAlarms = async (
		currentSelectedProbe = selectedProbe
	) => {
		setIsChartLoading(true);
		const startString = formatDate(datePickerValue[0]) + " 00:00";
		const endString = formatDate(datePickerValue[1]) + " 23:59";
		const formattedDate = startString + "-" + endString;
		let requestPayload = {};

		if (currentSelectedProbe && currentSelectedProbe.length) {
			requestPayload = {
				action: "get-probe-alarm-history",
				probe_id: currentSelectedProbe,
				period: formattedDate,
			};
		} else {
			requestPayload = {
				action: "get-probes-alarm-history",
				period: formattedDate,
			};
		}

		try {
			const probeAlarmsHistoryResponse = await sendRequest(
				requestPayload
			);
			const data = probeAlarmsHistoryResponse.data._data_;
			const timeStamps = data.map((item) => {
				return item.time_stamp.substring(0, item.time_stamp.lastIndexOf(':'));
			  })

			const pumpErr = data.map((item) => item.pump_err);
			const pumpDryRun = data.map((item) => item.pump_dry_run);
			const pumpDryErr = data.map((item) => item.pump_dry_err);
			const pumpCallService = data.map((item) => item.pump_call_service);
			const pumpAlarmLevel = data.map((item) => item.pump_alarm_level);

			const formattedTimeStamps = timeStamps.map((timestamp) => {
				const [date, time] = timestamp.split(' ');
				return `${time}\n${date}`;
			  });

			const probeAlarmsHistoryOptions = {
				animation: true,
				grid: { top: 30, right: 60, bottom: 40, left: 60 },
				xAxis: {
					type: 'category',
					data: formattedTimeStamps,
					axisLabel: {
					  formatter: (value) => {
						return `{value|${value.split('\n')[0]}}\n{value|${value.split('\n')[1]}}`;
					  },
					  rich: {
						value: {
						  lineHeight: 15,
						  align: 'center'
						}
					  }
					}
				  },
				yAxis: {
					type: "value",
				},
				series: [
					{
						name: "Wezwij technika",
						data: pumpCallService,
						type: "bar",
						stack: "stack1",
						color: theme.danger,
					},
					{
						name: "Błąd suchobiegu",
						data: pumpDryErr,
						type: "bar",
						stack: "stack1",
						color: theme.gray,
					},
					{
						name: "Suchobieg",
						data: pumpDryRun,
						type: "bar",
						stack: "stack1",
						color: theme.standby,
					},
					{
						name: "Błąd pompowni",
						data: pumpErr,
						type: "bar",
						stack: "stack1",
						color: "#fffc43",
					},
					{
						name: "Poziom alarmowy",
						data: pumpAlarmLevel,
						type: "bar",
						stack: "stack1",
						color: "#ff7f43",
					},
				],
				tooltip: {
					trigger: "axis",
				},
				dataZoom: [
					{
						type: "inside",
						xAxisIndex: [0],
						filterMode: "weakFilter",
					},
				],
				legend: {
					data: [
						"Wezwij technika",
						"Błąd suchobiegu",
						"Suchobieg",
						"Błąd pompowni",
						"Poziom alarmowy",
					],
					orient: "horizontal",
					top: "top",
					left: "center",
					textStyle: {
						color: theme.fontcolor,
					},
				},
			};

			setProbeAlarmsChartOptions(probeAlarmsHistoryOptions);
			setIsChartLoading(false);
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		if (probeId) {
			setSelectedProbe([probeId]);
			fetchData(1, undefined, undefined, undefined, undefined, [probeId]);
			fetchDataProbeAlarms();
		} else {
			fetchData(1);
			fetchDataProbeAlarms();
		}
	}, []);


	const cleanData = (str, dictionaryKey) => {
		str = str.replace(/^;+|;+$/g, "").replace(/;+/g, ";");
		const lines = str
			.split(";")
			.filter(
				(line) =>
					line.trim() !== "" &&
					line !== "RESERVED" &&
					line !== "EMPTY"
			);
		return lines
			.map((val) => {
				const trimmedVal = val.trim();
				return (
					dictionary?.event_log?.[dictionaryKey]?.[trimmedVal] ||
					trimmedVal
				);
			})
			.join("\n");
	};

	const exportToExcel = async () => {
		const startString = formatDate(datePickerValue[0]) + " 00:00";
		const endString = formatDate(datePickerValue[1]) + " 23:59";
		const formattedDate = `${startString}-${endString}`;

		const getPumpsStatistics = {
			action: "get-probe-pumps-alarm-log",
			_data_: {
				page: 1,
				perPage: 1000,
				date: formattedDate,
				sortColumn: "0",
				sortDirection: "asc",
				probe_id: [],
				search: "",
			},
		};

		const response = await sendRequest(getPumpsStatistics);
		const cleanedData = response.data._data_.results.map((record) => {
			const { total_rows, ...rest } = record;
			return rest;
		});

		const ws = XLSX.utils.json_to_sheet(cleanedData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "reportAlarms.xlsx");
	};

	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");

		return `${year}/${month}/${day}`;
	};

	const handleDateChange = (value) => {
		setDatePickerValue(value);
		fetchData(
			currentPage,
			perPage,
			sortColumn,
			sortDirection,
			value,
			selectedProbe,
			searchValue
		);
	};

	const handlePageChange = (page) => {
		fetchData(
			page,
			perPage,
			sortColumn,
			sortDirection,
			datePickerValue,
			selectedProbe,
			searchValue
		);
		setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchData(
			page,
			newPerPage,
			sortColumn,
			sortDirection,
			datePickerValue,
			selectedProbe,
			searchValue
		);
		setPerPage(newPerPage);
	};

	const handleSort = async (column, sortDirection) => {
		setSortDirection(sortDirection);
		setSortColumn(column.sortField);
		fetchData(
			currentPage,
			perPage,
			column.sortField,
			sortDirection,
			datePickerValue,
			selectedProbe,
			searchValue
		);
	};
	const handleSelectProbe = (selectedOptions) => {
		const probeValues = selectedOptions
			? selectedOptions.map((option) => option.value)
			: [];
		setSelectedProbe(probeValues);
		fetchData(
			currentPage,
			perPage,
			sortColumn,
			sortDirection,
			datePickerValue,
			probeValues,
			searchValue
		);
	};

	const handleSearchChange = (e) => {
		setSearchValue(e.target.value);
		setCurrentPage(1)
	};

	useEffect(() => {
		const debounceTimeout = setTimeout(() => {
			fetchData(
				currentPage,
				perPage,
				sortColumn,
				sortDirection,
				datePickerValue,
				selectedProbe,
				searchValue
			);
		}, 500);

		return () => {
			clearTimeout(debounceTimeout);
		};
	}, [searchValue]);

	const columns = [
		{
			name: "Nazwa pompowni",
			selector: (row) => row.description || row.probe_id,
			grow: 2,
			sortable: true,
			sortField: "0",
		},
		{
			name: "Adres",
			selector: (row) => row.location,
			grow: 2,
			sortable: true,
			sortField: "1",
		},
		{
			name: "Data Wystąpienia",
			selector: (row) => row.ctime,
			grow: 2,
			sortable: true,
			sortField: "2",
		},
		{
			name: "Zegar wewnętrzny KMS",
			selector: (row) => row.event_cputime,
			grow: 1,
			sortable: true,
			sortField: "3",
		},
		{
			name: "Błędy Wejścia/Wyjścia",
			selector: (row) => row.io,
			grow: 2,
			sortable: true,
			sortField: "4",
			cell: (row) => (
				<div style={{ whiteSpace: "pre-line", padding: "10px 0px" }}>
					{row.io}
				</div>
			),
		},
		{
			name: "Błędy Pływaka",
			selector: (row) => row.ls,
			grow: 2,
			sortable: true,
			sortField: "5",
			cell: (row) => (
				<div style={{ whiteSpace: "pre-line", padding: "10px 0px" }}>
					{row.ls}
				</div>
			),
		},
		{
			name: "Błędy grupa 1",
			selector: (row) => row.error1,
			grow: 2,
			sortable: true,
			sortField: "6",
			cell: (row) => (
				<div style={{ whiteSpace: "pre-line", padding: "10px 0px" }}>
					{row.error1}
				</div>
			),
		},
		{
			name: "Błędy grupa 2",
			selector: (row) => row.error2,
			grow: 2,
			sortable: true,
			sortField: "7",
			cell: (row) => (
				<div style={{ whiteSpace: "pre-line", padding: "10px 0px" }}>
					{row.error2}
				</div>
			),
		},
	];

	if (!isAccessVerified) {
		return null;
	}

	return (
		<ReportsLayout>
			<h2 className="page-title">Raporty Alarmów</h2>
			<div className="table-container">
				<div
					onClick={() => setShowCharts(!showChart)}
					className="advanced-button">
					<IcoArrowDown width={30} />
					{showChart ? "Tabela" : "Wykres"}
				</div>
				{showChart ? (
					<div className="chart-container">
						<h2>Historia Alarmów Pompowni</h2>
						{isChartLoading ? (
							<div className="loading">
								<TailSpin
									height="100"
									width="100"
									color={theme.primary}
									ariaLabel="tail-spin-loading"
									radius="1"
								/>
							</div>
						) : (
							<ReactECharts
								option={probeAlarmschartOptions}
								style={{ width: "100%", height: "90%" }}
							/>
						)}
					</div>
				) : (
					<StyledDataTable
						paginationComponentOptions={{
							rowsPerPageText: "Wiersze na stronę:",
						}}
						columns={columns}
						data={data}
						progressPending={loading}
						noDataComponent="Brak danych spełniających kryteria"
						progressComponent={<LoadingComponent />}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						paginationDefaultPage={currentPage}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						onSort={handleSort}
						paginationRowsPerPageOptions={[
							10, 15, 20, 30, 50, 100, 150, 250, 500,
						]}
						sortServer
						onRowClicked={(row) => {
							navigate(`/details/${row.probe_id}`);
						}}
					/>
				)}
				<div>
					<input
						className="search-input"
						placeholder="Szukaj..."
						onChange={handleSearchChange}
						value={searchValue}
					/>
				</div>
				<div className="datepicker-container">
					<DateRangePicker
						onChange={handleDateChange}
						value={datePickerValue}
						clearIcon={null}
						maxDate={new Date()}
					/>
					<Select
						placeholder="Wybierz pompownie..."
						styles={customStyles}
						className="select"
						isMulti
						options={probesAll}
						value={probesAll.filter((opt) =>
							selectedProbe.includes(opt.value)
						)}
						onChange={handleSelectProbe}
					/>
					{!showChart && (
						<Button
							style={{ padding: "5px 40px" }}
							className="button"
							onClick={exportToExcel}>
							Eksportuj do Excela
						</Button>
					)}
				</div>
			</div>
		</ReportsLayout>
	);
};

export default ReportsAlarms;
