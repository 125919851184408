import { useState, useRef, useEffect } from "react";
import { PipelinemapLayout } from "../Pipelinemap.styles";
import { sendRequest } from "../../../api/sendRequest";
import { Button } from "../../../components/Button/Button";
import { pumpRequest } from "../../../api/pumpRequest";
import { StyledDataTable } from "../../../components/Table/Table.styles";
import ExpandedComponent from "../../../components/Pipelinemap/ExpandedComponent";
import { ReactComponent as IcoArrowDown } from "./../../../svg/ico-w-dol.svg";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/Table/LoadingComponent";

const PipelinemapNodes = () => {
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState("2");
	const [sortDirection, setSortDirection] = useState("desc");
	const [searchValue, setSearchValue] = useState("");
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [clickedNode, setClickedNode] = useState([]);
	const [isNodeModalIsOpen, setIsNodeModalIsOpen] = useState(false);
	const [info, setInfo] = useState("");
	const modalContentRef = useRef(null);
	const navigate = useNavigate();

	const fetchNodesData = async (
		page,
		size = perPage,
		column = sortColumn,
		direction = sortDirection,
		search
	) => {
		setLoading(true)
		const getNodesList = {
			action: "get-nodes-list",
			_data_: {
				page,
				perPage: size,
				sortColumn: column,
				sortDirection: direction,
				search: search,
			},
		};

		try {
			const nodesListResponse = await sendRequest(getNodesList);
			setTotalRows(nodesListResponse.data._data_.rows);
			setData(nodesListResponse.data._data_.results);
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchNodesData();
	}, []);

	const handleSearchChange = (e) => {
		setSearchValue(e.target.value);
		setCurrentPage(1)
		fetchNodesData(
			currentPage,
			perPage,
			sortColumn,
			sortDirection,
			e.target.value
		);
	};

	const handlePageChange = (page) => {
		fetchNodesData(page, perPage, sortColumn, sortDirection);
		setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchNodesData(page, newPerPage, sortColumn, sortDirection);
		setPerPage(newPerPage);
	};

	const handleSort = async (column, sortDirection) => {
		setSortDirection(sortDirection);
		setSortColumn(column.sortField);
		fetchNodesData(
			currentPage,
			perPage,
			column.sortField,
			sortDirection,
			searchValue
		);
	};

	const handleNodeClicked = (node) => {
		setIsNodeModalIsOpen(true);
		setClickedNode(node);
	};

	const closeModal = () => {
		setIsNodeModalIsOpen(false);
		setIsConfirmModalOpen(false);
		setClickedNode([]);
	};

	const cleanPipelines = async () => {
		const getFeaturesLineString = {
			action: "pump-clean-pipeline",
			node_id: clickedNode.node_id,
		};

		try {
			await pumpRequest(getFeaturesLineString);
			setInfo("Wysłano żądanie płukania rurociągów.");
			closeModal();
		} catch (error) {
			console.error(error);
		}
	};

	const columns = [
		{
			name: "Nazwa węzła",
			selector: (row) => row.node_name,
			grow: 1,
			sortable: true,
			sortField: "0",
		},
		{
			name: "Ilość podłączonych pompowni",
			selector: (row) => row.probes,
			grow: 1,
			sortable: true,
			sortField: "1",
		},
		{
			name: "Średnica (cm)",
			selector: (row) => row.diameter,
			grow: 1,
			sortable: true,
			sortField: "2",
		},
		{
			name: "Przepustowość (m³/h)",
			selector: (row) => row.throughput,
			grow: 1,
			sortable: true,
			sortField: "3",
		},
		{
			name: "Akcje",
			cell: (row) => (
				<Button
					onClick={() => handleNodeClicked(row)}
					style={{ padding: "5px 40px" }}>
					Płukanie
				</Button>
			),
			style: { justifyContent: "center" },
			grow: 1,
		},
	];

	return (
		<PipelinemapLayout>
			<h2 className="page-title">Tabela węzłów sieci</h2>
			<div
				onClick={() => navigate(`/pipelinemap`)}
				className="advanced-button">
				<IcoArrowDown width={30} />
				Mapa
			</div>
			<div className="table-container">
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: "Wiersze na stronę:",
					}}
					data={data}
					columns={columns}
					progressPending={loading}
					noDataComponent="Brak danych spełniających kryteria"
					progressComponent={<LoadingComponent />}
					pagination
					paginationServer
					paginationTotalRows={totalRows}
					paginationDefaultPage={currentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					expandableRows
					expandableRowsComponent={({ data }) => (
						<ExpandedComponent rowId={data.node_id} />
					)}
					onChangePage={handlePageChange}
					onSort={handleSort}
					sortServer
				/>
				<input
					className="search-input"
					placeholder="Szukaj"
					onKeyUp={handleSearchChange}
				/>
			</div>
			{isNodeModalIsOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Węzeł: {clickedNode.node_name}</h2>
						<div>
							<p style={{ marginBottom: "10px" }}>
								Przepustowość: {clickedNode.throughput}m³/h
							</p>
							<p>Średnica: {clickedNode.diameter}mm</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => setIsConfirmModalOpen(true)}>
								Płukanie rurociągu
							</Button>
						</div>
					</div>
				</div>
			)}
			{isConfirmModalOpen && (
				<div
					onMouseDown={closeModal}
					className="modal-overlay"
					style={{ zIndex: "16" }}>
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz włączyć płukanie rurociągu?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => cleanPipelines()}>
								Potwierdź
							</Button>
						</div>
					</div>
				</div>
			)}
		</PipelinemapLayout>
	);
};

export default PipelinemapNodes;
