import styled from "styled-components";

const successSVG =
	"data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIzMnB4IiBpZD0iTGF5ZXJfMSIgZmlsbD0iIzJiYzAzZiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgMzI7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0xNiwxQzcuNzE2LDEsMSw3LjcxNiwxLDE2YzAsOC4yODQsNi43MTYsMTUsMTUsMTVzMTUtNi43MTYsMTUtMTVDMzEsNy43MTYsMjQuMjg0LDEsMTYsMXogTTI1LjQxNCwxMi40MTRsLTEwLDEwICBDMTUuMDI0LDIyLjgwNSwxNC41MTIsMjMsMTQsMjNzLTEuMDI0LTAuMTk1LTEuNDE0LTAuNTg2bC01LTVDNy4yNDIsMTcuMDcsNywxNi41OTQsNywxNmMwLTEuMDk0LDAuOTAyLTIsMi0yICBjMC41MTIsMCwxLjAyNCwwLjE5NSwxLjQxNCwwLjU4NkwxNCwxOC4xNzJsOC41ODYtOC41ODZDMjIuOTc2LDkuMTk1LDIzLjQ4OCw5LDI0LDljMS4wOTgsMCwyLDAuOTA2LDIsMiAgQzI2LDExLjU5NCwyNS43NTgsMTIuMDcsMjUuNDE0LDEyLjQxNHoiLz48L3N2Zz4=";

const errorSVG =
	"data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIzMnB4IiBpZD0iTGF5ZXJfMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgMzI7IiB2ZXJzaW9uPSIxLjEiIGZpbGw9IiNlZTQyNDIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0xNiwxQzcuNzE2LDEsMSw3LjcxNiwxLDE2YzAsOC4yODQsNi43MTYsMTUsMTUsMTVzMTUtNi43MTYsMTUtMTVDMzEsNy43MTYsMjQuMjg0LDEsMTYsMXogTTE2LDI2Yy0xLjEwNSwwLTItMC44OTUtMi0yICBjMC0xLjEwNSwwLjg5NS0yLDItMnMyLDAuODk1LDIsMkMxOCwyNS4xMDUsMTcuMTA1LDI2LDE2LDI2eiBNMTgsMThjMCwxLjEwNS0wLjg5NSwyLTIsMnMtMi0wLjg5NS0yLTJWOGMwLTEuMTA1LDAuODk1LTIsMi0yICBzMiwwLjg5NSwyLDJWMTh6Ii8+PC9zdmc+";


export const Button = styled.button`
	position: relative;
	display: inline-block;
	padding: 10px 40px;
	background-color: ${(props) => props.theme.primary};
	color: #fff;
	border: none;
	border-radius: 5px;
	text-align: center;
	text-decoration: none;
	font-weight: 700;
	font-size: 16px;
	font-family: "Overpass", sans-serif;
	cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	:hover {
		background-color: ${(props) => props.theme.darkerprimary};
	}

	&.success::before {
		content: "";
		display: block;
		position: absolute;
		top: 10px;
		right: 10px;
		width: 20px;
		height: 20px;
		background-image: url(${successSVG});
		background-size: contain;
		background-repeat: no-repeat;
	}

	&.error::before {
		content: "";
		display: block;
		position: absolute;
		top: 10px;
		right: 10px;
		width: 20px;
		height: 20px;
		background-image: url(${errorSVG});
		background-size: contain;
		background-repeat: no-repeat;
	}
`;
