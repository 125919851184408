import styled from "styled-components";

export const SettingsLayout = styled.div`
	margin: 100px 0 50px 0;

	.table-container {
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;

		.rdt_Table {
			background-color: ${(props) => props.theme.white};
		}
	}

	.action-button {
		width: 222px;
		margin-bottom: 20px;
	}

	.rdt_Table {
		padding-top: 56px;
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}

	input {
		padding: 11px 10px;
		width: 100%;
		border: none;
		outline: none;
		border-radius: 5px;
		color: ${(props) => props.theme.fontcolor};
		background-color: ${(props) => props.theme.white};
	}
`;
