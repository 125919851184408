import styled from "styled-components";

export const ReportsLayout = styled.div`
	margin: 100px 0 50px 0;

	.chart-container {
		text-align: center;
		padding-top: 15px;
		height: 45vh;
		margin-top: 25px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
	}

	.table-container {
		position: relative;
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;

		.advanced-button {
			position: absolute;
			top: -35px;
			right: 0;
			padding: 5px 15px;
			min-width: 120px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			background-color: ${(props) => props.theme.white};
			border-radius: 5px;
			cursor: pointer;
			border-radius: 5px;
			z-index: 2;
		}
	}

	.search{
		margin-left: 10px;
		padding: 5px 10px;
		color: ${(props) => props.theme.fontcolor};
		background-color: ${(props) => props.theme.secondary};
		border-radius: 5px;
		border: none;
		outline: none;
	}

	.search-input {
		margin-right: 15px;
		margin-bottom: 20px;
		padding: 5px 10px;
		width: 51%;
		border: none;
		outline: none;
		border-radius: 5px;
		color: ${(props) => props.theme.fontcolor};
		background-color: ${(props) => props.theme.secondary};
	}
	.datepicker-container {
		display: flex;
		margin-bottom: 20px;

		.select {
			width: 35%;
		}

		.select-region{
			margin-left: 10px;
			min-width: 140px;
		}

		.button {
			margin-left: auto;
		}
	}

	.react-daterange-picker {
		margin-right: 15px;
	}
	.react-daterange-picker__wrapper {
		border-radius: 5px;
	}

	.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	@media (max-width: 425px) {
		.datepicker-container {
			display: flex;
			flex-direction: column-reverse;
			gap: 10px;

			.button {
				margin-left: 0;
			}

			.select {
				width: auto;
			}
			.react-daterange-picker {
				margin-right: 0;
				width: 226px;
			}
		}
	}
`;
