import styled from "styled-components";

export const StyledExpandedComponent = styled.table`
  width: 96.8%;
  border-collapse: collapse;
  margin-top: 7px;
  margin-left: auto;
  color: ${(props) => props.theme.fontcolor};

  thead {
    background-color: ${(props) => props.theme.expandedTheadBackgroundColor};

    th {
      padding: 10px 15px;
      border: 1px solid ${(props) => props.theme.expandedTableBackgroundColor};
      text-align: left;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 25%;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 25%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 25%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 12.5%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 12.5%;
    }
  }

  tbody {
    cursor: pointer;
    tr {
      &:nth-child(odd) {
        background-color: ${(props) => props.theme.expandedOddRowBackgroundColor};
      }

      &:hover {
        background-color: ${(props) => props.theme.expandedHoverRowBackgroundColor};
      }
    }

    td {
      padding: 8px 15px;
      border: 1px solid ${(props) => props.theme.expandedTableBackgroundColor};
    }
  }
`;

