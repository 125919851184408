import styled from "styled-components";

export const LogsLayout = styled.div`
	margin: 100px 0 50px 0;

	.table-container {
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
	}
	.datepicker-container {
		margin-bottom: 20px;

		.search-input {
			margin-right: 15px;
			padding: 13px 10px;
			border: none;
			outline: none;
			border-radius: 5px;
			color: ${(props) => props.theme.fontcolor};
			background-color: ${(props) => props.theme.secondary};
		}
	}

	.react-daterange-picker {
		margin-right: 15px;
	}
	.react-daterange-picker__wrapper {
		border-radius: 5px;
	}
`;
