import React, { useState, useRef, useEffect } from "react";
import { StyledDataTable } from "../../components/Table/Table.styles";
import { UsersLayout } from "./Users.style";
import { Button } from "../../components/Button/Button";
import Select from "react-select";
import useAccessControl from "../../hooks/useAccessControl";
import { ReactComponent as Icoedytuj } from "./../../svg/ico-edytuj.svg";
import { ReactComponent as Icozamknij } from "./../../svg/ico-zamknij.svg";
import { sendRequest } from "../../api/sendRequest";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import LoadingComponent from "../../components/Table/LoadingComponent";
import { useStyledSelect } from "../../components/Select/StyledSelect";

const Users = () => {
	const customStyles = useStyledSelect()
	const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
	const modalContentRef = useRef(null);
	const [userId, setUserId] = useState(null);
	const [login, setLogin] = useState("");
	const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");
	const [mail, setMail] = useState("");
	const [selectedRegions, setSelectedRegions] = useState(null);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [descp, setDescp] = useState("");
	const [loginError, setLoginError] = useState("");
	const [password1Error, setPassword1Error] = useState("");
	const [password2Error, setPassword2Error] = useState("");
	const [mailError, setMailError] = useState("");
	const [selectedRegionsError, setSelectedRegionsError] = useState("");
	const [selectedGroupError, setSelectedGroupError] = useState("");
	const [selectedProbes, setSelectedProbes] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const [userData, setUserData] = useState([]);
	const [probesAll, setprobesAll] = useState([]);
	const [userRegions, setUserRegions] = useState([]);
	const [userGroups, setUserGroups] = useState([]);
	const [deletingUser, setDeletingUser] = useState("");
	const [deletingUserId, setDeletingUserId] = useState(null);
	const [confirmedDeletingUser, setConfirmedDeletingUser] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [msg, setMsg] = useState("");
	const isVerified = useAccessControl();
	const token = localStorage.getItem("token");

	const fetchData = async () => {
		if (isVerified.isAdmin) {
			try {
				const getProbesAllRequest = {
					action: "get-probes-all",
					nodes: false,
				};
				const getUsersRequest = { action: "get-users" };
				const getGroupsRequest = { action: "get-groups" };
				const getRegionsRequest = { action: "get-regions" };

				const [
					usersResponse,
					groupsResponse,
					regionsResponse,
					probesAllResponse,
				] = await Promise.all([
					sendRequest(getUsersRequest),
					sendRequest(getGroupsRequest),
					sendRequest(getRegionsRequest),
					sendRequest(getProbesAllRequest),
				]);

				setprobesAll(
					probesAllResponse.data._data_.map((probe) => ({
						value: probe.probe_id,
						label: probe.location !== "undefined" ? `${probe.location} (${probe.probe_name})` : `${probe.probe_description} (${probe.probe_name})`,
					}))
				);
				setUserData(usersResponse.data._data_);
				setUserGroups(
					groupsResponse.data._data_.map((group) => ({
						value: group.id,
						label: group.name,
					}))
				);
				setUserRegions(
					regionsResponse.data._data_.map((region) => ({
						value: region.id,
						label: region.r_name,
					}))
				);
				setIsLoading(false);
			} catch (error) {
				console.error(error);
				setIsLoading(false);
			}
		} else {
			if (token) {
				try {
					const payload = token.split(".")[1];
					const decodedPayload = JSON.parse(atob(payload));
					setUserId(decodedPayload.uId);
					const getUserRequest = {
						action: "get-user",
						id: decodedPayload.uId,
					};
					const response = await sendRequest(getUserRequest);
					setUserData([response.data._data_]);
					setIsLoading(false);
				} catch (error) {
					console.error(error);
					setIsLoading(false);
				}
			}
		}
	};

	useEffect(() => {
		fetchData();
	}, [isVerified.isAdmin]);

	useEffect(() => {
		if (confirmedDeletingUser && deletingUserId) {
			const deleteUserRequest = {
				action: "delete-user",
				id: deletingUserId,
				confirmed: confirmedDeletingUser,
			};

			sendRequest(deleteUserRequest)
				.then((response) => {
					if (response.data.status === 1) {
						setMsg("Pomyślnie usunięto!")
						const getUsersRequest = {
							action: "get-users",
						};

						sendRequest(getUsersRequest)
							.then((usersResponse) => {
								setUserData(usersResponse.data._data_);
							})
							.catch((error) => {
								console.error(error);
							});
					} else {
						setMsg("Błąd usuwania!")
						console.error("Error deleting user:", response.data);
					}
				})
				.catch((error) => {
					console.error(error);
				});

			setConfirmedDeletingUser(false);
			setDeletingUserId(null);
			setIsDeleteUserModalOpen(false);
		}
	}, [confirmedDeletingUser, deletingUserId]);

	const validateUserData = () => {
		let isValid = true;

		if (!login) {
			setLoginError("Login jest wymagany");
			isValid = false;
		} else {
			setLoginError("");
		}

		if (!editMode) {
			if (!password1) {
				setPassword1Error("Hasło jest wymagane");
				isValid = false;
			} else {
				setPassword1Error("");
			}
		}

		if (password1 || password2) {
			if (password1 !== password2) {
				setPassword2Error("Hasła muszą być takie same");
				isValid = false;
			} else {
				setPassword2Error("");
			}
		}

		const validEmailRegex =
			/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!mail || !validEmailRegex.test(mail)) {
			setMailError("Podaj prawidłowy adres e-mail");
			isValid = false;
		} else {
			setMailError("");
		}

		if (!selectedRegions) {
			setSelectedRegionsError("Wybierz przynajmniej jeden region");
			isValid = false;
		} else {
			setSelectedRegionsError("");
		}

		if (!selectedGroup) {
			setSelectedGroupError("Wybór grupy jest wymagany");
			isValid = false;
		} else {
			setSelectedGroupError("");
		}

		return isValid;
	};
	const clearForm = () => {
		setLogin("");
		setPassword1("");
		setPassword2("");
		setMail("");
		setSelectedRegions(null);
		setSelectedGroup(null);
		setSelectedProbes([]);
		setDescp("");
		setIsActive(false);
	};

	const clearErrors = () => {
		setLoginError("");
		setPassword1Error("");
		setPassword2Error("");
		setMailError("");
		setSelectedRegionsError("");
		setSelectedGroupError("");
	};

	const handleAddUser = () => {
		const isValid = validateUserData();

		if (isValid) {
			const userData = {
				active: isActive ? 1 : 0,
				description: descp,
				email: mail,
				email_reports: 1,
				group_id: selectedGroup.value,
				password: password2,
				region_id: selectedRegions.map((region) => region.value),
				username: login,
				probe_id: selectedProbes.map((probe) => probe.value),
			};

			const addUserRequest = {
				action: "add-user",
				_data_: userData,
			};
			sendRequest(addUserRequest).then((response) => {
				if (response.data.status === 1) {
					closeModal();
					clearForm();

					const getUsersRequest = {
						action: "get-users",
					};

					sendRequest(getUsersRequest)
						.then((usersResponse) => {
							setUserData(usersResponse.data._data_);
							setMsg("Zapisano!")
						})
						.catch((error) => {
							console.error(error);
							setMsg("Błąd zapisu!")
						});
				} else {
					setLoginError(response.data._data_.username);
				}
			});
		}
	};

	const handleOpenModalEdit = (row) => {
		setMsg("")
		const getUserRequest = {
			action: "get-user",
			id: row.id,
		};
	
		sendRequest(getUserRequest)
			.then((response) => {
				setEditMode(true);
				setIsAddUserModalOpen(true);
				setUserId(response.data._data_.id);
				setLogin(response.data._data_.username);
				setMail(response.data._data_.email);
				setDescp(response.data._data_.description);
				setIsActive(response.data._data_.active === 1);
	
				if (response.data._data_.regions) {
					const userRegionsFiltered = userRegions.filter((region) =>
						response.data._data_.regions.includes(region.label)
					);
					setSelectedRegions(userRegionsFiltered);
				} else {
					setSelectedRegions([]);
				}
	
				let probesArray = [];
				if (
					response.data._data_.probes &&
					response.data._data_.probes.trim() !== ""
				) {
					probesArray = response.data._data_.probes.split(",");
					const userProbesFiltered = probesAll.filter((probe) =>
						probesArray.includes(probe.value.trim())
					);
					setSelectedProbes(userProbesFiltered);
				} else {
					setSelectedProbes([]);
				}
	
				setSelectedGroup(
					userGroups.find(
						(group) => group.value === response.data._data_.group_id
					)
				);
			})
			.catch((error) => {
				console.error(error);
			});
	};
	

	const handleOpenModalEditSingleUser = () => {
		setMsg("")
		setEditMode(true);
		setIsAddUserModalOpen(true);
		setLogin(userData[0].username);
		setMail(userData[0].email);
	};

	const handleEditSingleUser = () => {
		let isValid = true;
		if (!password1) {
			setPassword1Error("Hasło jest wymagane");
			isValid = false;
		} else {
			setPassword1Error("");
		}

		if (password1 !== password2) {
			setPassword2Error("Hasła muszą być takie same");
			isValid = false;
		} else {
			setPassword2Error("");
		}

		const validEmailRegex =
			/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!mail || !validEmailRegex.test(mail)) {
			setMailError("Podaj prawidłowy adres e-mail");
			isValid = false;
		} else {
			setMailError("");
		}

		if (isValid) {
			const editUserRequest = {
				action: "edit-user",
				_data_: {
					email: mail,
					id: userId,
					password: password1,
				},
			};

			sendRequest(editUserRequest).then((response) => {
				if (response.data.status === 1) {
					closeModal();
					setLogin("");
					setPassword1("");
					setPassword2("");
					setMail("");
					setSelectedRegions(null);
					setSelectedGroup(null);
					setSelectedProbes([]);
					setDescp("");
					setIsActive(false);
					setMsg("Zapisano!")

					const getUserRequest = {
						action: "get-user",
						id: userId,
					};

					sendRequest(getUserRequest)
						.then((usersResponse) => {
							setUserData([usersResponse.data._data_]);
						})
						.catch((error) => {
							console.error(error);
						});
				} else {
					setLoginError(response.data._data_.username);
					setMsg("Błąd edycji!")
				}
			});
		}
	};

	const closeModal = () => {
		clearErrors();
		if (editMode) {
			clearForm();
		}
		setIsAddUserModalOpen(false);
		setIsDeleteUserModalOpen(false);
		setEditMode(false);
	};

	const openModal = () => {
		setMsg("")
		setEditMode(false);
		setIsAddUserModalOpen(true);
	};

	const handleEditUser = () => {
		const isValid = validateUserData();

		if (isValid) {
			const editUserRequest = {
				action: "edit-user",
				_data_: {
					active: isActive ? 1 : 0,
					description: descp,
					email: mail,
					group_id: selectedGroup.value,
					id: userId,
					password: password1,
					region_id: selectedRegions.map((region) => region.value),
					probe_id: selectedProbes.map((probe) => probe.value),
				},
			};

			sendRequest(editUserRequest).then((response) => {
				if (response.data.status === 1) {
					closeModal();
					setLogin("");
					setPassword1("");
					setPassword2("");
					setMail("");
					setSelectedRegions(null);
					setSelectedGroup(null);
					setSelectedProbes([]);
					setDescp("");
					setIsActive(false);
					setMsg("Zapisano!")

					const getUsersRequest = {
						action: "get-users",
					};

					sendRequest(getUsersRequest)
						.then((usersResponse) => {
							setUserData(usersResponse.data._data_);
						})
						.catch((error) => {
							console.error(error);
						});
				} else {
					setLoginError(response.data._data_.username);
					setMsg("Błąd zapisu!")
				}
			});
		}
	};

	const handleDeleteUser = (row) => {
		setIsDeleteUserModalOpen(true);
		setDeletingUserId(row.id);
		setDeletingUser(row.description);
	};

	const handleSwitchChange = async (userId) => {
		const user = userData.find((u) => u.id === userId);

		if (user) {
			const updatedValue = user.email_reports === 1 ? 0 : 1;
			const updatedUser = { ...user, email_reports: updatedValue };
			const updatedUserData = userData.map((u) =>
				u.id === userId ? updatedUser : u
			);

			setUserData(updatedUserData);

			const editUserRequest = {
				action: "edit-user",
				_data_: {
					email_reports: updatedValue,
					id: userId,
				},
			};
			try {
				await sendRequest(editUserRequest);
				fetchData();
				setMsg("Zapisano zmianę!")
			} catch (error) {
				console.error(error);
				setMsg("Błąd zapisu!")
			}
		}
	};

	const columns = [
		{
			name: "Login",
			selector: (row) => row.username,
			grow: 2,
		},
		{
			name: "Opis",
			selector: (row) => row.description,
			grow: 3,
		},
		{
			name: "Mail",
			selector: (row) => row.email,
			grow: 3,
		},
		{
			name: "Alarmy",
			cell: (row) => (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}>
					<Switch
						checked={row.email_reports === 1}
						onChange={() => handleSwitchChange(row.id)}
					/>
				</div>
			),
			grow: 1,
		},
		{
			name: "Grupa",
			selector: (row) => row.group_name,
			grow: 2,
		},
		{
			name: "Regiony",
			selector: (row) => row.regions,
			grow: 2,
		},
		{
			name: "Akcje",
			cell: (row) => (
				<div style={{ display: "flex", gap: "15px" }}>
					<Icoedytuj
						style={{ cursor: "pointer" }}
						width={20}
						onClick={() => handleOpenModalEdit(row)}
					/>
					<Icozamknij
						style={{ cursor: "pointer" }}
						width={20}
						onClick={() => handleDeleteUser(row)}
					/>
				</div>
			),
			style: { justifyContent: "center" },
			grow: 1,
		},
	];

	const singleUserColumns = [
		{
			name: "Login",
			selector: (row) => row.username,
			grow: 2,
		},
		{
			name: "Opis",
			selector: (row) => row.description,
			grow: 3,
		},
		{
			name: "Mail",
			selector: (row) => row.email,
			grow: 3,
		},
		{
			name: "Alarmy Email",
			selector: (row) =>
				row.email_reports === 1 ? "Włączone" : "Wyłączone",
			grow: 2,
		},
		{
			name: "Grupa",
			selector: (row) => row.group_name,
			grow: 2,
		},
		{
			name: "Akcje",
			cell: (row) => (
				<div style={{ display: "flex", gap: "15px" }}>
					<Icoedytuj
						style={{ cursor: "pointer" }}
						width={20}
						onClick={() => handleOpenModalEditSingleUser()}
					/>
				</div>
			),
			style: { justifyContent: "center" },
			grow: 1,
		},
	];

	if (!isVerified.isVerified) {
		return null;
	}
	return (
		<UsersLayout>
			<h2 className="page-title">Użytkownicy</h2>
			<span style={{marginLeft: "10px"}}>{msg}</span>
			<div className="table-container">
				<StyledDataTable
					columns={isVerified.isAdmin ? columns : singleUserColumns}
					data={userData}
					noDataComponent="Brak danych spełniających kryteria"
					progressPending={isLoading}
					progressComponent={<LoadingComponent />}
				/>
				{isVerified.isAdmin && (
					<Button
						className="add-user"
						onClick={openModal}
						style={{
							fontSize: "16px",
						}}>
						Dodaj użytkownika
					</Button>
				)}
			</div>
			{isAddUserModalOpen && isVerified.isAdmin && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						{editMode ? (
							<h2>Edytuj Użytkownika</h2>
						) : (
							<h2>Dodaj Użytkownika</h2>
						)}
						{editMode ? (
							<h2>{login}</h2>
						) : (
							<div>
								<label htmlFor="login">Login:</label>
								<input
									id="login"
									type="text"
									placeholder="Nazwa użytkownika"
									value={login}
									onChange={(e) => setLogin(e.target.value)}
								/>
								<p className="err-msg">{loginError}</p>
							</div>
						)}
						<div>
							<label htmlFor="password1">Hasło:</label>
							<input
								id="password1"
								type="text"
								placeholder="Hasło"
								value={password1}
								onChange={(e) => setPassword1(e.target.value)}
							/>
							<p className="err-msg">{password1Error}</p>
						</div>
						<div>
							<label htmlFor="password2">Powtórz hasło:</label>
							<input
								id="password2"
								type="text"
								placeholder="Powtórz hasło"
								value={password2}
								onChange={(e) => setPassword2(e.target.value)}
							/>
							<p className="err-msg">{password2Error}</p>
						</div>
						<div>
							<label htmlFor="mail">E-mail:</label>
							<input
								id="mail"
								type="text"
								placeholder="E-mail"
								value={mail}
								onChange={(e) => setMail(e.target.value)}
							/>
							<p className="err-msg">{mailError}</p>
						</div>
						<div>
							<label>Region:</label>
							<Select
								classNamePrefix="react-select"
								placeholder="Wybierz regiony..."
								isMulti
								options={userRegions}
								defaultValue={selectedRegions}
								onChange={setSelectedRegions}
								styles={customStyles}
							/>
							<p className="err-msg">{selectedRegionsError}</p>
						</div>
						<div>
							<label>Grupa użytkowników:</label>
							<Select
								classNamePrefix="react-select"
								placeholder="Wybierz grupę..."
								options={userGroups}
								defaultValue={selectedGroup}
								onChange={setSelectedGroup}
								styles={customStyles}
							/>
							<p className="err-msg">{selectedGroupError}</p>
						</div>
						{selectedGroup &&
							selectedGroup.label === "Użytkownik" && (
								<div>
									<label>Przypisane pompownie:</label>
									<Select
										classNamePrefix="react-select"
										placeholder="Wybierz pompownie do przypisania..."
										isMulti
										value={selectedProbes}
										options={probesAll}
										onChange={setSelectedProbes}
										styles={customStyles}
									/>
								</div>
							)}
						<div>
							<label htmlFor="descp">Opis:</label>
							<input
								id="descp"
								type="text"
								placeholder="Opis"
								value={descp}
								onChange={(e) => setDescp(e.target.value)}
							/>
							<p className="err-msg"></p>
						</div>
						<div>
							<label style={{ marginRight: "10px" }}>
								Aktywny:
							</label>
							<Switch
								checked={isActive}
								onChange={() => setIsActive(!isActive)}
							/>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							{editMode ? (
								<Button
									className="add"
									onClick={handleEditUser}>
									Zapisz
								</Button>
							) : (
								<Button className="add" onClick={handleAddUser}>
									Dodaj
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{isAddUserModalOpen && !isVerified.isAdmin && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Edytuj</h2>
						<h2>{login}</h2>

						<div>
							<label htmlFor="password1">Hasło:</label>
							<input
								id="password1"
								type="text"
								placeholder="Hasło"
								value={password1}
								onChange={(e) => setPassword1(e.target.value)}
							/>
							<p className="err-msg">{password1Error}</p>
						</div>
						<div>
							<label htmlFor="password2">Powtórz hasło:</label>
							<input
								id="password2"
								type="text"
								placeholder="Powtórz hasło"
								value={password2}
								onChange={(e) => setPassword2(e.target.value)}
							/>
							<p className="err-msg">{password2Error}</p>
						</div>
						<div>
							<label htmlFor="mail">E-mail:</label>
							<input
								id="mail"
								type="text"
								placeholder="E-mail"
								value={mail}
								onChange={(e) => setMail(e.target.value)}
							/>
							<p className="err-msg">{mailError}</p>
						</div>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={handleEditSingleUser}>
								Zapisz
							</Button>
						</div>
					</div>
				</div>
			)}
			{isDeleteUserModalOpen && (
				<div onMouseDown={closeModal} className="modal-overlay">
					<div
						onMouseDown={(e) => {
							e.stopPropagation();
						}}
						className="modal-content"
						ref={modalContentRef}>
						<h2>Czy na pewno chcesz usunąć użytkownika</h2>
						<h2>{deletingUser} ?</h2>
						<div className="modal-actions">
							<Button className="cancel" onClick={closeModal}>
								Anuluj
							</Button>
							<Button
								className="add"
								onClick={() => setConfirmedDeletingUser(true)}>
								Usuń
							</Button>
						</div>
					</div>
				</div>
			)}
		</UsersLayout>
	);
};

export default Users;
