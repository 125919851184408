import { useEffect, useState } from "react";
import { Button } from "../../Button/Button";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { StyledDataTable } from "../../Table/Table.styles";
import { sendRequest } from "../../../api/sendRequest";
import { useParams } from "react-router-dom";
import { ReactComponent as IcoArrowDown } from "./../../../svg/ico-w-dol.svg";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import LoadingComponent from "../../Table/LoadingComponent";
import { pumpRequest } from "../../../api/pumpRequest";
import LoadingThreeDots from "../../Loading/LoadingThreeDots";

const DetailsLogs = () => {
	const [pumpLogsData, setPumpLogsData] = useState([]);
	const [eventLogsData, setEventLogsData] = useState([]);
	const monthAgo = new Date(new Date().setDate(new Date().getDate() - 30));
	const today = new Date();
	const [datePickerValue, setDatePickerValue] = useState([monthAgo, today]);
	const [searchValue, setSearchValue] = useState("");
	const [isLoadingLogs, setIsLoadingLogs] = useState(true);
	const [pumpTotalRows, setPumpTotalRows] = useState(0);
	const [buttonStatus, setButtonStatus] = useState({});
	const [eventsTotalRows, setEventsTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [pumpCurrentPage, setPumpCurrentPage] = useState(1);
	const [eventsCurrentPage, setEventsCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState("4");
	const [sortDirection, setSortDirection] = useState("desc");
	const [showPumpLogs, setShowPumpLogs] = useState(true);
	const [isPumpApiLoading, setIsPumpApiLoading] = useState(false);
	const dictionary = JSON.parse(localStorage.getItem("dictionary"));
	const params = useParams();

	const fetchEventLogs = async (
		page,
		size = perPage,
		column = sortColumn,
		direction = sortDirection,
		search,
		newDates = datePickerValue
	) => {
		setIsLoadingLogs(true);

		const startString = formatDate(newDates[0]) + " 00:00";
		const endString = formatDate(newDates[1]) + " 23:59";
		const formattedDate = startString + "-" + endString;

		const getEventLogsRequest = {
			action: "get-probe-events-log",
			_data_: {
				probe_id: params.id,
				page: page,
				perPage: size,
				search: search,
				date: formattedDate,
				sortColumn: column,
				sortDirection: direction,
			},
		};
		sendRequest(getEventLogsRequest)
			.then((eventsResponse) => {
				setEventsTotalRows(
					eventsResponse.data._data_.results[0]?.total_count
				);
				setEventLogsData(eventsResponse.data._data_);
				setIsLoadingLogs(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoadingLogs(false);
			});
	};

	const fetchPumpLogs = async (
		page,
		size = perPage,
		column = sortColumn,
		direction = sortDirection,
		search,
		newDates = datePickerValue
	) => {
		setIsLoadingLogs(true);

		const startString = formatDate(newDates[0]) + " 00:00";
		const endString = formatDate(newDates[1]) + " 23:59";
		const formattedDate = startString + "-" + endString;

		const getPumpLogsRequest = {
			action: "get-probe-eventlog",
			_data_: {
				probe_id: params.id,
				page: page,
				perPage: size,
				search: search,
				date: formattedDate,
				sortColumn: column,
				sortDirection: direction,
			},
		};

		sendRequest(getPumpLogsRequest)
			.then((pumpResponse) => {
				setPumpTotalRows(
					pumpResponse.data._data_.results[0]?.total_count
				);
				setPumpLogsData(pumpResponse.data._data_);
				setIsLoadingLogs(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoadingLogs(false);
			});
	};

	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");

		return `${year}/${month}/${day}`;
	};

	useEffect(() => {
		fetchPumpLogs(1);
		fetchEventLogs(1);
	}, []);

	const fetchLogs = (
		page,
		size = perPage,
		column = sortColumn,
		direction = sortDirection,
		search,
		newDates = datePickerValue
	) => {
		if (showPumpLogs) {
			fetchPumpLogs(page, size, column, direction, search, newDates);
			setPumpCurrentPage(page);
		} else {
			fetchEventLogs(page, size, column, direction, search, newDates);
			setEventsCurrentPage(page);
		}
	};

	const handleRefreshLogsData = async (e) => {
		const buttonId = e.currentTarget.getAttribute("data-button-id");
		setButtonStatus({ [buttonId]: "" });
		setIsPumpApiLoading(true);
		const getPumpEventLog = {
			action: "pump-get-event-log",
			probe_id: params.id,
		};

		try {
			await pumpRequest(getPumpEventLog);
			setIsPumpApiLoading(false);
			setButtonStatus({ [buttonId]: "success" });
		} catch (error) {
			console.error(error);
			setIsPumpApiLoading(false);
			setButtonStatus({ [buttonId]: "error" });
		}
	};

	const handleDateChange = (value) => {
		setDatePickerValue(value);
		fetchLogs(
			pumpCurrentPage,
			perPage,
			sortColumn,
			sortDirection,
			searchValue,
			value
		);
	};

	const handleSearchChange = (e) => {
		setSearchValue(e.target.value);
		setPumpCurrentPage(1)
		fetchLogs(
			pumpCurrentPage,
			perPage,
			sortColumn,
			sortDirection,
			e.target.value
		);
	};

	const handlePageChange = (page) => {
		fetchLogs(page, perPage, sortColumn, sortDirection);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchLogs(page, newPerPage, sortColumn, sortDirection);
		setPerPage(newPerPage);
	};

	const handleSort = async (column, sortDirection) => {
		setSortDirection(sortDirection);
		fetchLogs(
			pumpCurrentPage,
			perPage,
			column.sortField,
			sortDirection,
			searchValue
		);
	};

	const handleRefreshData = () => {
		if (showPumpLogs) {
			fetchPumpLogs(
				pumpCurrentPage,
				perPage,
				sortColumn,
				sortDirection,
				searchValue,
				datePickerValue
			);
		} else {
			fetchEventLogs(
				eventsCurrentPage,
				perPage,
				sortColumn,
				sortDirection,
				searchValue,
				datePickerValue
			);
		}
	};

	const MAX_LENGTH = 35;

	const cleanData = (str, dictionaryKey) => {
		if (!str) return "";
		str = str.replace(/^;+|;+$/g, "");
		str = str.replace(/;+/g, ";");
		const lines = str
			.split(";")
			.filter((line) => line.trim() !== "" && line !== "RESERVED");

		return lines
			.map((val) => {
				const trimmedVal = val.trim();
				return (
					dictionary?.event_log?.[dictionaryKey]?.[trimmedVal] ||
					trimmedVal
				);
			})
			.join("\n");
	};

	const pumpLogsColumns = [
		{
			name: "Log Wejścia/Wyjścia",
			selector: (row) => row.io,
			grow: 5,
			sortable: true,
			sortField: "0",
			cell: (row) => (
				<div style={{ whiteSpace: "pre-line", padding: "10px 0px" }}>
					{cleanData(row.io, "io")}
				</div>
			),
		},
		{
			name: "Log Czujnika Poziomu",
			selector: (row) => row.ls,
			grow: 2,
			sortable: true,
			sortField: "1",
			cell: (row) => (
				<div style={{ whiteSpace: "pre-line", padding: "10px 0px" }}>
					{cleanData(row.ls, "ls")}
				</div>
			),
		},
		{
			name: "Błędy",
			selector: (row) => `${row.error1} ${row.error2}`,
			grow: 1,
			sortable: true,
			sortField: "2",
			cell: (row) => (
				<div style={{ whiteSpace: "pre-line", padding: "10px 0px" }}>
					{cleanData(row.error1, "error1")} <br />
					{cleanData(row.error2, "error2")}
				</div>
			),
		},

		{
			name: "Zegar CPU",
			selector: (row) => row.event_cputime,
			grow: 1,
			sortable: true,
			sortField: "3",
		},
		{
			name: "Data zdarzenia",
			selector: (row) => row.event_time,
			grow: 3,
			sortable: true,
			sortField: "4",
		},
	];

	const EventLogsColumns = [
		{
			name: "Event Type",
			selector: (row) => row.event,
			grow: 2,
			sortable: true,
			sortField: "0",
		},
		{
			name: "Event Response",
			selector: (row) => {
				if (!row.event_response) {
					return <div style={{ maxWidth: "500px" }}></div>;
				}

				const content = row.event_response;

				if (content.length > MAX_LENGTH) {
					return (
						<Tippy content={content}>
							<div style={{ maxWidth: "500px" }}>{content}</div>
						</Tippy>
					);
				} else {
					return <div style={{ maxWidth: "500px" }}>{content}</div>;
				}
			},
			grow: 2,
			sortable: true,
			sortField: "1",
		},
		{
			name: "User",
			selector: (row) => row.user_description,
			grow: 1,
			sortable: true,
			sortField: "2",
		},
		{
			name: "Timestamp",
			selector: (row) => row.ctime,
			grow: 1,
			sortable: true,
			sortField: "3",
		},
	];
	return (
		<div className="table-container">
			<div
				className="logs-button"
				onClick={() => setShowPumpLogs(!showPumpLogs)}>
				<IcoArrowDown width={30} />
				{showPumpLogs ? "Logi Zdarzeń" : "Logi Pompowni"}
			</div>
			<h2 className="table-title">
				{showPumpLogs ? "Logi Pompowni" : "Logi Zdarzeń"}
			</h2>
			{showPumpLogs ? (
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: "Wiersze na stronę:",
					}}
					data={pumpLogsData.results}
					columns={pumpLogsColumns}
					noDataComponent="Brak danych spełniających kryteria"
					progressPending={isLoadingLogs}
					progressComponent={<LoadingComponent />}
					pagination
					paginationServer
					paginationTotalRows={pumpTotalRows}
					paginationDefaultPage={pumpCurrentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					onSort={handleSort}
					sortServer
				/>
			) : (
				<StyledDataTable
					paginationComponentOptions={{
						rowsPerPageText: "Wiersze na stronę:",
					}}
					data={eventLogsData.results}
					columns={EventLogsColumns}
					progressPending={isLoadingLogs}
					noDataComponent="Brak danych spełniających kryteria"
					pagination
					paginationServer
					progressComponent={<LoadingComponent />}
					paginationTotalRows={eventsTotalRows}
					paginationDefaultPage={eventsCurrentPage}
					onChangeRowsPerPage={handlePerRowsChange}
					onChangePage={handlePageChange}
					onSort={handleSort}
					sortServer
				/>
			)}

			<div className="datepicker-container">
				<DateRangePicker
					onChange={handleDateChange}
					value={datePickerValue}
					clearIcon={null}
					maxDate={new Date()}
				/>
				<input
					className="search-input"
					placeholder="Szukaj"
					onKeyUp={handleSearchChange}
				/>
				<Button onClick={handleRefreshData}>Odśwież dane</Button>
				<Button
					data-button-id="handleRefreshLogsData"
					className={buttonStatus.handleRefreshLogsData}
					onClick={handleRefreshLogsData}
					style={{ minWidth: "223px" }}>
					{isPumpApiLoading ? (
						<LoadingThreeDots />
					) : (
						"Pobierz logi pompowni"
					)}
				</Button>
			</div>
		</div>
	);
};

export default DetailsLogs;
