import {
	React,
	useEffect,
	useState,
	useMemo,
	useRef,
	useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { sendRequest } from '../../api/sendRequest';
import { ReactComponent as IcoArrowDown } from './../../svg/ico-w-dol.svg';
import { useTheme } from 'styled-components';
import { StyledDataTable } from '../../components/Table/Table.styles';
import { fromLonLat } from 'ol/proj';
import 'ol/ol.css';
import { createEmpty, extend, getHeight, getWidth } from 'ol/extent';
import {
	RMap,
	ROSM,
	RLayerVector,
	RFeature,
	RPopup,
	RLayerCluster,
} from 'rlayers';
import {
	RIcon,
	RStyle,
	RFill,
	RStroke,
	RRegularShape,
	RCircle,
	RText,
} from 'rlayers/style';
import { LineString, Point } from 'ol/geom';
import locationProbeIcon from './../../svg/Artboard 62.svg';
import locationProbeIconInactive from './../../svg/Artboard 62 transparent.svg';
import locationNodeIcon from './../../svg/iconmonstr-triangle-1.svg';
import calculateIconScale from '../../hooks/calculateIconScale';
import LoadingComponent from '../../components/Table/LoadingComponent';
import { TailSpin } from 'react-loader-spinner';

const Map = ({ setProbeCounts, visibleProbes, setVisibleProbes }) => {
	const [arePumpsVisible, setPumpsVisible] = useState(true);
	const [arePipesVisible, setPipesVisible] = useState(true);
	const [areNodesVisible, setNodesVisible] = useState(true);
	const [isSelectLayersVisible, setSelectLayersVisible] = useState(false);
	const navigate = useNavigate();
	const theme = useTheme();
	const [showTable, setShowTable] = useState(false);
	const [mapProbes, setMapProbes] = useState([]);
	const [probesAll, setProbesAll] = useState([]);
	const [drawings, setDrawings] = useState([]);
	const [nodesList, setNodesList] = useState([]);
	const [isMapLoading, setIsMapLoading] = useState(true);
	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState('5');
	const [sortDirection, setSortDirection] = useState('desc');
	const [pumpStatus, setPumpStatus] = useState('all');
	const [mapCenter, setMapCenter] = useState(null);
	const [mapZoom, setMapZoom] = useState(null);
	const [mapKey, setMapKey] = useState(Math.random());
	const mapProbesRef = useRef();
	const probeRef = useRef();
	mapProbesRef.current = mapProbes;
	const savedCenter = JSON.parse(sessionStorage.getItem('mapCenter'));
	const savedZoom = sessionStorage.getItem('mapZoom');

	const fetchTableData = async (
		page,
		size = perPage,
		column = sortColumn,
		direction = sortDirection,
		search,
		visibleProbes
	) => {
		setLoading(true);
		const getProbesAll = {
			action: 'get-probes-all',
			nodes: false,
			_data_: {
				page: page,
				perPage: size,
				search: search,
				sortColumn: column,
				sortDirection: direction,
				probes: visibleProbes,
			},
		};
		try {
			const response = await sendRequest(getProbesAll);
			setTotalRows(response.data._data_.results[0]?.total_count);
			setProbesAll(response.data._data_.results);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchData = async () => {
		const cachedMapProbes = sessionStorage.getItem('mapProbes');
		const cachedMapProbesTimestamp =
			sessionStorage.getItem('mapProbesTimestamp');
		const currentTime = Date.now();
		const cacheDuration = 3600000;

		let mapProbesInitialResponse = cachedMapProbes
			? JSON.parse(cachedMapProbes)
			: null;

		const isCacheValid =
			cachedMapProbes &&
			cachedMapProbesTimestamp &&
			currentTime - parseInt(cachedMapProbesTimestamp) < cacheDuration;

		if (!isCacheValid) {
			try {
				mapProbesInitialResponse = await sendRequest({
					action: 'get-map-probes-all',
				});
				sessionStorage.setItem(
					'mapProbes',
					JSON.stringify(mapProbesInitialResponse)
				);
				sessionStorage.setItem(
					'mapProbesTimestamp',
					currentTime.toString()
				);
			} catch (error) {
				console.error('Error fetching map probes:', error);
				setIsMapLoading(false);
				return;
			}
		}

		try {
			const [
				featuresLineStringResponse,
				featuresPointResponse,
				nodesResposne,
			] = await Promise.all([
				sendRequest({
					action: 'get-features',
					type: 'LineString',
					region_id: [],
				}),
				sendRequest({
					action: 'get-features',
					type: 'Point',
					region_id: [],
				}),
				sendRequest({
					action: 'get-nodes',
				}),
			]);

			const checkPumpsStatusResponse = await sendRequest({
				action: 'get-probes-pump-status',
			});

			const updatedMapProbes = mapProbesInitialResponse.data._data_.map(
				(probe) => {
					const updatedProbe =
						checkPumpsStatusResponse.data._data_.find(
							(item) => item.probe_id === probe.probe_id
						);
					return updatedProbe
						? {
								...probe,
								probe_running: updatedProbe.probe_running,
								error: updatedProbe.error,
						  }
						: probe;
				}
			);

			setMapProbes(updatedMapProbes);
			setDrawings([
				...featuresLineStringResponse.data._data_,
				...featuresPointResponse.data._data_,
			]);
			setNodesList(nodesResposne.data._data_);
		} catch (error) {
			console.error('Error fetching additional data:', error);
		}
		setMapKey(Math.random());
		setIsMapLoading(false);
	};

	useEffect(() => {
		const fetchProbesAllInterval = setInterval(checkAllPumpStatus, 10000);
		fetchData();
		fetchTableData(1);
		return () => {
			clearInterval(fetchProbesAllInterval);
		};
	}, []);

	const checkAllPumpStatus = async () => {
		const checkPumpsStatus = {
			action: 'get-probes-pump-status',
		};
		try {
			const response = await sendRequest(checkPumpsStatus);
			const updatedData = response.data._data_;

			const newMapProbes = mapProbesRef.current.map((probe) => {
				const updatedProbe = updatedData.find(
					(item) => item.probe_id === probe.probe_id
				);
				if (updatedProbe) {
					return {
						...probe,
						probe_running: updatedProbe.probe_running,
					};
				}
				return probe;
			});

			if (
				JSON.stringify(newMapProbes) !==
				JSON.stringify(mapProbesRef.current)
			) {
				setMapProbes(newMapProbes);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getIconColor = (probe_running, probe_error, probe_status) => {
		if (probe_status === 0) return 'transparent';
		if (probe_error > 0) return theme.danger;
		if (probe_running === 0) return theme.blocked;
		if (probe_running === 1) return theme.standby;
		if (probe_running === 2) return theme.success;
		if (probe_running === 3) return theme.warning;

		return theme.warning;
	};

	const iconColors = useMemo(() => {
		const colors = {};
		mapProbes.forEach((probe) => {
			colors[probe.probe_id] = getIconColor(
				probe.probe_running,
				probe.error,
				probe.status
			);
		});
		return colors;
	}, [mapProbes, theme]);

	const handleShowTableClick = () => {
		setShowTable(!showTable);
		if (window.innerWidth >= 425) {
			setTimeout(() => {
				window.scrollTo({
					top: document.body.scrollHeight,
					behavior: 'smooth',
				});
			}, 1);
		}
	};

	const customStyles = {
		rows: {
			style: {
				cursor: 'pointer',
			},
		},
	};

	useEffect(() => {
		if (mapProbes && mapProbes.length > 0) {
			let totalError = 0;
			let countStatusOne = 0;
			let countProbeRunningZero = 0;
			let countProbeRunningThree = 0;
			let countStatusZero = 0;

			mapProbes.forEach((probe) => {
				if (probe.status === 1) {
					if (probe.error > 0) {
						totalError++;
						return;
					}
					if (probe.probe_running === 0) {
						countProbeRunningZero++;
						return;
					}
					if (probe.probe_running === 3) {
						countProbeRunningThree++;
						return;
					}
					countStatusOne++;
				}
				if (probe.status === 0) {
					countStatusZero++;
				}
			});

			setProbeCounts({
				countStatusOne,
				countProbeRunningZero,
				countProbeRunningThree,
				countStatusZero,
				totalError,
			});
		}
	}, [mapProbes]);

	const columns = [
		{
			name: 'Nazwa',
			selector: (row) => row.probe_name,
			grow: 1,
			sortable: true,
			sortField: '0',
		},
		{
			name: 'Opis',
			selector: (row) => row.description,
			grow: 2,
			sortable: true,
			sortField: '1',
		},
		{
			name: 'Adres',
			selector: (row) => row.location,
			grow: 1,
			sortable: true,
			sortField: '2',
		},
		{
			name: 'Status',
			selector: (row) => (row.active === 1 ? 'Gotowość' : 'Wyłączona'),
			grow: 1,
			sortable: true,
			sortField: '3',
			conditionalCellStyles: [
				{
					when: (row) => row.active === 1,
					style: {
						backgroundColor: theme.success,
						color: 'white',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.active === 0,
					style: {
						backgroundColor: 'white',
						color: 'black',
						justifyContent: 'center',
					},
				},
			],
		},
		{
			name: 'Stan Pracy',
			selector: (row) => {
				if (row.active === 0) {
					return 'Wyłączona';
				} else {
					switch (row.probe_running) {
						case 0:
							return 'Wyłączona';
						case 1:
							return 'Nie pompuje';
						case 2:
							return 'Pompuje';
						case 3:
							return 'Brak połączenia';
						default:
							return 'Brak połączenia';
					}
				}
			},
			grow: 1,
			sortable: true,
			sortField: '4',
			conditionalCellStyles: [
				{
					when: (row) => row.active === 0,
					style: {
						backgroundColor: 'white',
						color: 'black',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.active === 1 && row.probe_running === 1,
					style: {
						backgroundColor: theme.standby,
						color: 'white',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.active === 1 && row.probe_running === 2,
					style: {
						backgroundColor: theme.success,
						color: 'white',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.active === 1 && row.probe_running === 0,
					style: {
						backgroundColor: theme.danger,
						color: 'white',
						justifyContent: 'center',
					},
				},
				{
					when: (row) => row.active === 1 && row.probe_running === 3,
					style: {
						backgroundColor: theme.warning,
						color: 'white',
						justifyContent: 'center',
					},
				},
			],
		},
		{
			name: 'Ostatni odczyt',
			selector: (row) => row.last_login,
			grow: 1,
			sortable: true,
			sortField: '5',
		},
		{
			name: 'Harmonogramy',
			selector: (row) => row.schedules,
			grow: 1,
			sortable: true,
			sortField: '6',
		},
		{
			name: 'Region',
			selector: (row) => row.region_name,
			grow: 1,
			sortable: true,
			sortField: '7',
		},
	];

	const handleMove = (event) => {
		const newCenter = event.target.getView().getCenter();
		const zoom = event.target.getView().getZoom();
		setMapZoom(zoom);

		sessionStorage.setItem('mapCenter', JSON.stringify(newCenter));
		sessionStorage.setItem('mapZoom', zoom);
	};

	const handleSearchChange = (e) => {
		setCurrentPage(1);
		setSearchValue(e.target.value);
	};

	useEffect(() => {
		const debounceTimeout = setTimeout(() => {
			fetchTableData(
				currentPage,
				perPage,
				sortColumn,
				sortDirection,
				searchValue,
				visibleProbes
			);
		}, 500);

		return () => {
			clearTimeout(debounceTimeout);
		};
	}, [searchValue]);

	useEffect(() => {
		fetchTableData(
			currentPage,
			perPage,
			sortColumn,
			sortDirection,
			searchValue,
			visibleProbes
		);
	}, [visibleProbes]);

	const handlePageChange = (page) => {
		fetchTableData(
			page,
			perPage,
			sortColumn,
			sortDirection,
			searchValue,
			visibleProbes
		);
		setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		fetchTableData(
			page,
			newPerPage,
			sortColumn,
			sortDirection,
			searchValue,
			visibleProbes
		);
		setPerPage(newPerPage);
	};

	const handleSort = async (column, sortDirection) => {
		setSortDirection(sortDirection);
		setSortColumn(column.sortField);
		fetchTableData(
			currentPage,
			perPage,
			column.sortField,
			sortDirection,
			searchValue,
			visibleProbes
		);
	};

	const statusMessage = (probe) => {
		switch (probe.probe_running) {
			case 0:
				return 'Wyłączona';
			case 1:
				return 'Nie pompuje';
			case 2:
				return 'Pompuje';
			case 3:
				return 'Brak połączenia';
			default:
				return 'Brak połączenia';
		}
	};

	const radiusStar = (feature) =>
		Math.round(5 * (parseFloat(feature.get('mag')) - 2.5));

	const extentFeatures = (features, resolution) => {
		const extent = createEmpty();

		for (const f of features) extend(extent, f.getGeometry().getExtent());

		return (
			Math.round(0.25 * (getWidth(extent) + getHeight(extent))) /
			resolution
		);
	};

	const handleCacheId = useCallback(
		(feature, resolution) =>
			feature.get('features').length > 1
				? '#' + extentFeatures(feature.get('features'), resolution)
				: '$' + radiusStar(feature.get('features')[0]),

		[]
	);

	const handleRender = useCallback(
		(feature, resolution) => {
			const size = feature.get('features').length;
			const allFeatures = feature.get('features');
			const allProbeIds = allFeatures.map((f) => f.get('probeId'));

			const allProbeValues = allProbeIds.map((probeId) => {
				const probe = mapProbes.find((p) => p.probe_id === probeId);
				return probe
					? { error: probe.error, running: probe.probe_running }
					: null;
			});

			let statusColor = theme.standby;

			const hasError = allProbeValues.some((v) => v && v.error > 0);
			if (hasError) {
				statusColor = theme.danger;
			} else {
				if (allProbeValues.some((v) => v && v.running === 2)) {
					statusColor = theme.success;
				} else if (allProbeValues.some((v) => v && v.running === 0)) {
					statusColor = theme.blocked;
				} else if (allProbeValues.some((v) => v && v.running === 3)) {
					statusColor = theme.warning;
				}
			}

			if (size > 1) {
				const radius = extentFeatures(
					feature.get('features'),
					resolution
				);
				return (
					<>
						<RCircle radius={15}>
							<RFill color={statusColor} />
						</RCircle>
						<RText text={size.toString()} scale={1.5}>
							<RFill color="#000000" />
							<RStroke color="rgb(0, 0, 0)" width={0.5} />
						</RText>
					</>
				);
			}

			const unclusteredFeature = feature.get('features')[0];
			return (
				<RRegularShape
					radius1={radiusStar(unclusteredFeature)}
					radius2={3}
					points={5}
					angle={Math.PI}>
					<RFill color="rgba(255, 0, 0, 0.8)" />
					<RStroke color="rgba(255, 204, 0, 0.2)" width={5} />
				</RRegularShape>
			);
		},
		[mapProbes]
	);

	return (
		<div className="map">
			{isMapLoading && (
				<TailSpin
					height="80"
					width="80"
					color={theme.primary}
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{
						position: 'absolute',
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						zIndex: 10,
					}}
					wrapperClass=""
					visible={true}
				/>
			)}
			<div className="button" onClick={handleShowTableClick}>
				<IcoArrowDown width={30} />
				{showTable ? 'Mapa' : 'Tabela'}
			</div>
			{showTable ? (
				<div className="table-container">
					<StyledDataTable
						paginationComponentOptions={{
							rowsPerPageText: 'Wiersze na stronę:',
						}}
						className="table-dashboard"
						data={probesAll}
						noDataComponent="Brak danych spełniających kryteria"
						columns={columns}
						customStyles={customStyles}
						progressPending={loading}
						progressComponent={<LoadingComponent />}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						paginationDefaultPage={currentPage}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						onSort={handleSort}
						sortServer
						onRowClicked={(row) => {
							if (row.multi_probe === null) {
								navigate(`/details/${row.probe_id}`);
							} else {
								const multiProbeIds = JSON.parse(
									row.multi_probe
								).join('_');
								navigate(`/multidetails/${multiProbeIds}`);
							}
						}}
					/>
					<input
						type="text"
						placeholder="Szukaj..."
						onChange={handleSearchChange}
						value={searchValue}
					/>
					{visibleProbes.length > 0 && (
						<div
							onClick={() => setVisibleProbes([])}
							className="delete-filters">
							Usuń filtr
						</div>
					)}
				</div>
			) : (
				<div className="map-container">
					{visibleProbes.length > 0 && (
						<div
							onClick={() => setVisibleProbes([])}
							className="delete-filters">
							Usuń filtr
						</div>
					)}
					<button
						className="select-layers-button"
						onClick={() =>
							setSelectLayersVisible(!isSelectLayersVisible)
						}>
						&#9776;
					</button>
					{isSelectLayersVisible && (
						<div className="select-layers">
							<label>
								<input
									type="checkbox"
									checked={arePumpsVisible}
									onChange={(e) =>
										setPumpsVisible(e.target.checked)
									}
								/>
								Pompownie
							</label>
							{arePumpsVisible && (
								<select
									onChange={(e) =>
										setPumpStatus(e.target.value)
									}
									defaultValue="all">
									<option value="all">Wszystkie</option>
									<option value={0}>Wyłączona</option>
									<option value={1}>Nie pompuje</option>
									<option value={2}>Pompuje</option>
									<option value={3}>Brak połączenia</option>
									<option value="error">Z błędem</option>
								</select>
							)}
							<label>
								<input
									type="checkbox"
									checked={arePipesVisible}
									onChange={(e) =>
										setPipesVisible(e.target.checked)
									}
								/>
								Rurociągi
							</label>
							<label>
								<input
									type="checkbox"
									checked={areNodesVisible}
									onChange={(e) =>
										setNodesVisible(e.target.checked)
									}
								/>
								Węzły
							</label>
						</div>
					)}
					<RMap
						key={mapKey}
						minZoom={8}
						width={'100%'}
						height={'100%'}
						initial={{
							center:
								savedCenter &&
								savedCenter[0] !== 0 &&
								savedCenter[1] !== 0
									? savedCenter
									: mapProbes[0]?.latitude &&
									  mapProbes[0]?.longitude
									? fromLonLat([
											mapProbes[0]?.latitude,
											mapProbes[0]?.longitude,
									  ])
									: [0, 0],
							zoom:
								savedZoom ||
								(mapProbes[0]?.latitude &&
								mapProbes[0]?.longitude
									? 15
									: 15),
						}}
						onMoveEnd={handleMove}>
						<ROSM />
						{mapZoom < 14 && (
							<RLayerCluster
								distance={40}
								visible={arePumpsVisible}
								ref={probeRef}
								zIndex={5}>
								{mapProbes
									.filter((probe) => probe.status !== 0)
									.filter(
										(pump) =>
											visibleProbes.length === 0 ||
											visibleProbes.includes(
												pump.probe_id
											)
									)
									.filter((probe) => {
										if (pumpStatus === 'all') {
											return true;
										} else if (pumpStatus === 'error') {
											return probe.error > 0;
										} else {
											return (
												probe.probe_running ===
												Number(pumpStatus)
											);
										}
									})
									.map((probe, index) => (
										<RFeature
											key={index}
											properties={{
												probeId: probe.probe_id,
											}}
											geometry={
												new Point(
													fromLonLat([
														probe.latitude,

														probe.longitude,
													])
												)
											}></RFeature>
									))}

								<RStyle
									cacheSize={1024}
									cacheId={handleCacheId}
									render={handleRender}
								/>
							</RLayerCluster>
						)}
						<RLayerVector
							zIndex={3}
							visible={arePumpsVisible}
							properties={{ label: 'Pompownie' }}>
							{mapProbes
								.filter(
									(pump) =>
										visibleProbes.length === 0 ||
										visibleProbes.includes(pump.probe_id)
								)
								.filter((probe) => {
									if (pumpStatus === 'all') {
										return true;
									} else if (pumpStatus === 'error') {
										return probe.error > 0;
									} else {
										return (
											probe.probe_running ===
											Number(pumpStatus)
										);
									}
								})
								.sort((a, b) => {
									const getPriority = (probe) => {
										if (probe.status === 0) return 1;
										if (
											probe.status === 1 &&
											probe.probe_running === 1 &&
											probe.error === 0
										)
											return 2;
										if (
											probe.status === 1 &&
											probe.probe_running === 2 &&
											probe.error === 0
										)
											return 3;
										if (
											probe.status === 1 &&
											probe.error > 0
										)
											return 4;
										if (
											probe.status === 1 &&
											probe.probe_running === 3 &&
											probe.error === 0
										)
											return 5;
										return 6;
									};

									const priorityA = getPriority(a);
									const priorityB = getPriority(b);

									if (priorityA < priorityB) return -1;
									if (priorityA > priorityB) return 1;
									return 0;
								})
								.map((probe) => {
									const color = iconColors[probe.probe_id];

									const getMultiProbeInfo = (probe) => {
										if (probe.multi_probe) {
											try {
												const multiProbeArray =
													JSON.parse(
														probe.multi_probe
													);
												if (
													Array.isArray(
														multiProbeArray
													)
												) {
													const count =
														multiProbeArray.length;
													if (count === 2)
														return 'Grupa 2-pompowa';
													if (count === 3)
														return 'Grupa 3-pompowa';
													if (count === 4)
														return 'Grupa 4-pompowa';
												}
											} catch (e) {
												console.error(
													'Błąd podczas przetwarzania multi_probe:',
													e
												);
											}
										}
										return null;
									};

									const multiProbeInfo =
										getMultiProbeInfo(probe);
									return (
										<RFeature
											onPointerEnter={() =>
												(document.body.style.cursor =
													'pointer')
											}
											onPointerLeave={() =>
												(document.body.style.cursor =
													'default')
											}
											onClick={() => {
												if (multiProbeInfo) {
													const multiProbeIds =
														probe.multi_probe
															? JSON.parse(
																	probe.multi_probe
															  ).join('_')
															: probe.probe_id;
													navigate(
														`/multidetails/${multiProbeIds}`
													);
												} else {
													navigate(
														`/details/${probe.probe_id}`
													);
												}
												document.body.style.cursor =
													'default';
											}}
											key={probe.probe_id}
											geometry={
												new Point(
													fromLonLat([
														probe.latitude,
														probe.longitude,
													])
												)
											}>
											<RStyle>
												<RIcon
													key={color}
													src={
														color === 'transparent'
															? locationProbeIconInactive
															: locationProbeIcon
													}
													color={color}
													scale={calculateIconScale(
														0.9,
														mapZoom
													)}
													anchor={[0.5, 0.5]}
												/>
											</RStyle>
											<RPopup
												trigger={'hover'}
												className="map-popup">
												<p>
													<strong>
														{
															probe.probe_info
																.probe_name
														}
													</strong>
												</p>
												{multiProbeInfo && (
													<p>{multiProbeInfo}</p>
												)}
												<p>
													<em>
														{
															probe.probe_info
																.location
														}
													</em>
												</p>
												<p>
													Status:
													<em>
														{' ' +
															statusMessage(
																probe
															)}
													</em>
												</p>
												<div>
													Harmonogramy:
													{probe.schedules ? (
														probe.schedules
															.split(',')
															.map(
																(
																	schedule,
																	index
																) => (
																	<p
																		key={
																			index
																		}>
																		{
																			schedule
																		}
																	</p>
																)
															)
													) : (
														<span> —</span>
													)}
												</div>

												<p>
													Ostatni odczyt:{' '}
													<em>
														{
															probe.probe_info
																.last_login
														}
													</em>
												</p>
											</RPopup>
										</RFeature>
									);
								})}
						</RLayerVector>
						<RLayerVector
							zIndex={1}
							visible={arePipesVisible}
							properties={{ label: 'Rury' }}>
							{drawings
								.filter(
									(drawing) => drawing.type === 'LineString'
								)
								.map((lineString) => (
									<RFeature
										key={lineString.uid}
										geometry={
											new LineString(
												JSON.parse(lineString.coords)
											)
										}>
										<RStyle>
											<RStroke
												color={theme.pipe}
												width={3}
											/>
										</RStyle>
									</RFeature>
								))}
						</RLayerVector>
						<RLayerVector
							zIndex={2}
							visible={areNodesVisible}
							properties={{ label: 'Węzły' }}>
							{drawings
								.filter((drawing) => drawing.type === 'Point')
								.map((point, index) => {
									const node = nodesList.find(
										(node) => node.node_id === point.uid
									);
									return (
										<RFeature
											key={index}
											geometry={
												new Point(
													JSON.parse(point.coords)
												)
											}>
											<RStyle>
												<RIcon
													scale={calculateIconScale(
														1,
														mapZoom
													)}
													src={locationNodeIcon}
													anchor={[0.5, 0.7]}
												/>
											</RStyle>
											<RPopup
												trigger={'hover'}
												className="map-popup">
												<p>
													<strong>
														Nazwa:{' '}
														{node
															? node.node_name
															: 'Unknown'}
													</strong>
												</p>
												<p>
													<em>
														Przepustowość:{' '}
														{node
															? node.throughput
															: 'Unknown'}{' '}
														m³/h
													</em>
												</p>
												<p>
													<em>
														Średnica:{' '}
														{node
															? node.diameter
															: 'Unknown'}{' '}
														cm
													</em>
												</p>
											</RPopup>
										</RFeature>
									);
								})}
						</RLayerVector>
					</RMap>
				</div>
			)}
		</div>
	);
};

export default Map;
