import axios from "axios";

export async function sendRequest(request) {
	const apiUrl = process.env.REACT_APP_API_URL;
	let token = localStorage.getItem("token");

	if (token) {
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		try {
			const testResponse = await axios.post(
				apiUrl,
				{ action: "check-token" },
				{
					headers: { "Content-Type": "application/json" },
				}
			);
			if (testResponse.data.status === 2) {
				console.log("Expired Token");
				const isSavedPass = localStorage.getItem("savepass") === "true";
				const storage = isSavedPass ? localStorage : sessionStorage;
				const login = storage.getItem("login");
				const password = storage.getItem("password");

				const loginPayload = {
					action: "login",
					username: login,
					password: password,
				};
				console.log("Payload", loginPayload);
				try {
					const response = await axios.post(apiUrl, loginPayload, {
						headers: {
							"Content-Type": "application/json",
						},
					});
					console.log("login response", response);
					if (response.data.status === 1) {
						token = response.data.JWT;
						localStorage.setItem("token", token);
						axios.defaults.headers.common[
							"Authorization"
						] = `Bearer ${token}`;
					}
				} catch (error) {
					console.error(error);
				}
			} else if (
				testResponse.data.status === 0 &&
				testResponse.data.message === "Error: Bad token!"
			) {
				console.log("Error: Bad token!");
				localStorage.removeItem("token");
				window.location.reload();
			}
		} catch (error) {
			token = null;
		}
	}

	return axios.post(apiUrl, request, {
		headers: {
			"Content-Type": "application/json",
		},
	});
}
