import { useState, useEffect } from "react";
import { Button } from "../../Button/Button";
import { pumpRequest } from "../../../api/pumpRequest";
import { useParams } from "react-router-dom";
import { timer } from "rxjs";
import { switchMap, filter, take, tap } from "rxjs/operators";
import LoadingThreeDots from "../../Loading/LoadingThreeDots";
import { StyledDataTable } from "../../Table/Table.styles";

const DetailsCommands = ({ probe }) => {
	const [readRegistry, setReadRegistry] = useState("");
	const [readRegistryValue, setReadRegistryValue] = useState("Value");
	const [saveRegistry, setSaveRegistry] = useState("");
	const [saveValue, setSaveValue] = useState("");
	const [eventId, setEventId] = useState("");
	const [shouldCheckPumpStatus, setShouldCheckPumpStatus] = useState(false);
	const [isProbeLoading, setIsProbeLoading] = useState(false);
	const [saveRegistryError, setSaveRegistryError] = useState("");
	const [informationData, setInformationData] = useState([]);
	const [saveValueError, setSaveValueError] = useState("");
	const params = useParams();

	useEffect(() => {
		if (!shouldCheckPumpStatus) return;
		const subscription = timer(0, 2000)
			.pipe(
				switchMap(() => {
					console.log("Checking status...");
					return pumpRequest({
						action: "pump-observable",
						probe_id: params.id,
						event_id: eventId,
					});
				}),
				tap((response) => {
					console.log(
						"Received response before filtering:",
						response
					);
				}),
				filter((response) => {
					if (!response.data._data_) {
						return false;
					}
					if (
						response.data._data_.event.includes(
							"read_register_done"
						)
					) {
						setReadRegistryValue(
							response.data._data_.event_response
						);
					}
					const isDone =
						response.data._data_.event.includes("done") ||
						response.data._data_.event.includes("set_rtc_stop");
					if (isDone) {
						console.log('Status is "done"');
					}
					return isDone;
				}),
				tap((response) => {
					console.log("Received response after filtering:", response);
				}),
				take(1)
			)
			.subscribe(
				() => {
					setIsProbeLoading(false);
					setShouldCheckPumpStatus(false);
				},
				(requestError) => {
					setIsProbeLoading(false);
				}
			);
		return () => subscription.unsubscribe();
	}, [shouldCheckPumpStatus, setShouldCheckPumpStatus]);

	useEffect(() => {
		try {
			const informationDataObject = JSON.parse(probe.information_data);

			if (
				!informationDataObject.txInfo ||
				typeof informationDataObject.txInfo !== "object"
			) {
				console.warn(
					"txInfo is empty or not an object",
					informationDataObject.txInfo
				);

				return;
			}

			const { dataRate = {}, ...restTxInfo } =
				informationDataObject.txInfo;

			informationDataObject.txInfo = {
				...restTxInfo,
				...dataRate,
			};

			setInformationData([informationDataObject]);
		} catch (error) {
			console.error(
				"An error occurred while processing information data:",
				error
			);
		}
	}, []);

	const handleReadRegistry = async () => {
		setIsProbeLoading(true);
		const pumpGetRegister = {
			action: "pump-get-register",
			probe_id: params.id,
			register: readRegistry,
		};
		try {
			const pumpGetRegisterResponse = await pumpRequest(pumpGetRegister);
			setEventId(pumpGetRegisterResponse.data.event_id);
			setShouldCheckPumpStatus(true);
		} catch (error) {
			setIsProbeLoading(false);
			console.error(error);
		}
	};

	const handleSaveRegistry = async () => {
		let isValid = false;

		if (!saveRegistry) {
			setSaveRegistryError("Pole wymaga wartości");
			isValid = true;
		} else {
			setSaveRegistryError("");
		}

		if (!saveValue) {
			setSaveValueError("Pole wymaga wartości");
			isValid = true;
		} else {
			setSaveValueError("");
		}

		if (isValid) return;
		setIsProbeLoading(true);
		const pumpSaveRegister = {
			action: "pump-set-register",
			probe_id: params.id,
			register: saveRegistry,
			value: saveValue,
		};
		try {
			const pumpSaveRegisterResponse = await pumpRequest(
				pumpSaveRegister
			);
			setEventId(pumpSaveRegisterResponse.data.event_id);
			setShouldCheckPumpStatus(true);
		} catch (error) {
			setIsProbeLoading(false);
			console.error(error);
		}
	};

	const handleKmsReboot = async () => {
		setIsProbeLoading(true);
		const kmsRebootRequest = {
			action: "pump-kms-reboot",
			probe_id: params.id,
		};
		try {
			const pumpKmsRebootResponse = await pumpRequest(kmsRebootRequest);
			setEventId(pumpKmsRebootResponse.data.event_id);
			setShouldCheckPumpStatus(true);
		} catch (error) {
			setIsProbeLoading(false);
			console.error(error);
		}
	};

	const handleSetRtc = async () => {
		setIsProbeLoading(true);
		const setRtcRequest = {
			action: "pump-set-rtc",
			probe_id: params.id,
		};
		try {
			const pumpSetRtcResponse = await pumpRequest(setRtcRequest);
			setEventId(pumpSetRtcResponse.data.event_id);
			setShouldCheckPumpStatus(true);
		} catch (error) {
			setIsProbeLoading(false);
			console.error(error);
		}
	};

	const handleSaveScheduler = async () => {
		setIsProbeLoading(true);
		const setSchedulerRequest = {
			action: "pump-set-scheduler",
			probe_id: params.id,
		};
		try {
			const pumpSetSchedulerResponse = await pumpRequest(
				setSchedulerRequest
			);
			setEventId(pumpSetSchedulerResponse.data.event_id);
			setShouldCheckPumpStatus(true);
		} catch (error) {
			setIsProbeLoading(false);
			console.error(error);
		}
	};

	const informationDataColumns = [
		{
			name: "RX",
			cell: (row) => (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						padding: "5px 0",
					}}>
					{`Name: ${row.rxInfo.name}, RSSI: ${row.rxInfo.rssi}, LoRa SNR: ${row.rxInfo.loRaSNR}, Time: ${row.rxInfo.time}`
						.split(",")
						.map((item, index) => (
							<p key={index} style={{ margin: 0 }}>
								{item.trim()}
							</p>
						))}
				</div>
			),
			sortable: true,
			sortField: "rxInfo.name",
			minWidth: "250px",
			grow: 1,
		},
		{
			name: "TX",
			cell: (row) => (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						padding: "5px 0",
					}}>
					{`Frequency: ${row.txInfo.frequency}, ADR: ${row.txInfo.adr}, Code Rate: ${row.txInfo.codeRate}, Bandwidth: ${row.txInfo.bandwidth}, Modulation: ${row.txInfo.modulation}, Spread Factor: ${row.txInfo.spreadFactor}`
						.split(",")
						.map((item, index) => (
							<p key={index} style={{ margin: 0 }}>
								{item.trim()}
							</p>
						))}
				</div>
			),
			sortable: true,
			sortField: "txInfo.frequency",
			minWidth: "250px",
			grow: 1,
		},
	];

	return (
		<div className="commands-main">
			<div className="commands-container">
				<div className="commands-card">
					<h3>Odczytaj wartość</h3>
					<div>
						<input
							className="search-input"
							placeholder="klucz rejestru"
							type="text"
							value={readRegistry}
							onChange={(e) => setReadRegistry(e.target.value)}
						/>
						<div>{readRegistryValue}</div>
					</div>
					<div>
						<p></p>
					</div>
					<Button
						onClick={handleReadRegistry}
						disabled={isProbeLoading}
						style={{
							cursor: isProbeLoading ? "wait" : "pointer",
						}}>
						{isProbeLoading ? <LoadingThreeDots /> : "Odczytaj"}
					</Button>
				</div>
				<div className="commands-card">
					<h3>Zapisz wartość</h3>
					<div>
						<input
							className="search-input"
							placeholder="klucz rejestru"
							type="text"
							value={saveRegistry}
							onChange={(e) => setSaveRegistry(e.target.value)}
						/>
						<input
							className="search-input"
							placeholder="wartość klucza"
							type="text"
							value={saveValue}
							onChange={(e) => setSaveValue(e.target.value)}
						/>
					</div>
					<div>
						<p>{saveRegistryError}</p>
						<p>{saveValueError}</p>
					</div>
					<Button
						onClick={handleSaveRegistry}
						disabled={isProbeLoading}
						style={{
							cursor: isProbeLoading ? "wait" : "pointer",
						}}>
						{isProbeLoading ? <LoadingThreeDots /> : "Zapisz"}
					</Button>
				</div>
			</div>
			<div className="additional-commands">
				<div className="additional-commands-actions">
					<Button
						onClick={handleSetRtc}
						disabled={isProbeLoading}
						style={{
							cursor: isProbeLoading ? "wait" : "pointer",
						}}>
						{isProbeLoading ? <LoadingThreeDots /> : "Ustaw Zegar"}
					</Button>
					<Button
						onClick={handleKmsReboot}
						disabled={isProbeLoading}
						style={{
							cursor: isProbeLoading ? "wait" : "pointer",
						}}>
						{isProbeLoading ? (
							<LoadingThreeDots />
						) : (
							"Restart Sterownika"
						)}
					</Button>
					<Button
						onClick={handleSaveScheduler}
						disabled={isProbeLoading}
						style={{
							cursor: isProbeLoading ? "wait" : "pointer",
						}}>
						{isProbeLoading ? (
							<LoadingThreeDots />
						) : (
							"Zapisz harmonogram"
						)}
					</Button>
				</div>
				<div className="placeholder"></div>
			</div>
			<StyledDataTable
				noDataComponent="Brak danych spełniających kryteria"
				data={informationData}
				columns={informationDataColumns}
			/>
		</div>
	);
};

export default DetailsCommands;
