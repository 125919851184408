import {
	Menu,
	MenuItem,
	sidebarClasses,
	SubMenu,
	menuClasses,
} from "react-pro-sidebar";
import { StyledSidebar } from "./Nav.styles";
import { ReactComponent as Icoarrow } from "./../../svg/ico-w-lewo.svg";
import { ReactComponent as Icoulubione } from "./../../svg/ico-ulubione-projekty.svg";
import { ReactComponent as Icoadhoc } from "./../../svg/ico-adhoc.svg";
import { ReactComponent as Icoudostepnij } from "./../../svg/ico-udostepnij.svg";
import { ReactComponent as Icotesty } from "./../../svg/ico-testy.svg";
import { ReactComponent as Icodane } from "./../../svg/ico-dane.svg";
import { ReactComponent as Icomapa } from "./../../svg/ico-mapa-pin.svg";
import { ReactComponent as Icolista } from "./../../svg/ico-lista.svg";
import { ReactComponent as Icoraport } from "./../../svg/ico-raport.svg";
import { ReactComponent as Icopowiadomienienowe } from "./../../svg/ico-powiadomienie-nowe.svg";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import logo from "./../../img/logoNQM.png";
import smLogo from "./../../img/logoNQMsmall.png";
import { useState, useEffect } from "react";

const Nav = ({ isExpanded, setIsExpanded, toggled, setToggled }) => {
	const [broken, setBroken] = useState(false);
	const [sidebarWidth, setSidebarWidth] = useState("250px");
	const [fontSize, setFontSize] = useState("16px");

	const theme = useTheme();
	const location = useLocation();
	const userRoutes = JSON.parse(localStorage.getItem("routes")) || [];

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setSidebarWidth("250px");
				setFontSize("16px");
			} else if (window.innerWidth < 900) {
				setSidebarWidth("180px");
				setFontSize("12px");
			} else if (window.innerWidth < 1200) {
				setSidebarWidth("210px");
				setFontSize("14px");
			} else {
				setSidebarWidth("250px");
				setFontSize("16px");
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const menuItems = [
		{
			id: "menu-dashboard",
			path: "/dashboard",
			label: "Panel Główny",
			icon: <Icoulubione width="45" height="45" fill={theme.slategray} />,
		},
		{
			id: "menu-schedules",
			path: "/schedules",
			label: "Harmonogramy",
			icon: <Icoadhoc width="45" height="45" fill={theme.slategray} />,
		},
		{
			id: "menu-pipelinemap",
			path: "/pipelinemap",
			label: "Mapa",
			icon: <Icomapa width="45" height="45" fill={theme.slategray} />,
		},
		{
			id: "menu-pipelinemap-nodes",
			path: "/pipelinemap-nodes",
			label: "Węzły sieci",
			icon: <Icoudostepnij width="45" height="45" fill={theme.slategray} />,
		},
		{
			id: "menu-reports-pumps",
			path: "/reports-pumps",
			label: "Pompownie",
			icon: <Icoraport width="45" height="45" fill={theme.slategray} />,
		},
		{
			id: "menu-reports-alarms",
			path: "/reports-alarms",
			label: "Alarmy",
			icon: <Icopowiadomienienowe width="45" height="45" fill={theme.slategray} />,
		},
		{
			id: "menu-reports-month",
			path: "/reports-month",
			label: "Zestawienie Mies.",
			icon: <Icodane width="45" height="45" fill={theme.slategray} />,
		},
	];

	const visibleMainItems = menuItems.filter((item) => {
		const userRight = userRoutes.find(
			(right) => right.menu_key === item.id
		);
		return (
			userRight &&
			userRight.visible === 1 &&
			!item.id.startsWith("menu-reports-") &&
			!item.id.startsWith("menu-pipelinemap")
		);
	});

	const visibleNetworkOperationItems = menuItems.filter((item) => {
		const userRight = userRoutes.find(
			(right) => right.menu_key === item.id
		);
		return (
			userRight &&
			userRight.visible === 1 &&
			item.id.startsWith("menu-pipelinemap")
		);
	});

	const visibleSubMenuItems = menuItems.filter((item) => {
		const userRight = userRoutes.find(
			(right) => right.menu_key === item.id
		);
		return (
			userRight &&
			userRight.visible === 1 &&
			item.id.startsWith("menu-reports-")
		);
	});

	return (
		<StyledSidebar
			width={sidebarWidth}
			breakPoint="md"
			collapsed={!isExpanded}
			onBackdropClick={() => setToggled(false)}
			onBreakPoint={setBroken}
			toggled={toggled}
			rootStyles={{
				position: "fixed",
				height: "100vh",
				top: "50%",
				transform: "translate(0, -50%)",
				zIndex: "11",
				borderColor: theme.white,
				[`.${sidebarClasses.container}`]: {
					backgroundColor: theme.white,
				},
			}}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "10px",
					color: theme.primary,
					padding: "10px",
					marginBottom: "20px",
				}}>
				<div>
					{isExpanded ? (
						<img src={logo} alt="Logo" className="logo" />
					) : (
						<img src={smLogo} alt="Logo" className="small-logo" />
					)}
				</div>
			</div>
			<Menu
				menuItemStyles={{
					button: ({ level }) => {
						if (level === 0 || 1) {
							return {
								"&:hover": {
									backgroundColor: `${theme.hoverwhite} !important`,
								},
							};
						}
					},
				}}>
				{visibleMainItems.map((item) => (
					<MenuItem
						style={{ fontSize: fontSize }}
						onClick={() => {
							if (broken) {
								setToggled(false);
							}
						}}
						key={item.id}
						id={item.id}
						active={location.pathname === item.path}
						component={<Link to={item.path} />}
						icon={item.icon}>
						{item.label}
					</MenuItem>
				))}
				{visibleNetworkOperationItems.length > 0 && (
					<SubMenu
						style={{ fontSize: fontSize }}
						label="Praca sieci"
						icon={
							<Icolista
								width="45"
								height="45"
								fill={theme.slategray}
							/>
						}>
						{visibleNetworkOperationItems.map((item) => (
							<MenuItem
								style={{ fontSize: fontSize }}
								onClick={() => {
									if (broken) {
										setToggled(false);
									}
								}}
								key={item.id}
								id={item.id}
								active={location.pathname === item.path}
								component={<Link to={item.path} />}
								icon={item.icon}>
								{item.label}
							</MenuItem>
						))}
					</SubMenu>
				)}
				{visibleSubMenuItems.length > 0 && (
					<SubMenu
						style={{ fontSize: fontSize }}
						label="Raporty"
						icon={
							<Icodane
								width="45"
								height="45"
								fill={theme.slategray}
							/>
						}>
						{visibleSubMenuItems.map((item) => (
							<MenuItem
								style={{ fontSize: fontSize }}
								onClick={() => {
									if (broken) {
										setToggled(false);
									}
								}}
								key={item.id}
								id={item.id}
								active={location.pathname === item.path}
								component={<Link to={item.path} />}
								icon={item.icon}>
								{item.label}
							</MenuItem>
						))}
					</SubMenu>
				)}
				<MenuItem
					style={{ fontSize: fontSize }}
					className="settings-menu-item"
					onClick={() => {
						if (broken) {
							setToggled(false);
						}
					}}
					component={<Link to={"/admin-users-admin"} />}
					icon={
						<Icotesty
							width="45"
							height="45"
							fill={theme.slategray}
						/>
					}>
					Ustawienia
				</MenuItem>
			</Menu>
			<div>
				<div
					className="expand-icon"
					style={{
						transform: `translate(-50%, 0) rotate(${
							isExpanded ? "0" : "180deg"
						})`,
					}}>
					<Icoarrow
						style={{ cursor: "pointer" }}
						onClick={() => setIsExpanded(!isExpanded)}
						width={50}
						fill={theme.primary}
					/>
				</div>
			</div>
		</StyledSidebar>
	);
};

export default Nav;
