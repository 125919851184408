import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
	Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./themes";
import GlobalStyle from "./globalStyles";
import Root from "./Root";
import Home from "./pages/Home/Home";
import Users from "./pages/Users/Users";
import Logs from "./pages/Logs/Logs";
import Details from "./pages/Details/Details";
import MultiDetails from "./pages/MultiDetails/MultiDetails";
import Schedules from "./pages/Schedules/Schedules";
import Pipelinemap from "./pages/Pipelinemap/PipelinemapMap/Pipelinemap";
import Regions from "./pages/Regions/Regions";
import MapEdit from "./pages/MapEdit/MapEdit";
import Settings from "./pages/Settings/Settings";
import ReportsAlarms from "./pages/Reports/ReportsAlarms/ReportsAlarms";
import ReportsPumps from "./pages/Reports/ReportsPumps/ReportsPumps";
import ReportsMonth from "./pages/Reports/ReportsMonth/ReportsMonth";
import PipelinemapNodes from "./pages/Pipelinemap/PipelinemapNodes/PipelinemapNodes";
import NotAuthorized from "./pages/NotAuthorized/NotAuthorized";

const App = () => {
	const storedThemeName = localStorage.getItem("selected-theme");
	const [theme, setTheme] = useState(
		storedThemeName === "light" ? lightTheme : darkTheme
	);

	const toggleTheme = () => {
		if (theme.name === 'light') {
			setTheme(darkTheme);
		} else {
			setTheme(lightTheme);
		}
	};	

	useEffect(() => {
		localStorage.setItem("selected-theme", theme.name);
	}, [theme]);

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Root toggleTheme={toggleTheme} />}>
				<Route index element={<Navigate to="/dashboard" replace />} />
				<Route path="/dashboard" element={<Home />} />
				<Route path="/admin-users-admin" element={<Users />} />
				<Route path="/admin-logs" element={<Logs />} />
				<Route path="/details/:id" element={<Details />} />
				<Route path="/multidetails/:id" element={<MultiDetails />} />
				<Route path="/reports-pumps" element={<ReportsPumps />} />
				<Route path="/reports-alarms" element={<ReportsAlarms />} />
				<Route path="/reports-month" element={<ReportsMonth />} />
				<Route path="/schedules" element={<Schedules />} />
				<Route path="/pipelinemap" element={<Pipelinemap />}>
					<Route
						path=":type/:id_schedule"
						element={<Pipelinemap />}
					/>
				</Route>
				<Route
					path="/pipelinemap-nodes"
					element={<PipelinemapNodes />}
				/>
				<Route path="/admin-regions" element={<Regions />} />
				<Route path="/admin-map-edit" element={<MapEdit />} />
				<Route path="/admin-settings" element={<Settings />} />
				<Route path="/noauth" element={<NotAuthorized />} />
				<Route
					path="*"
					element={<Navigate to="/dashboard" replace />}
				/>
			</Route>
		)
	);

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<GlobalStyle />
				<RouterProvider router={router} />
			</div>
		</ThemeProvider>
	);
};

export default App;
