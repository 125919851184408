import {
	React,
	useEffect,
	useState,
	useRef,
	useCallback,
} from "react";
import { HomeLayout } from "./Home.styles";
import useAccessControl from "../../hooks/useAccessControl";
import ReactECharts from "echarts-for-react";
import { sendRequest } from "../../api/sendRequest";
import { ReactComponent as Icopompadziala } from "./../../svg/ico-pompa2-dziala.svg";
import { ReactComponent as Icopompaniedziala } from "./../../svg/ico-pompa2-niedziala.svg";
import { ReactComponent as Icopompadeaktywowana } from "./../../svg/ico-pompa2-deaktywowana.svg";
import { ReactComponent as Icopompauwaga } from "./../../svg/ico-pompa2-full-uwaga.svg";
import Loading from "../../components/Loading/Loading";
import { useTheme } from "styled-components";
import Map from "../../components/Home/Map";

const Home = () => {
	const theme = useTheme();
	const isAccessVerified = useAccessControl();
	const [chartOptions, setChartOptions] = useState(null);
	const [probeCounts, setProbeCounts] = useState({
		countStatusOne: 0,
		countProbeRunningZero: 0,
		countProbeRunningThree: 0,
		countStatusZero: 0,
		totalError: 0
	  });
	const [isLoading, setIsLoading] = useState(true);
	const [zoom, setZoom] = useState({ start: 0, end: 100 });
	const [timeStamps, setTimeStamps] = useState([]);
	const [pumpRun, setPumpRun] = useState([]);
	const [pumpErr, setPumpErr] = useState([]);
	const [pumpAlarmLevel, setPumpAlarmLevel] = useState([]);
	const [visibleProbes, setVisibleProbes] = useState([]);
	const chartsRef = useRef();

	const fetchData = async () => {
		const getHistogramRequest = {
			action: "get-histogram",
		};


		try {
			const [histogramResponse] =
				await Promise.all([
					sendRequest(getHistogramRequest),
				]);

			const data = histogramResponse.data._data_;
			setTimeStamps(data.map((item) => {
				return item.time_stamp.substring(0, item.time_stamp.lastIndexOf(':'));
			  }));
			setPumpRun(data.map((item) => item.pump_run));
			setPumpErr(data.map((item) => item.pump_err));
			setPumpAlarmLevel(data.map((item) => item.pump_alarm_level));

			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const formattedTimeStamps = timeStamps.map((timestamp) => {
			const [date, time] = timestamp.split(' ');
			return `${time}\n${date}`;
		  });

		const options = {
			animation: false,
			grid: {
				top: 20,
				right: 55,
				bottom: 35,
				left: 70,
				borderColor: "#e5e5e5",
			},
			xAxis: {
				type: 'category',
				data: formattedTimeStamps,
				axisLabel: {
				  formatter: (value) => {
					return `{value|${value.split('\n')[0]}}\n{value|${value.split('\n')[1]}}`;
				  },
				  rich: {
					value: {
					  lineHeight: 15,
					  align: 'center'
					}
				  }
				}
			  },
			yAxis: {
				type: "value",
			},
			series: [
				{
					name: "Pompownia pracuje",
					data: pumpRun,
					type: "bar",
					stack: "stack1",
					color: theme.success,
				},
				{
					name: "Błąd pompowni",
					data: pumpErr,
					type: "bar",
					stack: "stack1",
					color: theme.danger,
				},
				{
					name: "Poziom alarmowy",
					data: pumpAlarmLevel,
					type: "bar",
					stack: "stack1",
					color: theme.warning,
				},
			],
			tooltip: {
				trigger: "axis",
			},
			dataZoom: [
				{
					start: zoom.start,
					end: zoom.end,
					type: "inside",
					xAxisIndex: [0],
					filterMode: "weakFilter",
				},
			],
		};
		setChartOptions(options);
	}, [timeStamps, pumpRun, pumpErr, pumpAlarmLevel]);

	useEffect(() => {
		const totalPoints = timeStamps.length;
		const pointsToShow = 733;
		const startZoom = Math.max(0, ((totalPoints - pointsToShow) / totalPoints) * 100);
		const endZoom = 100;
	
		const updatedZoom = {
			start: startZoom,
			end: endZoom
		};
	
		setZoom(updatedZoom);
	}, [timeStamps]);

	useEffect(() => {
		if (chartsRef.current) {
			const echartsInstance = chartsRef.current.getEchartsInstance();

			const formattedTimeStamps = timeStamps.map((timestamp) => {
				const [date, time] = timestamp.split(' ');
				return `${time}\n${date}`;
			  });

			echartsInstance.setOption(
				{
					animation: false,
					grid: {
						top: 20,
						right: 55,
						bottom: 35,
						left: 70,
						borderColor: "#e5e5e5",
					},
					xAxis: {
						type: 'category',
						data: formattedTimeStamps,
						axisLabel: {
						  formatter: (value) => {
							return `{value|${value.split('\n')[0]}}\n{value|${value.split('\n')[1]}}`;
						  },
						  rich: {
							value: {
							  lineHeight: 15,
							  align: 'center'
							}
						  }
						}
					  },
					yAxis: {
						type: "value",
					},
					series: [
						{
							name: "Pompownia pracuje",
							data: pumpRun,
							type: "bar",
							stack: "stack1",
							color: theme.success,
						},
						{
							name: "Błąd pompowni",
							data: pumpErr,
							type: "bar",
							stack: "stack1",
							color: theme.danger,
						},
						{
							name: "Poziom alarmowy",
							data: pumpAlarmLevel,
							type: "bar",
							stack: "stack1",
							color: theme.warning,
						},
					],
					tooltip: {
						trigger: "axis",
					},
					dataZoom: [
						{
							start: zoom.start,
							end: zoom.end,
							type: "inside",
							xAxisIndex: [0],
							filterMode: "weakFilter",
						},
					],
				}, true, false);
			}
		}, [zoom]);

		const onChartClick = useCallback(async (params) => {
			const convertDateTimeFormat = (inputDateTime) => {
				const [time, date] = inputDateTime.split('\n');
				return `${date} ${time}`;
			};
		
			const addRegionRequest = {
				action: "get-histogram-datetime",
				datetime: convertDateTimeFormat(params.name),
			};
			try {
				const response = await sendRequest(addRegionRequest);
				const probeIds = response.data._data_.map((item) => item.probe_id);
				setVisibleProbes(probeIds);
			} catch (error) {
				console.error(error);
			}
		}, []);
		

	const onEvents = {
		click: onChartClick,
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (isLoading) {
		return <Loading />;
	}

	if (!isAccessVerified) {
		return null;
	}


	return (
		<HomeLayout>
			<div className="chart-container">
				{chartOptions && (
					<ReactECharts
						ref={chartsRef}
						option={chartOptions}
						onEvents={onEvents}
						style={{ width: "100%", height: "100%" }}
					/>
				)}
				<div className="chart-info">
					<Icopompadziala width={55} />
					<Icopompaniedziala width={55} />
					<Icopompaniedziala width={55} />
					<Icopompadeaktywowana width={55} />
					<Icopompauwaga width={55} />
					<div className="number number-online">{probeCounts.countStatusOne}</div>
					<div className="number number-block">{probeCounts.countProbeRunningZero}</div>
					<div className="number number-offline">{probeCounts.countProbeRunningThree}</div>
					<div className="number number-off">{probeCounts.countStatusZero}</div>
					<div className="number number-alarms">{probeCounts.totalError}</div>
				</div>
			</div>
			<Map setProbeCounts={setProbeCounts} visibleProbes={visibleProbes} setVisibleProbes={setVisibleProbes} />
		</HomeLayout>
	);
};

export default Home;
