import styled from "styled-components";

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom-left-radius: 5px;
	padding: 1px 1px 1px 10px;
	min-width: 463px;
	position: fixed;
	top: 0;
	right: 0;
	background-color: ${(props) => props.theme.white};
	z-index: 20;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	svg {
		cursor: pointer;
	}

	input {
		height: 10px;
		padding: 15px 10px;
		border: none;
		outline: none;
		border-radius: 5px;
		background-color: ${(props) => props.theme.secondary};
		color: ${(props) => props.theme.fontcolor};
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.secondary} inset !important;
	}

	.logged-as {
		flex-direction: column;
		padding: 0 15px;
	}

	.burger-menu {
		display: none;
	}

	.region-name {
		padding: 0 5px;
	}

	.menu-settings {
		top: 100%;
		right: 0;
	}

	.notification {
		position: relative;

		.notification-badge {
			position: absolute;
			top: 0;
			right: -2px;
			background-color: red;
			border-radius: 50%; 
			padding: 5px;
			min-width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			font-size: 12px;
		}
	}

	.menu-notifications {
		top: 100%;
		right: 5%;
		max-height: 500px;
		width: 260px;
		overflow-y: auto;
		.notification-info {
			margin: 20px 0;
		}

		.notification-item {
			display: flex;
			flex-direction: column;
			width: 100%;
			border-bottom: 1px solid #ccc;
			font-size: 14px;
			padding: 10px;
			cursor: pointer;
			transition: background-color 0.3s;
		}

		.notification-item:hover {
			background-color: ${(props) => props.theme.expandedTableBackgroundColor};
		}

		.notification-top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
		}

		.notification-description {
			flex: 1;
			font-weight: bold;
		}

		.notification-time {
			flex: 1;
			text-align: right;
			font-size: 0.9em;
			color: #777;
		}

		.notification-error {
			color: red; /* Możesz dostosować kolor do własnych preferencji */
			text-align: center;
		}
	}

	.dropdown-menu {
		display: flex;
		flex-direction: column;
		position: absolute;
		background-color: ${(props) => props.theme.white};
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		min-width: 160px;
		box-shadow: 0px 24px 28px -10px rgba(0, 0, 0, 0.2);
		z-index: 3;

		a {
			width: 100%;
			color: ${(props) => props.theme.fontcolor};
			padding: 12px 16px;
			text-decoration: none;
			display: block;

			:hover {
				background-color: ${(props) => props.theme.hoverwhite};
			}
		}
		a:last-of-type {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}
	.logo {
		display: none;
		align-self: center;
		margin: 0 auto;
	}
	@media (max-width: 768px) {
		width: 100%;
		justify-content: end;

		.burger-menu {
			display: flex;
			margin-right: auto;
			background-color: ${(props) => props.theme.white};
			border: 1px solid rgba(0, 0, 0, 0.2);
			border-radius: 5px;
		}

		
		.settings-icon {
			display: none;
		}
	}
	@media (max-width: 425px) {
		.logo {
			display: block;
		}

		.theme-icon{
			position: absolute;
			top: 0;
			left: 13.5%;
		}

		.logged-as {
			font-size: 14px;
		}
		.region-name,
		.logged-as {
			display: none;
		}
	}
	@media (max-width: 320px) {
		.logo{
			height: 33px !important;
			width: 110px;
		}
	}
`;
