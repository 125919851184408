import styled from "styled-components";

export const LoginContainer = styled.div`

	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	color: ${(props) => props.theme.fontcolor};

	.version-info {
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: 12px;
		padding: 5px;
		color: ${(props) => props.theme.slategray}
	}

	form {
		display: flex;
		flex-direction: column;
		text-align: start;
		gap: 15px;
	}

	.err-msg {
		color: ${(props) => props.theme.danger};
		margin-top: 2px;
		min-height: 17px;
		font-size: 14px;
	}

	.login-box {
		position: relative;
		width: 300px;
		padding: 20px;
		border-radius: 5px;
		background-color: ${(props) => props.theme.white};
		text-align: center;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}

	.password-input {
		position: relative;
	}

	.submit-input {
		text-align: center;
	}

	.password-toggle {
		position: absolute;
		top: 27%;
		right: 10px;

		background: none;
		border: none;
		font-size: 14px;
		cursor: pointer;
	}

	.login-box h2 {
		margin-top: 0;
	}

	.remember-input {
		label {
			display: flex;
			align-items: center;
		}
	}

	.login-box input {
		width: 100%;
		padding: 15px 10px;
		border: none;
		outline: none;
		border-radius: 5px;
		color: ${(props) => props.theme.fontcolor};
		background-color: ${(props) => props.theme.secondary};
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.secondary} inset !important;
	}

	.checkbox {
		display: inline-block;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background: ${(props) => props.theme.secondary};
		border: 2px ${(props) => props.theme.secondary} solid;
		margin-right: 4px;
	}

	input[type="checkbox"] {
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}
`;
