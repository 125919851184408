import React, { useState } from "react";
import { LoginContainer } from "./Login.styles";
import { ReactComponent as Icooko } from "./../../svg/ico-oko.svg";
import { Button } from "../../components/Button/Button";
import { useTheme } from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = ({ setToken }) => {
	const theme = useTheme();
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [login, setLogin] = useState("");
	const [loginError, setLoginError] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [isChecked, setIsChecked] = useState(false);
	const navigate = useNavigate();
	const handlePasswordToggle = () => {
		setPasswordVisible(!passwordVisible);
	};

	const apiUrl = process.env.REACT_APP_API_URL;

	const sendRequest = async (payload) => {
		const response = await axios.post(apiUrl, payload);
		return response;
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		let isValid = true;

		if (!login) {
			setLoginError("Login jest wymagany");
			isValid = false;
		} else {
			setLoginError("");
		}

		if (!password) {
			setPasswordError("Hasło jest wymagane");
			isValid = false;
		} else {
			setPasswordError("");
		}

		if (isValid) {
			const loginPayload = {
				username: login,
				password: password,
				action: "login",
			};

			sendRequest(loginPayload)
				.then((response) => {
					if (response["data"]["status"] === 0) {
						setPasswordError("Login lub hasło są nieprawidłowe");
						return;
					}

					const token = response.data.JWT;
					const routes = response.data.routes;
					const groupName = response.data.group_name;
					const userDescription = response.data.user_description;
					const dictionary = response.data.dictionary;

					localStorage.removeItem("routes");
					localStorage.setItem("routes", JSON.stringify(routes));
					localStorage.setItem("group_name", groupName);
					localStorage.setItem("user_description", userDescription);
					localStorage.setItem("dictionary", JSON.stringify(dictionary));
					if (isChecked) {
						localStorage.setItem("savepass", true);
						localStorage.setItem("login", login);
						localStorage.setItem("password", password);
					} else {
						localStorage.setItem("savepass", false);
						sessionStorage.setItem("login", login);
						sessionStorage.setItem("password", password);
					}

					setToken({
						token,
					});
					navigate("/dashboard", { replace: true });
				})
				.catch((err) => {
					console.log("AXIOS: ", err);
					setPasswordError("Błąd sieci");
				});
		}
	};

	return (
		<LoginContainer>
			<div className="login-box">
				<h2>Logowanie</h2>

				<form onSubmit={handleSubmit}>
					<div className="login-input">
						<label htmlFor="login">Login:</label>
						<input
							type="text"
							id="login"
							placeholder="Nazwa użytkownika"
							value={login}
							onChange={(e) => setLogin(e.target.value)}
						/>
						<p className="err-msg">{loginError}</p>
					</div>
					<div className="password-input">
						<label htmlFor="pass">Hasło:</label>
						<input
							type={passwordVisible ? "text" : "password"}
							placeholder="Hasło"
							id="pass"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<button
							type="button"
							onClick={handlePasswordToggle}
							className="password-toggle">
							{passwordVisible ? (
								<Icooko
									width="45"
									height="45"
									fill={theme.darkerprimary}
								/>
							) : (
								<Icooko
									width="45"
									height="45"
									fill={theme.primary}
								/>
							)}
						</button>
						<p className="err-msg">{passwordError}</p>
					</div>
					<div className="remember-input">
						<label>
							<input
								type="checkbox"
								onChange={() => {
									setIsChecked(!isChecked);
								}}
							/>
							<svg
								className={`checkbox ${
									isChecked ? "checkbox--active" : ""
								}`}
								aria-hidden="true"
								viewBox="0 0 15 11"
								fill="none">
								<path
									d="M1 4.5L5 9L14 1"
									strokeWidth="2"
									stroke={isChecked ? theme.primary : "none"}
								/>
							</svg>
							Zapisz hasło
						</label>
					</div>
					<div className="submit-input">
						<Button style={{ fontSize: "24px" }} type="submit">
							Login
						</Button>
					</div>
				</form>
			<p className="version-info">{process.env.REACT_APP_VERSION}</p>
			</div>
		</LoginContainer>
	);
};

export default Login;
