import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function useAccessControl() {
	const navigate = useNavigate();
	const location = useLocation();
	const [isVerified, setIsVerified] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	const [isMenuProbeComm, setIsMenuProbeComm] = useState(false);
	const [isMenuProbeLogs, setIsMenuProbeLogs] = useState(false);
	const [isMenuProbePumpLock, setIsMenuProbePumpLock] = useState(false);
	const [isMenuProbeSettings, setIsMenuProbeSettings] = useState(false);
	const [isMenuProbeRegisters, setIsMenuProbeRegisters] = useState(false);

	useEffect(() => {
		const userRights = JSON.parse(localStorage.getItem("routes")) || [];
		const currentRoute = location.pathname.split("/")[1];

		const isAdminRights = userRights.find(
			(item) => item.menu_key === "menu-admin-users-admin"
		);

		if (isAdminRights) {
			setIsAdmin(isAdminRights.visible === 1);
		}

		if (currentRoute === "details" || currentRoute === "multidetails") {
			setIsVerified(true);

			setIsMenuProbeComm(
				userRights.some(
					(item) =>
						item.menu_key === "menu-probe-comm" &&
						item.visible === 1
				)
			);
			setIsMenuProbeLogs(
				userRights.some(
					(item) =>
						item.menu_key === "menu-probe-logs" &&
						item.visible === 1
				)
			);
			setIsMenuProbePumpLock(
				userRights.some(
					(item) =>
						item.menu_key === "menu-probe-pump-lock" &&
						item.visible === 1
				)
			);
			setIsMenuProbeSettings(
				userRights.some(
					(item) =>
						item.menu_key === "menu-probe-settings" &&
						item.visible === 1
				)
			);
			setIsMenuProbeRegisters(
				userRights.some(
					(item) =>
						item.menu_key === "menu-probe-registers" &&
						item.visible === 1
				)
			);

			return;
		}

		const hasAccess = userRights.some(
			(item) =>
				item.menu_key === `menu-${currentRoute}` && item.visible === 1
		);

		if (currentRoute !== "admin-users-admin") {
			if (!hasAccess) {
				navigate("/noauth");
			} else {
				setIsVerified(true);
			}
		} else {
			setIsVerified(true);
		}
	}, [navigate, location]);

	if (location.pathname === "/admin-users-admin") {
		return {
			isVerified: true,
			isAdmin,
		};
	}

	if (location.pathname.split("/")[1] === "details") {
		return {
			isVerified,
			isMenuProbeComm,
			isMenuProbeLogs,
			isMenuProbePumpLock,
			isMenuProbeSettings,
			isMenuProbeRegisters,
		};
	}
	return {
		isVerified,
	};
}

export default useAccessControl;
