import styled from "styled-components";

export const MapEditLayout = styled.div`
	margin: 50px 0 50px 0;

	.map-container {
		position: relative;
		width: 100%;
		height: 85vh;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
	}
	.select-layers-button {
		position: absolute;
		top: 80px;
		right: 30px;
		padding: 1px 4px;
		z-index: 2;
		background-color: white;
		border: 1px solid transparent;
		border-radius: 2px;

		:hover {
			border: 1px solid black;
		}
	}

	.select-layers {
		position: absolute;
		display: flex;
		flex-direction: column;
		padding: 10px 15px;
		background-color: white;
		top: 103px;
		right: 30px;
		border-radius: 5px;
		z-index: 1;
		color: #3f3f3f;

		input{
			margin-right: 5px;
		}
	}

	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 15;

		.modal-content {
			background-color: ${(props) => props.theme.white};
			padding: 20px;
			border-radius: 8px;
			min-width: 400px;
			max-width: 600px;
			display: flex;
			flex-direction: column;
			gap: 10px;

			.modal-actions {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;
			}

			.modal-actions {
				.cancel {
					margin-right: 10px;
				}
				.add {
					margin-left: 10px;
				}
			}

			.err-msg {
				color: ${(props) => props.theme.danger};
				margin-top: 2px;
				min-height: 17px;
				font-size: 14px;
			}

			h2 {
				margin-bottom: 25px;
			}

			input {
				width: 100%;
				margin-top: 5px;
				padding: 15px 10px;
				border: none;
				outline: none;
				border-radius: 5px;
				background-color: ${(props) => props.theme.secondary};
				color: ${(props) => props.theme.fontcolor};
			}
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px
					${(props) => props.theme.secondary} inset !important;
			}
		}
	}
`;
