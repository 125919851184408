import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { DetailsLayout } from './MultiDetails.styles';
import SingleProbe from '../../components/MultiDetails/SingleProbe';
import { useTheme } from 'styled-components';
import { sendRequest } from '../../api/sendRequest';

const MultiDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [probeData, setProbeData] = useState({});
    const [probeLoadStatus, setProbeLoadStatus] = useState({});
    const params = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    const probeIds = params.id?.split('_') || [];

    const fetchData = async () => {
        setIsLoading(true);
        let dataMap = {};

        try {
            const promises = probeIds.map((probe_id) =>
                sendRequest({ action: 'get-probe', probe_id: probe_id })
            );
            const responses = await Promise.all(promises);
            responses.forEach((response) => {
                const probeId = response.data._data_.probe_id;
                dataMap[probeId] = response.data._data_;
            });
            setProbeData(dataMap)
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [params]);

    useEffect(() => {
        if (probeIds.length === 1) {
            navigate(`/details/${probeIds[0]}`);
        }
    }, [probeIds.length, navigate, probeIds]);

    const sortedProbeIds = Object.keys(probeData).sort((a, b) => {
        if (typeof probeData[a].description === 'string' && typeof probeData[b].description === 'string') {
            return probeData[a].description.localeCompare(probeData[b].description);
        }
        return 0;
    });
    
    
	const handleProbeLoaded = (probeId) => {
		setProbeLoadStatus((prevStatus) => ({
			...prevStatus,
			[probeId]: true,
		}));
	};

    return (
        <DetailsLayout>
            {isLoading && (
                <TailSpin
                    height="100"
                    width="100"
                    color={theme.primary}
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperClass=""
                    wrapperStyle={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.background,
                        zIndex: 1,
                    }}
                    visible={isLoading}
                />
            )}
            {!isLoading && sortedProbeIds.map((probe_id) => (
                <SingleProbe
                    key={probe_id}
                    probe_id={probe_id}
                    onLoaded={() => handleProbeLoaded(probe_id)}
                />
            ))}
        </DetailsLayout>
    );
};

export default MultiDetails;
